import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import FieldInput from 'react-pe-scalars/dist'
import { Button, ButtonGroup, Icon, Intent } from '@blueprintjs/core'
import { __ } from 'react-pe-utilities/dist'
import { useState } from 'react'
import MemberRatingDisplay from './MemberRatingDisplay'

const CorrectRaiting = props => {
    const [rating, setRating] = useState( 
        props.correct_member_rate 
            ? 
            props.correct_member_rate.rate 
            : 
            0 
    )
    const [isTrackEditForms, setTrackEditForms] = useState(false)
    useEffect(() =>
    {
        setRating(
            props.correct_member_rate 
                ? 
                props.correct_member_rate.rate 
                : 
                0
        )   
    }, [ props.correct_member_rate ])
    const isTrakOwner = () =>
    {
        return props.user?.roles.filter( role => role === "Track_moderator").length > 0
    }
    const onChange = value =>
    {
        setRating( value )
    }
    const correctRate = ( isReset) => {
        props.correctRate( isReset ? false : rating)
        setRating( isReset ? "" : rating)
        setTrackEditForms(false)
    }
    const editable = isTrakOwner() && isTrackEditForms
    return isTrakOwner()
        ?
        <> 
            <div className={`lead py-2 d-flex `}>
                <span className={`px-2 title ${ rating ? "no-no-no" : "" } `}> 
                    { props.countedRating } 
                </span> 
            </div>
            
            <FieldInput 
                visualization="festFormsFields"           
                vertical
                not_clear
                editable={ editable }
                type={ "number" }
                min={ 0 }
                step={ 0.02 }
                label_step_size={ 1 }
                value={ rating || !editable ? rating : props.countedRating } 
                className=" super-lead p-2 "
                onChange={ onChange }
            /> 
            <Menu 
                {...props} 
                isUpdate={ rating !== props.rating }
                isTrackEditForms={isTrackEditForms}
                setTrackEditForms={setTrackEditForms}
                correctRate={correctRate}
            />
        </>
        :
        <MemberRatingDisplay
            rating={rating}
            correct_member_rate={props.correct_member_rate}
            countedRating={props.countedRating}
            className={"lead py-0 title"}
        />
}

CorrectRaiting.propTypes = {}

export default CorrectRaiting

const Menu = props =>
{
    const [isUpdate, setUpdate] = useState(props.isUpdate)
    useEffect(() => {
        setUpdate(props.isUpdate)
    }, [props.isUpdate])
    
    if(props.user?.roles.filter( role => role === "Track_moderator" ).length == 0) 
        return null
    const doUpdate = () =>
    {
        setUpdate(true)
        props.correctRate( ) 
    }
    const doReset = () =>
    {
        setUpdate(true)
        props.correctRate( true )
    }
    return props.isTrackEditForms
        ?
        <ButtonGroup  className='pr-3'>
            <Button small minimal={ !isUpdate} disabled={ !isUpdate} className="px-3" onClick={doUpdate}>
                <Icon 
                    icon="tick" 
                    intent={ isUpdate ? Intent.NONE : Intent.SUCCESS } 
                    className="mr-2"
                />
                {
                    __("Update")
                }
            </Button>
            <Button small className="px-3" onClick={ doReset } intent={ Intent.DANGER }>
                <Icon 
                    icon="reset"  
                    className="mr-2"
                />
                {
                    __("Reset")
                }
            </Button>
            <Button small className="px-3" onClick={ () => props.setTrackEditForms(false) }>
                <Icon 
                    icon="cross" 
                    intent={ Intent.SUCCESS } 
                    className="mr-2"
                />
                {
                    __("Cancel")
                }
            </Button>
        </ButtonGroup>
        :
        <>
            <Button 
                minimal 
                fill
                onClick={ () => props.setTrackEditForms(!props.isTrackEditForms) }
                className="text-nowrap px-4"
            >
                {__("Adjust rating")}
            </Button>
        </>
}