import React from 'react'
import PropTypes from 'prop-types'
import { Button, Popover, Position } from '@blueprintjs/core'
import { __ } from 'react-pe-utilities/dist'
import { useState } from 'react' 
import ProjectFields from 'modules/pe-wpfest-admin-module/data/ProjectFields'

const ProjectFieldSelector = props => {
    const [currentField, setCurrentField] = useState(props.currentField || "none")
    const onChooseCurrent = field =>
    {
        setCurrentField( field )
    }
    const selector = () =>
    {
        const btns = Object.keys(ProjectFields()).map( field =>
            {
                return <Button
                    fill
                    minimal
                    onClick={ evt => onChooseCurrent(field) }
                    key={ field }
                >
                    <span style={{ whiteSpace: "nowrap" }}>{ ProjectFields()[field].title}</span>
                </Button>
            })
        return <div className=' overflow-y-auto p-1' style={{maxHeight:350 }}>
            <div className='p-2'>
                {btns}
            </div>            
        </div>
    }
    return <Popover
        interactionKind="click"
        content={ selector() }
        position={ Position.LEFT }
    >
        <Button minimal >
            <span style={{ whiteSpace: "nowrap" }}>{ ProjectFields()[currentField].title}</span> 
        </Button>
    </Popover>
}

ProjectFieldSelector.propTypes = {}

export default ProjectFieldSelector