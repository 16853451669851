import React, { useEffect, useState } from "react" 
import CabinetView,{ WPFEST_ADMIN_MY_ROUTE, WPFEST_ADMIN_ROLE_ADMINISTRATOR } from "../CabinetView"
import { ButtonGroup, Button, Intent, Icon } from "@blueprintjs/core" 
import { __ } from "react-pe-utilities/dist" 
import { Route, Switch } from "react-router"
import Home from "./Home"
import Example from "./Example"
import All from "./All"
import Mine from "./Mine"
import Options from "./Options"
import Login from "./Login" 
import _fetch from "../../api" 
import NoMatchView from "modules/pe-basic-module/views/NoMatchView"
import { NavLink } from "react-router-dom"
import Site from "./Site" 
import { Loading } from "react-pe-useful/dist"
import IndexDB from "modules/pe-admin-module/views/utilities/IndexDB"  

const Cabinet = props =>
{ 
    const { preroute, route, uri} = props
    const [user, setUser] = useState({})
    const [sites, setSites] = useState([]) 
    const [globals, setglobals] = useState([]) 
    const [loading, setLoading] = useState(true)
    useEffect(() => { 
        _fetch( "init2" )
            .then(data => 
                {
                    if(data.isError)
                    {  
                        IndexDB.find(
                            "peFestAdmin_user",
                            "user",
                            "user",
                            "user"  
                        )
                            .then(user => {
                                setUser( user.data )
                                setLoading(false) 
                            }) 
                        
                        return
                    }
                    console.log("Connect to PE-FEST server: ", CabinetView.serverURL);
                    //console.log(data);
                    setSites( data.sites || [] )
                    setglobals( data.global_options || [] )
                    setUser(data.user)
                    IndexDB.save( 
                        data.global_options, 
                        "global_options", 
                        "global_options", 
                        "global_options",
                        "peFestAdmin_options",  
                        1 
                    )
                    IndexDB.save( 
                        data.user, 
                        "user", 
                        "user", 
                        "user",
                        "peFestAdmin_user",  
                        1 
                    )
                    setLoading(false) 
                })	
    }, [])
    const getMenu = () =>
    {
        const { preroute, route} = props
        if(!user || user.ID < 0) return null
        return <ButtonGroup 
            vertical={false}
            minimal
            large
        >
            <NavLink 
                icon="home" 
                exact
                to={ preroute + "/" + route + "/home" }
                className={"btn rounded-0 btn-outline-light border-0"}
                activeClassName="btn btn-primary rounded-0" 
            >
                <Icon icon="home" />
            </NavLink>
            {
                user && user?.is_super
                    ?
                    <>
                        <NavLink 
                            icon=""
                            exact
                            to={preroute + "/" + route + "/example" }
                            className={"btn rounded-0 btn-outline-light border-0"}
                            activeClassName="btn btn-primary rounded-0" 
                        >
                            { __( "Pattern sites" ) }      
                        </NavLink>                        
                        <NavLink 
                            icon="" 
                            exact
                            className={"btn rounded-0 btn-outline-light border-0"}
                            activeClassName="btn btn-primary "
                            to={preroute + "/" + route + "/all" }           
                        >
                            { __( "All festivals" ) }      
                        </NavLink>                        
                        <NavLink 
                            icon="" 
                            exact
                            className={"btn rounded-0 btn-outline-light border-0"}
                            activeClassName="btn btn-primary "
                            to={preroute + "/" + route + "/options" }           
                        >
                            { __( "Global options" ) }      
                        </NavLink> 
                    </>
                    :
                    null
            } 
            <NavLink 
                icon="" 
                exact
                to={ preroute + "/" + route + "/" + WPFEST_ADMIN_MY_ROUTE }
                className={"btn rounded-0 btn-outline-light border-0"}
                activeClassName="btn btn-primary rounded-0" 
            >
            { __( "My festivals" ) }      
            </NavLink>
        </ButtonGroup>
    }
    const renderContext = ( ) =>
    { 
        if(loading) return <div className="layout-state p-0">
            <Loading />
        </div>
        return <div className="layout-state p-0" style={{background:"#293742"}}>
            <div className=" container position-relative ">
                <div className="d-flex row">
                    <div className="w-100 d-flex">
                        { getMenu() }
                        <Login 
                            { ...props } 
                            user={user}
                        />  
                    </div>
                    
                </div>
                <Switch>
                    <Route
                        path= { preroute + "/" + route }
                        exact  
                        render={(routeProps) => (
                            <Home
                                user={ user }
                                uri={uri}
                            />
                        )}
                        key={"home"}
                    />
                    <Route
                        path= { preroute + "/" + route + "/home" }
                        exact  
                        render={(routeProps) => (
                            <Home
                                user={ user }
                                uri={uri}
                            />
                        )}
                        key={"home2"}
                    />
                    <Route
                        path={ preroute + "/" + route + "/example"} 
                        render={(routeProps) => (
                            user && user?.is_super && user.ID > 0
                                ?
                                <Example
                                    {...props}
                                    user={ user}
                                    uri={uri}
                                />
                                :
                                <Login
                                    {...props}
                                    user={ user}
                                    uri={uri}
                                    isOpen
                                />                   
                        )}
                        key={"example"}
                    />  
                    <Route
                        path={  preroute + "/" + route + "/all"} 
                        render={(routeProps) => (
                            user && user.ID > 0
                                ?
                                <All
                                    {...props}
                                    sites={sites}
                                    user={ user }
                                    uri={uri}
                                />
                                :
                                <Login
                                    {...props}
                                    user={ user}
                                    uri={uri}
                                    isOpen
                                />
                        )}
                        key={"all"}
                    />      
                    <Route
                        path={  preroute + "/" + route + "/options"} 
                        render={(routeProps) => (
                            user && user.ID > 0
                                ?
                                <Options
                                    {...props}
                                    sites={sites}
                                    user={ user }
                                    uri={uri}
                                />
                                :
                                <Login
                                    {...props}
                                    user={ user}
                                    uri={uri}
                                    isOpen
                                />
                        )}
                        key={"all"}
                    />       
                   
                    <Route
                        path={ preroute + "/" + route + "/" + WPFEST_ADMIN_MY_ROUTE } 
                        render={(routeProps) => {   
                             return user && user.ID > 0
                                ?
                                <Mine 
                                        {...props}
                                        user={ user}
                                        uri={uri}
                                    />
                                :
                                <Login
                                    {...props}
                                    user={ user}
                                    uri={uri}
                                    isOpen
                                />
                        } }
                        key={ WPFEST_ADMIN_MY_ROUTE }
                    />
                    <Route
                        path={ preroute + "/" + route + "/site/:id"} 
                        render={(routeProps) => (
                            user && user.ID > 0
                                ?
                                <Site
                                    {...props}
                                    user={ user}
                                    uri={uri}
                                />
                                :                               
                                <Login
                                    {...props}
                                    user={ user}
                                    uri={uri}
                                    isOpen
                                />
                        )}
                        key={"mine"}
                    />
                </Switch>
            </div>
        </div>
    }
    return renderContext( ) 
}
export default Cabinet