import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { __ } from 'react-pe-utilities/dist'
import { Button, Popover, Position } from '@blueprintjs/core'
import { SubMenu } from 'react-pe-scalars/dist'
import FestFormInput from './fest-forms'

const FestFormInputWidget = props => {
  const [ value, setValue ] = useState( props.value || "" )
  useEffect(() =>
  {
    setValue( props.value || "" )
  }, [props.value])
  const { field, title, description, commentary } = props
  const col1 = title || description
    ? 
    props.vertical
        ? 
        "col-12 layout-label-vert"
        : 
        "col-md-3  layout-label"
    : 
    " hidden "
  const col2 = title || description
    ? 
    props.vertical
        ? 
        "col-12 layout-data-vert "
        : 
        "col-md-9 layout-data " 
    : 
    " col-12 layout-data-vert "
  
  const onChangeSubMenu = ( val, field ) => 
  {
    if (!props.onSubMenu) return 
    props.onSubMenu(val, field)
  }
  const onClear = () =>
  {
    props.onChange(null, field)
  }
  const descr = description
        ?
        <Popover
            position={Position.RIGHT}
            interactionKind="hover"
            content={<div className="p-4 large-square" dangerouslySetInnerHTML={{ __html: __(description) }} />}
        >
            <Button minimal icon="help" />
        </Popover>
        : 
        null
  const o = <div className="row dat strob01 m-0" row_data={field}>
      <div className={col1}>
          {__(props.title)}
          {descr}
      </div>
      <div className={col2} style={{ position: "relative", maxWidth:"100%", paddingRight:0 }}>                   
          <div className="d-flex flex-grow-100">
              {
                  props.prefix ? <span className="prefix">{ props.prefix }</span> : null
              } 
              <FestFormInput 
                { ...props }
                value={ value }
                values={ props.values }
                onChange={ props.onChange }
              />
              {
                  props.postfix ? <span className="postfix">{ props.postfix }</span> : null
              }
              {
                (props.editable && !props.not_clear) && props.type !== "field_field"
                  ? 
                  <Button
                    className="right"
                    icon="cross"
                    minimal
                    onClick={onClear}
                  />
                  :
                  null
              } 
              <SubMenu 
                onChange={ (val, fld) => onChangeSubMenu( val, fld ) } 
                original={props.original} 
              /> 
          </div> 
      </div>
      {
          commentary
              ?
              <div className="scalar-commentary px-3 col-md-9 offset-md-3" dangerouslySetInnerHTML={{ __html: __(commentary) }} />
              :
              null
      }
  </div>  
  return <div className='w-100'>
    {o}
  </div>
}

FestFormInputWidget.propTypes = {}

export default FestFormInputWidget