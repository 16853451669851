import React from 'react'
import PropTypes from 'prop-types'
import Select from './Select'
import Tags from './Tags'
import { Tag } from '@blueprintjs/core'
import MultiSelect from '../../../../states/scalars/Multiselect'
import PhoneInput from 'react-phone-input-2'
import ru from 'react-phone-input-2/lang/ru.json'
import _ from 'lodash'
import FieldInput from 'react-pe-scalars/dist'
import RussianRegionsSelector from './RussianRegionsSelector'

const FestFormInput = props => {
    const isDesabled = () =>
    { 
        switch( props.type )
        {
            case "tags":
                const v = props.values.filter(val =>
                {
                    if(val._id)
                    {
                        return val._id === props.value
                    }
                    else
                    {
                        return val === props.value
                    }
                })[0]
                const value = v ? v.title ? v.title : v : props.value
                return <Tag className='py-1 px-3 ml-4' minimal round> { value } </Tag>  
            default:
                return <div className={ ` ${props.className} ` }>
                    { props.value }
                </div>  
        }
        
    }
    const changeValue = ( value, ii ) =>
    {
        // console.log( value, ii, props.i )
        props.onChange( value, props.i , ii )
    }
    const isEnabled = () =>
    {
        let form = ""
        const value = typeof props.value === "undefined" ? props.default : props.value
        switch( props.type )
        {
            case "field_field":
                
                return <div className='w-100'>
                {
                    Array(props.count).fill(1).map((fm, ii) =>
                    {
                        // console.log( props )
                        return <FieldInput
                            key={ii} 
                            vertical
                            type={props.ptype}  
                            visualization="festFormsFields" 
                            editable={true} 
                            className={` form-control dark input  ${props.className} `}
                            value={props.value && props.value[ii] ? props.value[ii]: ""} 
                            values={props.values}
                            onChange={ val => changeValue(val, ii)}
                            
                        /> 
                    })
                }
                </div>
            case "select":
                return <>
                    <MultiSelect 
                        field={props.field} 
                        editable={props.editable} 
                        default={props.default}
                        value={value}
                        multiple={props.multiple || false}
                        values={ Array.isArray(props.values) ? props.values : [] } 
                        on={ props.onChange } 
                        data={{ color : "red" }} 
                    />
                </>
            case "select-2":
                return <Select
                    value={ props.value }
                    values={ Array.isArray(props.values) ? props.values : [] }
                    field={ props.field }
                    onChange={ props.onChange }
                />
            case "phone":
                return <> 
                    <PhoneInput
                        inputProps={{
                            name:"phone",
                            required: true 
                        }}  
                        localization={ ru }
                        enableSearch 
                        inputClass="form-control input dark"
                        containerClass="w-100" 
                        placeholder={_("Insert phone number")}
                        country={"ru"}
                        preferredCountries={['ru','cu','cw','kz']}
                        value={ value }
                        onChange={ props.onChange }
                    />
                </>
            case "tags":
                return <Tags
                    value={ value }
                    values={ Array.isArray(props.values) ? props.values : [] }
                    field={ props.field }
                    onChange={ props.onChange }
                /> 
            case "RussianRegions":
                return <RussianRegionsSelector                    
                    field={props.field} 
                    editable={props.editable} 
                    default={props.default}
                    value={value}
                    on={ props.onChange } 
                    data={{ color : "red" }} 
                /> 
            case "number--1":
                return <input
                    type="number"
                    step={props.step}
                    min={props.min}
                    max={props.max}
                    className={`form-control dark input  ${props.className} `}
                    value={ value }
                    onChange={evt => props.onChange( evt.currentTarget.value )}
                />
            default:
                return <FieldInput 
                    vertical
                    type={ props.type }   
                    value={props.value  } 
                    values={props.values}
                    onChange={ val => props.onChange( val )}
                    not_clear
                /> 
                // return <input
                //     type="text"
                //     className={`form-control dark input  ${props.className} `}
                //     value={ value.toString() }
                //     onChange={evt => props.onChange( evt.currentTarget.value )}
                // /> 
        }
    }
    return (
        props.editable
            ?
            isEnabled()
            :
            isDesabled()
    )
}

FestFormInput.propTypes = {}

export default FestFormInput