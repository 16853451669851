import { Button, ButtonGroup, Intent, Tab, Tabs } from "@blueprintjs/core";
import IndexDB from "modules/pe-admin-module/views/utilities/IndexDB";
import _fetch from "modules/pe-wpfest-admin-module/api";
import React, { useEffect, useState } from "react"
import { Loading } from "react-pe-useful/dist";
import { __ } from "react-pe-utilities/dist";
import PatternSiteCard from "../pattern/PatternSiteCard";

const Example = props =>
{   
    //console.log(props)
    const [selectedTabId, onselectedTabId]  = useState("dolina")
    const [loading, setLoading] = useState( true )
    const [sites, setSites] = useState( [] ) 
    useEffect(() => {
        IndexDB.recover("peFestAdmin_pattern_sites", "sites", "sites")
            .then(sites =>
            {
                //console.log(sites)
                const allSites = Array.isArray(sites)
                    ?
                    sites.map(site => site.data)
                    :
                    []
                    setSites( allSites ) 
            }) 
        _fetch( "init" )
            .then(data => 
                {
                    const sites = ( data.sites || []  );
                    setSites( sites )
                    onselectedTabId( "site0" ) 
                    setLoading( false ) 
                    sites.forEach( site =>  {
                        // console.log( site )
                        IndexDB.save( 
                            site, 
                            "sites", 
                            site.domain_id, 
                            "sites",
                            "peFestAdmin_pattern_sites",  
                            1 
                        ) 
                    }) 
                })
    }, [])
    
    if(loading)
        return <Loading />
    const onTitle = ( title, num ) =>
    {
        let _sites = [...sites]
        _sites[num].title = title
        setSites(_sites)
    }
    const onDomain = (domain, num ) =>
    {
        let _sites = [...sites]
        _sites[num].domain = domain
        setSites(_sites)
    }
    const onContent = (description, num ) =>
    {
        let _sites = [...sites]
        _sites[num].description = description
        setSites(_sites)
    }
    const removeTab = (num) =>
    {
        let _sites = [...sites]
        _sites.splice(num, 1)
        setSites(_sites)
        onselectedTabId( "site0" ) 
    }
    const addTab = () =>
    {        
        let _sites = [...sites]
        let tabID = parseInt(selectedTabId.replace("site", ""))
        _sites.splice(
            tabID + 1, 
            0, 
            {
                title: "New " + sites.length, 
                id: "" + sites.length, 
                domain: sites[tabID] ? sites[tabID].domain : "", 
                content: "" 
            } 
        )
        // console.log(_sites)
        setSites(_sites)
        onselectedTabId( `site${ tabID + 1 }` ) 
    }
    const onSave = () =>
    {
        //console.log(this.state.sites);
        const _sites = sites.filter(site => site.id !== "-1")
		_fetch( "save_sites", _sites )
            .then(data => 
            {
                console.log( _sites );
                if(data.msg)
                {
                    setSites(_sites) 
                }
            });
    }
    let cards = sites 
        ?
        sites.map((elem, i)=> <Tab 
            id={"site" +i} 
            title={ elem.title} 
            panel={ <PatternSiteCard
                title={elem.title}
                domain={elem.domain}
                description={elem.description}
                id={elem.id}
                num={i}
                onTitle={ onTitle }
                onDomain={ onDomain }
                onContent={ onContent }
                onRemove={ removeTab }
            /> } 
            key={elem.id}
            className=" " 
        /> )
        :
        []
    cards.push( <Tabs.Expander key={"ext"}/> )    
    const btns = <ButtonGroup large={false} key={"add"} className="mt-5">
        <Button icon="plus" text={__("Add")} onClick={ addTab }/ >
        <Button icon="floppy-disk" text={__("Save")} onClick={ onSave } />        
    </ButtonGroup>
    cards.push(btns)

    return <div className="p-3 row">  
        <Tabs
            animate={true}
            id="Tabss"
            vertical={true}
            selectedTabId={ selectedTabId }
            onChange={ navID => {onselectedTabId(navID); console.log(navID);} }
            className="wpfest-admin-example"
        >
            {cards}
        </Tabs> 
    </div>
}
export default Example