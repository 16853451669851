import React, { Suspense } from "react" 
import { Loading } from "react-pe-useful" 
import LayoutBody from "./LayoutBody"
import UserContext from "./layoutConfig/userContext"
import LayoutProvider from "./layoutConfig/LayoutProvider"
import LayoutContent from "./LayoutContent"
import LayoutFooter from "./LayoutFooter"
import LayoutHeader from "./LayotHeader" 

class LayoutApp extends React.Component 
{  
  render() {  
    return <>
          <link rel="stylesheet" type="text/css" href="https://unpkg.com/normalize.css@^7.0.0" />
          <link rel="stylesheet" type="text/css" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" />
          <link rel="stylesheet" type="text/css" href="https://fonts.googleapis.com/css?family=Amatic+SC|Ubuntu+Condensed|Yanone+Kaffeesatz|Open+Sans+Condensed:300|Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap|Yanone+Kaffeesatz:200&display=swap" />
          <link rel="stylesheet" type="text/css" href="https://fonts.googleapis.com/css?family=PT+Sans:regular,italic,bold,bolditalic" />
          <link rel="stylesheet" type="text/css" href="https://cdn.jsdelivr.net/npm/social-likes/dist/social-likes_birman.css"></link>
          <link rel="stylesheet" type="text/css" href="/assets/css/bootstrap.min.css" />
          <link rel="stylesheet" type="text/css" href="/assets/css/blueprint.css" />
          <link rel="stylesheet" type="text/css" href="/assets/css/blueprint-icons.css" />
          <link rel="stylesheet" type="text/css" href="/assets/css/phone.css" />
          <Suspense fallback={<Loading />}>
              <LayoutBody />
          </Suspense>
          <script src="/assets/js/ckeditor/ckeditor.js" /> 
          <script src="https://unpkg.com/classnames@^2.2" />   
          <script src="https://www.youtube.com/iframe_api"></script>
          <script src='https://jitsi.protopia-home.ru/external_api.js'></script>
          <script src="https://unpkg.com/dom4@^1.8"></script>
          <script src="https://unpkg.com/react@^16.2.0/umd/react.production.min.js"></script>
          <script src="https://unpkg.com/react-dom@^16.2.0/umd/react-dom.production.min.js"></script>
          <script src="https://unpkg.com/react-transition-group@^2.2.1/dist/react-transition-group.min.js"></script>
          <script src="https://unpkg.com/popper.js@^1.12.6/dist/umd/popper.js"></script> 
          <script src="https://unpkg.com/@blueprintjs/core@^2.0.0"></script>
          <script src="https://unpkg.com/@blueprintjs/icons@^2.0.0"></script>          
          <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js"></script>
          <script src="https://cdn.jsdelivr.net/npm/social-likes/dist/social-likes.min.js"></script>
          <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js"></script>
          <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js"></script>  
        </> 
  }
}

export default LayoutApp
export {
  UserContext,
  LayoutProvider,
  LayoutContent,
  LayoutFooter,
  LayoutHeader,
  LayoutBody
}