import React, { Fragment } from 'react'
import PropTypes from 'prop-types' 
import { __ } from 'react-pe-utilities/dist'
import { useFestStore } from 'modules/pe-fest-module/data/store'

const FullMemberStatistics = props => {
    console.log( useFestStore.getState().statistics )
    const memberTRs = useFestStore.getState().statistics?.members?.map((member, i) => {
        const tutor = useFestStore.getState().statistics?.users?.filter(user => user.id === member.tutor_id )[0]
        let rating = 0;
        const crs = member.criteries.map((critery, ii) => {
            const full_rating_data = critery.full_rating_data[0] || { display_name : "---"}
            rating += critery.average_critery_rating
            return <tr key={critery.id} className={`${ critery.average_critery_rating ? "" : " opacity_5"}`}>
                <td>{i+1}.{ii + 1}</td>
                <td>
                    { full_rating_data.display_name }
                </td>
                <td>
                    {critery.title}   
                </td>
                <td>
                    {critery.average_critery_rating}   
                </td>
                <td>
                    { full_rating_data.description }   
                </td>
            </tr>
        })
        return <Fragment key={member.id}>
            <tr className='bg-light'>
                <td>
                    {i + 1}
                </td>
                <td colSpan={2} >
                    <div className='lead title py-2'>{member.title}</div> 
                    <div className='py-2'>
                        {__("Tutor")}: <span className='title'>{ tutor?.display_name } </span>| { tutor?.user_email }
                    </div> 
                </td>
                <td > 
                    <div className='py-2 display-6 title '>{ rating }</div> 
                </td>
                <td > 
                    <div className='py-2'> </div> 
                </td>
            </tr>
            {crs}
        </Fragment>
    })
    return <>
        <table className='table table-striped1 table-bordered  table-hover pe-fest-table-statistics'> 
            <thead className="thead-dark-33 bg-secondary text-white">
                <tr>
                    <th scope="col" width="55" >
                        {__("#")}
                    </th>
                    <th scope="expert">
                        {__("Expert")}
                    </th>
                    <th scope="col" >
                        {__("Critery")}
                    </th>
                    <th scope="col" width="100" >
                        {__("Rate")}
                    </th>
                    <th scope="col">
                        {__("Commentary")}
                    </th>
                </tr>
                
            </thead >
            <tbody>
               {memberTRs}
            </tbody> 
        </table>
    </>
}

FullMemberStatistics.propTypes = {}

export default FullMemberStatistics