import React from 'react'
import PropTypes from 'prop-types'
import { Tag } from '@blueprintjs/core'

const Tags = props => {
    const onClick = id =>
    {
        props.onChange(id,  props.field)
    }
    const values = Array.isArray(props.values)
        ?
        props.values.map((value, i) =>
            {
                if(value?._id)
                { 
                    return <Tag 
                        key={ value._id + "_" + i } 
                        intent={value.intent}
                        className="m-1 px-3 py-1 pointer" 
                        round
                        minimal={ props.value !== value._id }
                        onClick={() => onClick(value._id)}
                    >
                        {value.title}
                    </Tag>
                }
                else if( typeof value === "string")
                { 
                    return <Tag 
                        key={value + "_" + i} 
                        value={value }  
                        className="m-1 px-3 py-1 pointer" 
                        round
                        minimal={ props.value !== value }
                        onClick={() => onClick(value._id)}
                    >
                        { value }
                    </Tag>
                }
            })
        :
        []
    return (
        <div className='w-100 d-flex flex-wrap py-2 '>
           {values} 
        </div>
    )
}

Tags.propTypes = {}

export default Tags