import React, { Component } from "react"
import { useHistory, withRouter } from "react-router"
import { compose } from "recompose"
import _fetch from "."
import { sprintf, __ } from "react-pe-utilities"
import {AppToaster, Loading} from 'react-pe-useful' 
import WPFestSettings from "./utilities/WPFestSettings"
import FestComponent from "./FestComponent"
import User from "./utilities/User"
import { Button, ButtonGroup, Intent } from "@blueprintjs/core"
import { withFestStore } from "modules/pe-fest-module/data/store"
import FieldInput from "react-pe-scalars/dist"
import actions, { GET_TRACK_MODERATOR_ACTION, UPDATE_MEMBER_ACTION, UPDATE_OWNERS_ACTION } from "modules/pe-fest-module/data/actions"

class FestTrackModerator extends FestComponent 
{
	fetchCommand  = GET_TRACK_MODERATOR_ACTION
	fetchArgs     = { }
	header_type   = "track_moderator"
	state = {
		loading: true,
		requests: [],
	} 

	addRender = () =>
	{ 
		return (
			<div className="pt-3"> 
				{
					User.id 
					&& User.roles.filter((e) => e === "administrator" || e === "Track_moderator").length > 0
						? this.the_content()
						: this.the_none()
				} 
			</div>
		)
	}

	the_none() 
	{
		return (
			<div className="alert alert-danger p-5 w-100 my-2 lead text-center">
				{__("You no have rights.")}
			</div>
		)
	}
	the_content() 
	{
		console.log( this.props.store )
		return this.props.store.myTracks.length > 0
			?
			this.props.store.myTracks.map(track => {
				return <MyTrack
					key={track.ID}
					track={track}
					store={this.props.store}
					route={this.props.route}
					festid={WPFestSettings.festId}
				/>
			}) 
			:
			<div className="alert alert-secondary p-5 lead text-center">
				{
					__("No elements exists")
				}
			</div>
		 
	} 
}
export default compose(
	withRouter,
	withFestStore
)( FestTrackModerator )


export const MyTrack = props =>
{
	const history = useHistory()
	const onLinkMember = member =>
	{
		console.log(member)
		history.push(`${props.route}/member/${member.id}`)
	}
	const onTutorChange = (value, member_id) =>
	{
		console.log( value )
		_fetch(
			"get_fest_command",
			{ 
			  id: member_id,
			  command: "update_project_field", 
			  field: "tutor_id",
			  value, 
			  festId: WPFestSettings.festId
			},
			WPFestSettings.serviceURL,
			WPFestSettings.getToken( WPFestSettings.url ),
			"wpfa",
		  )
			.then((_data) => {
			  // console.log( data );
			  const data = _data.data 
			  if(data)
			  { 
				actions(UPDATE_MEMBER_ACTION, { id: member_id, member: data.member })
			  } 
			})
	}
	const onAuthorChange = (value, member_id) =>
	{
		console.log( value )
	}
	const onChangeStatus = (member_id, status) =>
	{
		_fetch(
			"get_fest_command",
			{
			  status,
			  member_id,
			  command: "change_member_status", 
			  festId: WPFestSettings.festId
			},
			WPFestSettings.serviceURL,
			WPFestSettings.getToken( WPFestSettings.url ),
			"wpfa",
		  )
			.then((_data) => {
			  // console.log( data );
			  const data = _data.data 
			  if(data)
			  { 
				actions(GET_TRACK_MODERATOR_ACTION, data)
			  } 
			})
	}
	const allow_role_req = member =>
	{
		_fetch(
			"get_fest_command",
			{  
				user_id: member.tutor.id,
				role: "Tutor",
			  	command: "forsed_user_role", 
				member_title: member.title,
			  	festId: WPFestSettings.festId
			},
			WPFestSettings.serviceURL,
			WPFestSettings.getToken( WPFestSettings.url ),
			"wpfa",
		  )
			.then((_data) => {
			  	const data = _data.data 
			  	console.log( data.new_user );
			  	if(data)
			  	{  
					AppToaster.show({
						intent: Intent.SUCCESS,
						className:"p-5",
						icon: "tick",
						message: __(
							data.send_mail 
								? 
								"The project has been verified and approved for participation in the Festival. The mentor received the rights to manage the project. He is informed about the change in his status by e-mail"
								: 
								"Error sending email to Project'a author."
						),	
						timeout: 25000
					}) 
					let newMember = {
						...member,
						tutor: data.new_user
				
					}
					actions(UPDATE_OWNERS_ACTION, { all_owners: data.all_owners })
					actions(UPDATE_MEMBER_ACTION, { id: member.id, member: newMember })
			  	} 
			})
	}
	const veto_role_req = member =>
	{
		_fetch(
			"get_fest_command",
			{  
				user_id: member.tutor.id,
				role: "Tutor",
			  	command: "forced_veto_role", 
				member_title: member.title,
				member_id: member.id,
			  	festId: WPFestSettings.festId
			},
			WPFestSettings.serviceURL,
			WPFestSettings.getToken( WPFestSettings.url ),
			"wpfa",
		  )
			.then((_data) => {
			  	const data = _data.data
			  	if(data)
			  	{  
					AppToaster.show({
						intent: Intent.SUCCESS,
						className:"p-5",
						icon: "tick",
						message: __(
							data.send_mail 
								? 
								"Member is informed about the change in his status by e-mail"
								: 
								"Error sending email to Project'a author."
						),	
						timeout: 15000
					}) 
					let newMember = {
						...member,
						tutor: ""
				
					}
					actions(UPDATE_MEMBER_ACTION, { id: member.id, member: newMember })
			  	} 
			})
	}
	const members = Array.isArray(props.store.allMembers) &&
		props.store.allMembers.filter( member => member.track.ID === props.track.ID ).length > 0 
		?
		props.store.allMembers
			.filter( member => member.track.ID === props.track.ID ) 
			.map((member, i) =>
			{ 
				return <div className="col-12 grey2" key={member.id + "_" + i }>
					<div className="row flex-centered">
						<div 
							className={`col-4 lead pointer py-2 ${ member.status === "draft" ? "opacity_5" : "" } d-flex` }
							onClick={ () => onLinkMember( member) }
						>
							<div>
								{member.title}
							</div>
						</div>
						<div className={`col-4 py-0 py-2 ${ member.status === "draft" ? "opacity_5" : "" }`}>
							{
								member.tutor && 
								Array.isArray(member.tutor.roles) && 
								member.tutor.roles.filter(role => role === "Tutor").length == 0
									?
									<ButtonGroup large fill className="">
										 <Button 
										 	className="p-2 w-100" 
											intent={Intent.SUCCESS}
											onClick={() => allow_role_req(member)}
										>
											<span className="small" dangerouslySetInnerHTML={{
												__html: sprintf(
														__("Give author %s tutor's capabilities"), 
														`<span class='title lead'>${member.tutor.display_name}</span><br>`
													)
											}} />
										</Button>
										 <Button 
										 	className="p-2 w-50" 
											intent={Intent.DANGER}
											onClick={() => veto_role_req(member)}	
										>
											{__("Veto")}
										</Button>
									</ButtonGroup>
									:
									<>
										{
											WPFestSettings.fmru_evlbl_roles.filter(role => role === "Tutor").length > 0
												?
												<FieldInput
													visualization="festFormsFields" 
													type="select"
													title={__("Tutor")}
													editable
													value={ {
														_id: member.tutor?.id,
														title: member.tutor.display_name 
													}}
													values={
														[
															{ id:"", display_name: "---" },
															...props.store.allOwners
														].map(owner => {
															return {
																_id: owner.id,
																title: owner.display_name
															}
														})
													}
													onChange={ value => onTutorChange(value, member.id) }
												/>
												:
												null
										}
										{
											WPFestSettings.fmru_evlbl_roles.filter(role => role === "Project_author").length > 0
												?
												<FieldInput
													visualization="festFormsFields" 
													type="select"
													title={__("Project_author")}
													editable
													value={ member.author?.id }
													values={
														[ 
															{ id:"", display_name: "---" },
															...props.store.allOwners
														].map(owner => {
															return {
																_id: owner.id,
																title: owner.display_name
															}
														})
													}
													onChange={ value => onAuthorChange(value, member.id) }
												/>
												:
												null
										}
									</>
							} 
						</div>
						<div className="col-2" >
							 { member.track.post_title }
						</div>
						<div className="col-2 " >
						{
							member.status === "draft"
								?
								<Button 
									minimal 
									fill 
									intent={Intent.DANGER} 
									rightIcon="eye-open" 
									className="px-5"
									onClick={() => onChangeStatus(member.id, "publish")}
								>
									{__("Do show")}	
								</Button>
								:
								<Button 
									minimal 
									fill 
									intent={Intent.SUCCESS} 
									rightIcon="eye-off" 
									className="px-5"
									onClick={() => onChangeStatus(member.id, "draft")}
								>
									{__("Do hide")}	
								</Button>

						}
						</div>
					</div>
				</div>
			})
		:
		<div className="p-4 lead font-italic text-dark-primary">
		{
			__("No members in track yet")
		}
		</div>
	return <div className="pe-fest-my-track-block row grey2 py-3 border-top border-secondary mb-4 ">
		<div className="col-12 d-flex">
			{
                props.track?.thumbnail 
                    ? 
                    <img src={props.track?.thumbnail} alt="track" className='track-miniature'/> 
                    :
                    null
            }
			<h3>{ props.track.post_title }</h3>	 
		</div>
		{ members }
	</div>
}