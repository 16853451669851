import React, { Component,  } from "react"  
import { Intent } from "@blueprintjs/core"
import _fetch from "."
import { __ } from "react-pe-utilities"  
import WPFestSettings from "./utilities/WPFestSettings"
import TextArea from "./utilities/TextArea"
import { AppToaster } from 'react-pe-useful'

class ExpertDescriptions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      text: "",
      denabled: this.props.denabled,
    }
  }

  render() {
    const { data, member_id } = this.props
    const { denabled } = this.state
    //if(data?.length === 0) return null
    const is_expert = this.props.user.roles.filter((e) => e === "Expert").length > 0
    const articleElements = data && data?.length > 0
      ? 
      data.map((article, index) => {
        const classes = index === 0 ? ["col-md-12", "critery_cell3", "light-colored "] : ["col-md-12", "critery_cell3", "grey2"]
        return !article.txt || article.txt === " " 
          ? 
          "" 
          :          
          <div className={classes.join(" ")} key={`exp_descr_${member_id}_${index}`}>
            <blockquote>
              <div className="lead">
                {article.txt}
              </div>
              <div className="mt-4">
                {article.auth}
              </div>
            </blockquote>
          </div>
      })
      :	
      <div className={["col-md-12", "critery_cell3", "grey2", "first_row"].join(" ")}>
        {__("No descriptions")}
      </div>

    const _textarea = denabled === 0
      ? (
        <>
          <textarea
            className="col-md-6 col-sm-12 input dark form-control"
            rows="10"
            placeholder={__("Start write")}
            style={{ marginBottom: 10, padding: 20, height:300 }}
            value={this.state.text}
            onChange={this.getComment}
          />
          <div className="col-md-6 col-sm-12">
            <div className="btn btn-lg btn-primary" onClick={this.send}>
              {__("Send descriptions")}
            </div>
            <div className="mt-4">
              <small>
                Вы можете оставить текстовые комментарии и рекомендации для проектной команды.
                <p>Участникам Фестиваля важно получить обратную связь по вопросам:</p>
                <ul>
                  <li>Какие задачи для развития замысла могут быть рекомендованы?</li>
                  <li>Какие задачи развития Вы рекомендуете для самих авторов – чему важно дальше учиться?</li>
                </ul>
              </small>
            </div>
          </div>
        </>
      )
      : (
        <div className="">
          {__("You are already send description")}
        </div>
      )

    /**/
    const form = is_expert
      ? 			(
        <div>
          <div className="row pt-4 pl-3 bl-20">
            <div className="col-md-12">
              <div className="lead">
                {__("Your parting words for the authors of Project")}
              </div>
            </div>
          </div>
          <div className="row pt-4 pl-3 bl-20">
            {_textarea}
          </div>
        </div>
      )
      : null

    return (
      <>
        <div>
          <div className="row mt-4">
            <div className="col-md-7">
              <div className="display-6">
                {__("Expert's parting words for the authors of Project")}
              </div>
            </div>
            <div className="col-md-5" />
          </div>
          <div className="row mt-3 bl-20">
            <div className="col-md-12">
              <div className="w-100   ">
                {articleElements}
              </div>
            </div>
          </div>
        </div>
        { form }
      </>
    )
  }

	changeComment = (evt) => {
	  const val = evt.currentTarget.value
	  this.getComment(val)
	}

	getComment = (text) => {
	  console.log(text)
	  this.setState({
	    text,
	  })
	}

	send = (text) => {
	  if (this.state.text === "") {
	    AppToaster.show({
	      intent: Intent.SUCCESS,
	      icon: "tick",
	      message: "You must insert text",
	    })
	    return
	  }
    const args = {
      text: this.state.text,
      member_id: this.props.member_id,
    }
    _fetch(
	    "get_fest_command", 
	    {...args, festId: WPFestSettings.festId, command:"send_member_descr"},
	    WPFestSettings.serviceURL,
	    WPFestSettings.getToken( WPFestSettings.url ),
	    "wpfa",
	  )
	    .then((_data) => {
        this.setState({
          denabled: false,
        })
	    })
	  // _fetch(
	  //   "send_member_descr",
	  //   {
	  //     text: this.state.text,
	  //     member_id: this.props.member_id,
	  //   },
	  //   WPFestSettings.url,
	  //   WPFestSettings.token,
	  //   "get_main",
	  // )
	  //   .then((data) => {
	  //     console.log(data)

	  //     this.setState({
	  //       denabled: false,
	  //     })
	  //     /**/
	  //   })
	}
}
export default ExpertDescriptions
