import React, {Fragment,Component} from "react"; 
import { Icon, Tag, Intent, Tooltip, Card, Position, Popover, Button } from "@blueprintjs/core";
import Categories from "./Categories"; 
import { __ } from "react-pe-utilities/dist";
import _fetch from "modules/pe-wpfest-admin-module/api";
import IndexDB from "modules/pe-admin-module/views/utilities/IndexDB"
import HoneycombsForm from "./single/HoneycombsForm";

export default class Honeycombs extends Categories
{
	type = "honeycombs"
	componentWillMount()
	{ 
		IndexDB.find( 
				"peFestAdmin_mySites",
				"sites",
				"sites",
				this.props.blog_id
			)
				.then( sites =>
				{
					let fmru_ganres = sites.data.ganres
						?
						sites.data.ganres
						:
						[]
					let users = sites.data.users
						?
						sites.data.users
						:
						[]
					//console.log("this.props.blog_id: ", this.props.blog_id)
					//console.log("users: ", sites.data.users)
					//console.log("sites: ", sites )
					//console.log("ganres: ", fmru_ganres )
					this.init({
						form_component	: HoneycombsForm, 
						theadColor		: "#114466",
						meta			: { 
							order 			: this.state.post.length > 0 
								? 
								parseInt(this.state.post[this.state.post?.length - 1].order) + 1 
								: 
								0
						}
					})
				})
							
		
	} 
	rows()
	{
		let rw = super.rows().filter(elem => elem[0] != 'color'); 
		return rw;
	}   
	onApproved = (evt, elem) =>
	{
		//console.log( elem.id )
		let state = {} 
		const post = [...this.state.post].map((e, i)=> {
			if(e.id === elem.id)
			{
				elem.post_status = evt.currentTarget.checked ? "publish" : "pending"
				state = e

			}
			return e;
		})
		this.setState({post})
		_fetch( "update_object" , { 
			format 	: this.props.format, 
			termin 	: this.props.termin, 
			data	: state,
			id		: elem.id,
			blog_id	: this.props.blog_id
		})
			.then(data =>
			{
				console.log(data);
				// if(data.post) this.setState({ post:data.post })
			}) 
	}
	onCreate = data =>
	{
		this.setState({post:data});
	}
	onBitchCreation = () =>
	{
		this.setState({is_batch_creation:!this.state.is_batch_creation, isNew:false});
	} 
}