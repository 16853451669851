import React, { Component,   } from "react"
import {  Link } from "react-router-dom" 
import _fetch from "../.."
import { __ } from "react-pe-utilities" 
import GanreIcon from "../../GanreIcon" 
import TrackLabel from "../../../track/TrackLabel"
import HoneycombsLabel from "../../../honeycombs/HoneycombsLabel"
import MemberRatingDisplay from "../MemberRatingDisplay"
import { withFestStore } from "modules/pe-fest-module/data/store"
import { compose } from "recompose"

class FestMemberStroke extends Component {
  render() { 
    //console.log(this.props)
    const {
      id, o, e, img, ganre, title, content, ganres, track, honeycombs, festData
    } = this.props 
    const _ganres = festData?.is_ganres
      ?
      ganres.map((ganre, index) => <GanreIcon ganre={ganre} key={ganre.id} />)
      :
      null
    const table = {
      height: "105px", overflow: "hidden", position: "relative", display: "table", padding: "5px 30px",
    }
    const cell = { display: "table-cell", verticalAlign: "middle" }
    const url = `${this.props.route}/member/${id}`
    return (
      <div className="w-100 grey2 position-relative overflow-hidden"> 
        <Link to={url} className="row hover">
          <div className="col-lg-3 col-md-5 col-sm-6 col-12">
            <div
              className="card-img"
              style={{
							  backgroundImage: `url(${img})`,
							  backgroundColor: "#00000005",//"transparent",
							  border: "0",
							  height: "100%",
                overflow:"hidden"
              }}
              data-mid={id}
            >
              <div className="card-id">{ o}</div>
              <div className="card-icons">{ _ganres }</div>
              
            </div>
          </div>
          <div
            className="col-lg-3 col-md-2 col-sm-4 col-8 d-flex flex-centered flex-column"
            style={table}
          >
            
              <h5
                className="card-title "
                style={cell}
              >
                {title}
              </h5>
                         
          </div>
          <div
            className="col-lg-1 col-md-1 col-sm-2 col-4 d-flex"
            style={table}
          >            
            <MemberRatingDisplay 
              correct_member_rate={this.props.correct_member_rate}
              countedRating={this.props.r2}
              className={"text-dark "}
            />           
          </div>
          <div className="col-lg-5 col-md-4 col-sm-12 col-12 text-secondary py-4 pl-md-0 pl-4 pr-5" style={{}} >
            <div style={cell} dangerouslySetInnerHTML={{ __html: content }} />
            <div> {__("Track")}: <TrackLabel track={track} className="title d-inline my-2 ml-2"/></div>
            <div> {__("Honeycomb")}: <HoneycombsLabel honeycombs={honeycombs}  className="title d-inline my-2 ml-2"/> </div>
          </div>
          
        </Link>
            {
              this.props.store.myTracks.filter(mtID => mtID == track.ID).length > 0
                ?
                <div className="my-track-label">
                  { __( "My Track" ) }
                </div>
                :
                null
            }
            {
              this.props.isMine
                ?
                <div className="mine-corner">
                  { __( "My project" ) }
                </div>
                :
                null
            }
            {
              this.props.isMineHoneycoms
                ?
                <div className="mine-honeycombs-corner">
                  { __( "My honeycombs" ) }
                </div>
                :
                null
            }
      </div>
    )
  }
}
export default compose( 
	withFestStore,
  )(FestMemberStroke)
