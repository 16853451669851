import React from "react" 
import LoginForm from "./profile/LoginForm"
import BasicState from "react-pe-basic-view"
import { __ } from "react-pe-utilities/dist"


class LoginView extends BasicState { 

  addRender = () => <div className="container">
    <div className="row justify-content-center">          
      <div className="col-12">
              <div className="page-title text-center mb-3">
                {__("Log in")}
              </div>
      </div>
      <LoginForm />
    </div>
  </div>
}
export default LoginView 
