import { Button, Dialog, Icon, Intent } from "@blueprintjs/core"; 
import _fetch from "modules/pe-wpfest-admin-module/api";
import WPFestAdminContext from "modules/pe-wpfest-admin-module/api/WPFestAdminContext";
import User from "modules/pe-wpfest-admin-module/User";
import { deleteCookie, setCookie } from "modules/pe-wpfest-admin-module/utilities/utils";
import VKLogin from "modules/pe-wpfest-admin-module/utilities/VKLogin";
import React, {useState} from "react";
import { AppToaster } from "react-pe-useful/dist";
import { __ } from "react-pe-utilities/dist";
import { useHistory } from "react-router";
import CabinetView, { TOKEN_COOKIE_NAME, WPFEST_ADMIN_MY_ROUTE } from "../CabinetView";
import LoginForm from "./LoginForm";
import { YandexLogin, YandexLogout } from 'react-yandex-login';
import MergeLocal from "./MergeLocal";

const Login = props =>
{ 
    const [isOpen, onOpen] = useState(props.isOpen)
    const [isMergeOpen, onMergeOpen] = useState(props.isMergeOpen)
    const [user, setUser] = useState(props.user)
    const histoty = useHistory()
    
    const onLogOut = () =>
    {
        setCookie( TOKEN_COOKIE_NAME, "" )
        setUser({})
        setTimeout(() => {
            // пауза, чтобы успела обновиться кука
            histoty.push( '/' + CabinetView.WPFESTAdminRoute )
        }, 100)
        
    }
    const onVK = evt =>
	{
		console.log( "onVK" );
	}
    const responseVk = response =>
	{
		let res = response[0]
		res.oauth_type = "vk";
		console.log(response);
		_fetch( "vk_auth", res )
			.then(data => 
			{
				// console.log(data);
                if(data.isError)
                {
                    return
                }
				if(data.is_login)
				{
                    setCookie(TOKEN_COOKIE_NAME, data.token, { expires : 72 * 3600 } ); 
                    User.setData( data.user );
                    window.location.reload()
                    //histoty.push( props.preroute + "/" + props.route + "/" + WPFEST_ADMIN_MY_ROUTE )
				}
			});
	}
    const onYandex = () =>
    {  
        const access_token = CabinetView.global_options.yandex_access_token.value 
        const clienSecret = CabinetView.global_options.yandex_client_secret.value 
        const clienID = CabinetView.global_options.yandex_client_id.value 
        const urlAccess = `https://oauth.yandex.ru/authorize?response_type=token&client_id=${ clienID }`
        const url = `https://login.yandex.ru/info?format=json&jwt_secret=${clienSecret}&with_openid_identity=1&oauth_token=${ access_token }`
		var headers = {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
            'Authorization': `OAuth ${access_token}`
		};
        fetch( url )
            .then(r => {
                console.log( r )
                try 
                {
                    const res = r.json()
                    // console.log( res )	
                    return res;  
                }
                catch(e)
                {
                    console.log( e)
                }            
            })
                .then( res => {
                    // console.log( res )
                    res.email = res.default_email
                    res.href = res.display_name + "_yandex"
                    res.oauth_type = "yandex"
                    _fetch( "yandex_auth", res )
                        .then( data => 
                        {
                            // console.log(data);
                            
                            if(data.isError)
                            {
                                return
                            }
                            if(data.is_login)
                            {
                                setCookie(TOKEN_COOKIE_NAME, data.token, { expires : 72 * 3600 } ); 
                                User.setData( data.user );
                                window.location.reload()
                                //histoty.push( props.preroute + "/" + props.route + "/" + WPFEST_ADMIN_MY_ROUTE )
                            }
                        });
                })
    }
    // const tg = () =>
    // {
    //     return <>
    //         <script 
    //             async 
    //             src="https://telegram.org/js/telegram-widget.js?19" 
    //             data-telegram-login="kraefestBot" 
    //             data-size="medium" 
    //             data-radius="0" 
    //             data-onauth="onTelegramAuth(user)" 
    //             data-request-access="write"
    //         ></script>
    //         <script type="text/javascript">
    //             function onTelegramAuth(user) 
    //             {
    //                 alert('Logged in as ' + user.first_name + ' ' + user.last_name + ' (' + user.id + (user.username ? ', @' + user.username : '') + ')')
    //             }
    //         </script>
    //     </>
        
    // }
    const renderContext = ( ) =>
    { 
        return user?.ID > 0 
            ?
            <div className="ml-auto d-flex">
                < MergeLocal />
                <Button
                    className="text-light"
                    large
                    minimal
                    disabled 
                >
                    { user.display_name }
                </Button>
                <Button
                    className="ml-auto text-light hint hint--bottom"
                    large
                    minimal
                    icon="log-out"
                    onClick={onLogOut}
                    data-hint={__("Log out")}
                >
                </Button>
                
            </div>
            
            :
            <>
                <div className="ml-auto ml-auto position-absolute z-index-100 right d-flex justify-content-center">
                    <Button
                        className=""
                        large
                        minimal={false}
                        intent={Intent.SUCCESS}
                        onClick={() => onOpen(true)}
                    >
                        { __( "Log in" )  }
                    </Button>                
                    {/*  */}
                    <VKLogin
                        apiId={ CabinetView.global_options?.vk_app_id?.value }
                        value={"VK"}
                        fields="name,email,picture"
                        onClick={ onVK }
                        callback={ responseVk} 
                        className="wpfest-admin-cs"
                    />
                    <div className=" wpfest-admin-cs -hidden"> 
                            <div className="soc yandex wpfest-admin-cs " onClick={ onYandex }>
                                <i className="fab fa-yandex" />
                            </div> 
                    </div>
                </div>
                <Dialog
                    isOpen={isOpen}
                    onClose={() => onOpen(false)}
                    className="little"
                >
                    <LoginForm
                        onClose={ () => onOpen(false) }
                        {...props}
                    />
                </Dialog>
            </>
    }
    return <>
        {
            renderContext( ) 
        }
    </>
}
export default Login