import React, {Fragment,Component} from "react"; 
import { Icon, Tag, Intent, Tooltip, Card, Position, Popover, Button } from "@blueprintjs/core";
import Categories from "./Categories";
import ProjectForm from "./single/ProjectForm";
import ProjectBatchForm from "./single/ProjectBatchForm";
import { __ } from "react-pe-utilities/dist";
import _fetch from "modules/pe-wpfest-admin-module/api";
import IndexDB from "modules/pe-admin-module/views/utilities/IndexDB";

export default class Projects extends Categories
{
	type = "project"
	componentWillMount()
	{ 
		IndexDB.find( 
				"peFestAdmin_mySites",
				"sites",
				"sites",
				this.props.blog_id
			)
				.then( sites =>
				{
					let fmru_ganres = sites?.data?.ganres
						?
						sites.data.ganres
						:
						[]
					let users = sites?.data?.users
						?
						sites.data.users
						:
						[]
					//console.log("this.props.blog_id: ", this.props.blog_id)
					//console.log("users: ", sites.data.users)
					//console.log("sites: ", sites )
					//console.log("ganres: ", fmru_ganres )
					this.init({
						form_component	: ProjectForm,
						theadColor		: "#ab2da1",
						meta			: { 
							fmru_ganre 		: [], 
							fmru_ganres 	: fmru_ganres, 
							fmru_honeycombs	: [],
							fmru_tracks		: [],
							fmru_users		: users,
							form_fields		: this.props.form_fields,
							festivalOptions	: this.props.options,
							order 			: this.state.post.length > 0 
								? 
								parseInt(this.state.post[this.state.post?.length - 1].order) + 1 
								: 
								0
						}
					})
				})
		_fetch("lead_fest_ganres", {id: this.props.blog_id})
			.then(data =>
				{ 
					//console.log(data)
					let fmru_ganres = data.lead.post || []
					if(!data.isError)
					{					
						_fetch("lead_fest_honeycombs", {id: this.props.blog_id})
							.then(data =>
							{ 
								let fmru_honeycombses = data.lead.post || []
								if(!data.isError)
								{				
									_fetch("lead_fest_tracks", {id: this.props.blog_id})
										.then(data =>
										{  
											let fmru_tracks = data.lead.post
											if(!data.isError)
											{
												_fetch("lead_fest_users", {id: this.props.blog_id})
													.then(data1 =>
													{
														let fmru_users 		= data1.lead.post 
														this.init({
															form_component	: ProjectForm,
															theadColor		: "#ab2da1",
															meta			: { 
																fmru_ganre 		: [], 
																fmru_tracks		: fmru_tracks, 
																fmru_honeycombs : fmru_honeycombses, 
																fmru_ganres 	: fmru_ganres, 
																fmru_users		: fmru_users,
																form_fields		: this.props.form_fields,
																festivalOptions	: this.props.options,
																order 			: this.state.post.length > 0 
																	? 
																	parseInt(this.state.post[this.state.post?.length - 1].order) + 1 
																	: 
																	0
															}
														})
														IndexDB.find( 
															"peFestAdmin_mySites",
															"sites",
															"sites",
															this.props.blog_id
														)
															.then(res =>
															{
																//console.log( { ...res.data, ganres : data.lead.post, users: fmru_users } )
																IndexDB.save(
																	{
																		...res.data, 
																		ganres : data.lead.post,
																		users: fmru_users
																	},
																	"sites",
																	this.props.blog_id,
																	"sites",
																	"peFestAdmin_mySites",
																	1
																)
																IndexDB.save(
																	{
																		...res.data, 
																		ganres : data.lead.post,
																		users: fmru_users 
																	},
																	"sites",
																	this.props.blog_id,
																	"sites",
																	"peFestAdmin_allSites",
																	1
																)
															})		
													})
											}		
										})
								} 
							} )
					}
				})
	}
	row_edit()
	{
		return <Tooltip intent={Intent.DANGER} content={__(!this.state.is_batch_creation ? "batch creation" : "close")} key={"bcr"}>
			<div className="btn btn-link text-light btn-sm" onClick={this.onBitchCreation}>
				<Icon icon={!this.state.is_batch_creation ? "layout-grid" : "undo" } />
			</div>
		</Tooltip> 
	}
	rows()
	{
		let rw = super.rows().filter(elem => elem[0] != 'color');
		rw.splice( 1, 0,
			[
				'status',	
				<div style={{minWidth:50, display:"flex", justifyContent:"center"}} key={"status"}>
					<Tooltip intent={Intent.DANGER}  position={Position.TOP} content={__("Approved")}><Icon icon="endorsed" /></Tooltip>
				</div>, 
				50
			],
			[
				'thumbnail',	
				<div style={{minWidth:50, display:"flex", justifyContent:"center"}} key={"status2"}>
					<Tooltip intent={Intent.DANGER}  position={Position.TOP} content={__("Thumbnail")}><Icon icon="media" /></Tooltip>
				</div>, 
				120
			]
		);
		rw.splice( 5, 0,
			[
				'r2',	
				<div style={{minWidth:140, display:"flex", justifyContent:"center"}} key={"offer_rating"}>
					{__("Offer rating")}
				</div>, 
				140
			]
		); 
		return rw;
	}
	onRow(col, elem)
	{
		let txt = super.onRow(col, elem);
		switch(col)
		{
			case "status":
				txt = <div className="d-flex justify-content-center" >
					<label className="_check_green_ m-1" >
						<input 
							type="checkbox" 
							onChange={ evt => this.onApproved(evt, elem) }
							value="1"
							checked={ elem.post_status == "publish" }
						/>
					</label>
				</div>
				break
			case 'thumbnail':
				txt =  <div style={{
					width: 70,
					height:70,
					backgroundSize: "cover",
					backgroundPosiztion:"center",
					backgroundImage:`url(${elem.thumbnail})`
				}} />
				break
			case 'r2':
				let rating = "";
				const razbludovka = <div className="mb-1 w-100">
				{
					elem.catergories?.map((category, i) => {
						if(!category.rating)	return null
						return <div className="d-flex mb-2" key={i}>
							<div 
								className="small d-flex align-items-end justify-content-start mb-1" 
								style={{
									 width: "calc(100% - 40px)", 
									 overflow:"hidden", 
									 textOverflow : "ellipsis",
									 lineHeight : .9,
									 borderBottom: "1px dotted #00000055"
								}}
							>
								{ category.title }
							</div>
							<div 
								className=" text-right d-flex align-items-end justify-content-end text-bold" 
								style={{ minWidth: 35 }}
							>
								{ category.rating ? category.rating.toFixed(2) : "" }
							</div>
						</div>
					})
				}
				<div className="d-flex flex-wrap">
				{
					elem.experts?.map((expert, i) =>
					{
						return <div key={i} className="mr-1 mb-1 px-2 text-light small bg-secondary pointer ">
							{expert.display_name}	
						</div>
					})	
				}	
				</div>
				
				</div>
				switch( this.props.options.rating_type )
				{
					case "each_category":
					case "distribution_procentage":
						rating = razbludovka
						break
					case "independent_grade":
					default:
						const popover = elem.r2 
							?
							<Popover 
								interactionKind="hover"
								content={
									<div className="p-4">
										{ razbludovka }
									</div>
								}
							>
								<Button minimal icon="arrow-down" >

								</Button>
							</Popover>
							:
							null
						rating = <div>
							<div>
								{ elem.r2 ? elem.r2.toFixed(2) : "" }
							</div> 
							{popover}							
						</div>
						break
				}
				txt = <div className="d-flex justify-content-center">
					{rating}
				</div>
		}
		return txt;
	}
	getPostTitle = elem =>
	{
		const fields = Array.isArray( elem.form_fields ) && Array.isArray( this.props.options.form_fields )
			?	
			elem.form_fields.map((form, i) => { 
				if(!this.props.options.form_fields[i]) return
				let label = form ? form.join(" ") : ""
				switch(this.props.options.form_fields[i].type)
				{
					case "url":
						label = Array.isArray(form)
							?
							form.map((e, ii) => {
								return e ? <a key={ii} href={e} target="_blank" className="ml-2">{__("link")}</a> : null	
							})
							:
							null
						break;
					default:
						label = form ? form.join(" ") : ""
				}
				return this.props.options.form_fields[i].hide_in_projects
					?
					null
					:				
					<Tag 
						interactive={true}
						className="small"	 
						minimal
						key={i}
						style={{margin: 2, fontSize: 10, padding: "0 15px", minHeight: 12 }}
						round
					>
						{ this.props.options.form_fields[i].name }:<span className="title ml-2">{label}</span> 
					</Tag>
			})
			:
			null
		//console.log( elem )
		const tags = elem.fmru_ganre?.map(e => <Tag
					style={{backgroundColor : e.color, marginRight:2}}
					interactive={true}
					key={e.id}
				>
					{e.post_title}
				</Tag>);
		const fmru_track =  elem?.fmru_track?.id
			?
			<>
				{__("Track")}: 
				<Tag
					style={{ marginLeft: 12 }}
					interactive={false} 
				>
					{ elem?.fmru_track?.post_title }
				</Tag> 
			</>
			:
			null
		const fmru_honeycombs =  elem?.fmru_honeycombs?.id
			?
			<>
				{__("Honeycombs")}: 
				<Tag
					style={{ marginLeft: 12 }}
					interactive={false} 
				>
					{ elem?.fmru_honeycombs?.post_title }
				</Tag> 
			</>
			:
			null
		const tutorData = this.meta.fmru_users.filter(user => user.id == elem.tutor_id)[0] || {}
		const tutor = this.props.options.fmru_evlbl_roles.filter(role => role === "Tutor").length > 0
			&& elem.tutor_id > 0
			?
			<>
				{__("Tutor")}:
				<span style={{ marginLeft: 12 , fontSize:10}} >
					{ 
						Array.isArray(this.meta.fmru_users) 
							? 
							tutorData.display_name + 
							" | " +
							tutorData.user_email
							: 
							"-- "
					}
				</span>
			</>
			:
			null
		return <Fragment>
			<div style={{width: 240}} className="title">{elem.post_title}</div>
			<div style={{width: 240}}>{tags}</div>
			<div style={{width: 240}}>{fields}</div>
			<div style={{marginTop:12}}>{fmru_track}</div>
			<div style={{marginTop:12}}>{fmru_honeycombs}</div>
			<div style={{marginTop:12}}>{tutor}</div>
		</Fragment>
	}
	addThead()
	{
		return this.state.is_batch_creation ?
		<tr>
			<td colSpan={12}>
				<ProjectBatchForm 
					fmru_ganres = {this.meta.fmru_ganres}
					goClose = {this.onBitchCreation}
					onCreate = {this.onCreate}
					blog_id = {this.props.blog_id}
				/>
			</td>
		</tr> : null;
	}
	onApproved = (evt, elem) =>
	{
		//console.log( elem.id )
		let state = {} 
		const post = [...this.state.post].map((e, i)=> {
			if(e.id === elem.id)
			{
				elem.post_status = evt.currentTarget.checked ? "publish" : "pending"
				state = e

			}
			return e;
		})
		this.setState({post})
		_fetch( "update_object" , { 
			format 	: this.props.format, 
			termin 	: this.props.termin, 
			data	: state,
			id		: elem.id,
			blog_id	: this.props.blog_id
		})
			.then(data =>
			{
				console.log(data);
				// if(data.post) this.setState({ post:data.post })
			}) 
	}
	onCreate = data =>
	{
		this.setState({post:data});
	}
	onBitchCreation = () =>
	{
		this.setState({is_batch_creation:!this.state.is_batch_creation, isNew:false});
	} 
}