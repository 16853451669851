import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { compose } from "recompose"
import { withRouter, } from "react-router" 
import FestComponent from "./FestComponent"
import { initArea, __ } from 'react-pe-utilities/dist'
import _fetch from 'modules/pe-wpfest-admin-module/api'
import WPFestSettings from './utilities/WPFestSettings'
import { withFestStore } from 'modules/pe-fest-module/data/store'

class FestRegisterNewProject extends FestComponent  
{
    fetchCommand = "null"
    fetchArgs = {}
    header_type = "reqister-user"
    setStartState = () =>
    {
        return { 
            extendFetchName: null
        }
    }
    onExtendFetchParams = data =>
    {

    }

    onRegister = () => {
         
    }
    the_none = () =>
    {
        return  <> 
            {
                initArea(
                    "fest-register-form",
                    {
                        ...this.props,
                        ...this.state,
                        role: "Tutor",
                        onExtendFetchParam : this.onExtendFetchParams
                    },
                )
            }
            
        </>
    }


    addRender = () =>
	{
        return <div className="short-container my-4">           
            { this.props.store.options.is_register_project ? 1 : 0}             
            { 
                WPFestSettings.is_register && this.props.store.options.is_register_project
                    ? 
                    <>        
                        { this.the_none()  }  
                        <div className="col-12 justify-content-center">
                            <div className="btn btn-primary mt-5" onClick={this.onRegister}>
                            {__("Register new project")}
                            </div> 
                        </div>                        
                    </>                  
                    : 
                    <div className="alert alert-danger p-5 w-100 my-2">
                        {__("Registration of new participants at the Festival is not possible")}
                    </div> 
            }            
        </div>
    }
}

FestRegisterNewProject.propTypes = {}

export default compose(
    withRouter,
    withFestStore
)(FestRegisterNewProject)