import React from 'react'
import PropTypes from 'prop-types' 
import { MediaChooser } from 'react-pe-useful/dist'
import { useState } from 'react'
import FieldInput from 'react-pe-scalars/dist'

const FormType = props => {
    const [value, setValue] = useState(props.value || "")
    const onChange = value =>
    {
        setValue( value )
        props.onChange( value )
    }
    const getValues = () =>
    {
        const values = props.values || ""
        return values.split("/").map( v => {
            return {
                _id: v,
                title: v,
                color:"#FF0000"
            }
        }) 
    }
    switch(props.type)
    {
        case "tags":
            return <FieldInput
                visualization="festFormsFields" 
                type={ "tags" }
                count={ props.count }
                values={ getValues() }
                className={` w-100 input dark form-control `}
                vertical
                editable
                value={ value ? value : "" } 
                on={onChange}
                onChange={onChange}
            />
        case "select":
            return <FieldInput
                visualization="festFormsFields" 
                type={ "select" }
                values={ getValues() }
                className={` w-100 input dark form-control `}
                vertical 
                editable
                width={ 250 }
                height={ 80 }
                value={ value ? value : "" }
                on={onChange}
                onChange={onChange}
            /> 
        case "RussianRegions":
            return <FieldInput
                visualization="festFormsFields" 
                type="RussianRegions"
                className={` w-100 input dark form-control `}
                vertical 
                editable={true}
                value={ value ? value : "" }
                on={onChange}
                onChange={onChange}
            /> 
        case "phone":
            return <FieldInput
                visualization="festFormsFields" 
                type={ "phone" }
                className={` w-100 input dark form-control `}
                vertical 
                editable 
                value={ value ? value : "" }
                on={onChange}
                onChange={onChange}
            /> 
    }
    return <>
        <FieldInput 
            type={ props.type }
            values={ getValues() }
            className={` w-100 input dark form-control `}
            vertical 
            width={ 250 }
            height={ 80 }
            value={ value ? value : "" }
            on={onChange}
            onChange={onChange}
        /> 
    </> 
}

FormType.propTypes = {}

export default FormType