import React from "react"
import { Intent } from "@blueprintjs/core" 
import { Route, Switch, withRouter, } from "react-router"
import _fetch from "./wpfestREST"
import FestAbout from "./wpfestREST/FestAbout"
import FestMembers from "./wpfestREST/FestMembers"
import FestMember from "./wpfestREST/FestMember"
import FestNewProjectForm from "./wpfestREST/FestNewProjectForm"
import FestRegister from "./wpfestREST/FestRegister"
import FestRequests from "./wpfestREST/FestRequests"
import FestResults from "./wpfestREST/FestResults"
import MenuFest from "./wpfestREST/MenuFest"
import WPFestSettings from "./wpfestREST/utilities/WPFestSettings"
import { __ } from "react-pe-utilities" 
import BasicState from "react-pe-basic-view"
import {  getCookie, } from "./wpfestREST/utilities/utils"
import {Loading} from 'react-pe-useful' 
import "../assets/css/style_wp_ferst.css"
import FestRegisterRole from "./wpfestREST/FestRegisterRole"
import FestRegisterNewProject from "./wpfestREST/FestRegisterNewProject"
import FestReglament from "./wpfestREST/FestReglament"
import FestNewHoneycombForm from "./wpfestREST/FestNewHoneycombForm" 
import FestTrackModerator from "./wpfestREST/FestTrackModerator"
import Fest from "./wpfestREST/Fest"

export const DEFAULT_WPFEST_ADMIN_URI = "https://wpfest.ru"

class TutorWPFest extends BasicState {
  basic_state_data() {    
    return {
      isLeftClosed: false,
      loading: true,
    }
  }

  componentWillMount() { 
    const {  serviceUrl, serviceId } = this.props.extend_params 
    const token = getCookie(`wpfest_token${this.props.extend_params.festUrl}`)
    // console.log( this.props )
    this.interval = setInterval(() => 
    {
      clearInterval( this.interval )
      this.setState(
        { loading: false },
        WPFestSettings.set({
          is_comment: true,
          serviceURL: serviceUrl || DEFAULT_WPFEST_ADMIN_URI,
          token, 
          fmru_evlbl_roles: []
        }),
      )
      if( serviceId && this.props.location.pathname === "/" + this.props.route )
      {
        this.props.history.push("/" + this.props.route + "/" + serviceId)
      }
    }, 100)
   
  }
  componentWillUnmount()
  {
    clearInterval( this.interval )
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="layout-state p-0 m-0">
          <Loading />
        </div>
      )
    }
    const { preroute, route, extend_params } = this.props
    const { festUrl, festID } = extend_params
    const wpfest_roles = getCookie(`wpfest_roles${festUrl}`)
    const wpfest_userId = getCookie(`wpfest_user_id${festUrl}`)
    const display_name = getCookie(`wpfest_display_name${festUrl}`)
    const roles = wpfest_roles ? JSON.parse(wpfest_roles) : []
    const rt = `${preroute}/${route}` === "/" || `${preroute}/${route}` === "//"
      ?
      ""
      :
      `${preroute}/${route}` 
    //console.log( rt )
    return (
      <div className="layout-state p-0 m-0"> 
      <Switch>
      {
        festID
          ?
          <Route
            path={rt}
          >
            <Fest
              festID={ festID } 
              route={`${rt}`} 
              preroute={preroute}
            /> 
          </Route>
          :
          <>
            <Route
              exact
              path={rt}
            >
              <MenuFest 
                route={rt}
                user={{ display_name, roles, id: wpfest_userId }}
              />
            </Route>
            <Route
              path={`${rt}/:festid`}
            >
              <Fest
                festID={ this.props.match.params.id } 
                route={`${rt}/${this.props.match.params.id}`} 
                preroute={preroute}
              />                    
            </Route>
          </>
      }
      </Switch>
          {/* <Route
            exact
            path={rt}
          >
            <MenuFest 
              route={rt}
              user={{ display_name, roles, id: wpfest_userId }}
            />
          </Route>
          <Route
            path={`${rt}/:festid`}
          >
            <Fest
              festID={ this.props.match.params.id } 
              route={`${rt}/${this.props.match.params.id}`} 
              preroute={preroute}
            />                    
          </Route>
          <Route
            exact
            path={`${rt}/:festid`}
          >
          {
            // !WPFestSettings.enabled_rules
            //   ?
            //   <FestMembers
            //     blog_id={festBlogId}
            //     url={festUrl}
            //     route={`${rt}`}
            //     user={{ display_name, roles, id: wpfest_userId }}
            //   />
            //   :
              <FestAbout
                blog_id={festBlogId}
                url={festUrl}
                route={`${rt}`}
                user={{ display_name, roles, id: wpfest_userId }}
              />
          }            
          </Route>
          <Route
            strict
            path={`${rt}/:festid/member/:memberId`}
          >
            <FestMember
              blog_id={festBlogId}
              isLeftClosed={this.state.isLeftClosed}
              onLeftSwitch={this.onLeftSwitch}
              url={festUrl}
              preroute={preroute}
              route={`${rt}`}
              user={{ display_name, roles, id: wpfest_userId }}
            />
          </Route>
          <Route
            exact
            path={`${rt}/:festid/results`}
          >
            <FestResults
              blog_id={festBlogId}
              url={festUrl}
              route={`${rt}`}
              user={{ display_name, roles, festid: wpfest_userId }}
            />
          </Route>
          <Route
            exact
            path={`${rt}/:festid/insert-project`}
          >
            <FestNewProjectForm
              blog_id={festBlogId}
              url={festUrl}
              route={`${rt}`}
              user={{ display_name, roles, id: wpfest_userId }}
            />
          </Route>
          <Route
            exact
            path={`${rt}/:festid/insert-honecombs`}
          >
            <FestNewHoneycombForm
              blog_id={festBlogId}
              url={festUrl}
              route={`${rt}`}
              user={{ display_name, roles, id: wpfest_userId }}
            />
          </Route>
          <Route
            exact
            path={`${rt}/:festid/register-project`}
          >
            <FestRegisterNewProject
              blog_id={festBlogId}
              url={festUrl}
              route={`${rt}`}
              user={{ display_name, roles, id: wpfest_userId }}
            />
          </Route>
          <Route
            exact
            path={`${rt}/:festid/admin-request-roles`}
          >
            <FestRequests
              blog_id={festBlogId}
              url={festUrl}
              route={`${rt}`}
              user={{ display_name, roles, id: wpfest_userId }}
              isNew
            />
          </Route>
          <Route
            exact
            path={`${rt}/:festid/track-moderator`}
          >
            <FestTrackModerator
              blog_id={festBlogId}
              url={festUrl}
              route={`${rt}`}
              user={{ display_name, roles, id: wpfest_userId }}
              isNew
            />
          </Route>
          <Route
            exact
            path={`${rt}/:festid/register`}
          >
            <FestRegister
              blog_id={festBlogId}
              url={festUrl}
              route={`${rt}`}
              user={{ display_name, roles, id: wpfest_userId }}
            />
          </Route>
          <Route
            exact
            path={`${rt}/:festid/register/:role`}
          >
            <FestRegisterRole
              blog_id={festBlogId}
              url={festUrl}
              route={`${rt}`}
              user={{ display_name, roles, id: wpfest_userId }}
            />
          </Route>
          <Route
            exact
            path={`${rt}/:festid/members`}
          >
            <FestMembers
              blog_id={festBlogId}
              url={festUrl}
              route={`${rt}`}
              user={{ display_name, roles, id: wpfest_userId }}
            />
          </Route>
          <Route
            strict
            path={`${rt}/:festid/reglament`}
          >
            <FestReglament
              blog_id={festBlogId}
              isLeftClosed={this.state.isLeftClosed}
              onLeftSwitch={this.onLeftSwitch}
              url={festUrl}
              preroute={preroute}
              route={`${rt}`}
              user={{ display_name, roles, id: wpfest_userId }}
            />
          </Route>  */}
      </div>
    )
  }

	onLeftSwitch = (isLeftClosed) => {
	  this.setState({ isLeftClosed })
	}
}
export default withRouter(TutorWPFest)

