import React, {Component, Fragment} from "react"; 
import { 
	Icon, Tag, 
	Intent, Tooltip, 
	Card, FormGroup, 
	Button, ButtonGroup,
	Position, Popover, 
	InputGroup, 
	NumericInput
} from "@blueprintjs/core";
import { __ } from "react-pe-utilities/dist";
import { ColorPicker } from "react-pe-useful/dist";
import FieldInput from "react-pe-scalars/dist";
import CATEGORY_RATING_TYPE from "modules/pe-wpfest-admin-module/utilities/CategoryRatingType";
 
export default class CategoryForm extends Component
{
	state = {
		post_title 	: this.props.post_title,
		color 		: this.props.color,
		order 		: this.props.order,
		rating_type	: this.props.rating_type,
		
	}
	title = evt =>
	{
		const txt = evt.currentTarget.value;
		this.props.onChange("post_title", txt, this.props.ID);
		this.setState({post_title : txt});
	}
	onTitle = title =>
	{ 
		this.props.onChange("post_title", title, this.props.ID);
		this.setState({post_title : title});
	}
	color = color =>
	{
		this.props.onChange("color", color, this.props.ID);
		this.setState({color : color.hex});
	}
	onColor = color =>
	{
		this.props.onChange("color", color, this.props.ID);
		this.setState({color : color});
	}
	onOrder = order =>
	{
		this.setState({ order });
		this.props.onChange("order", order, this.props.ID);
	}
	onRatingType = rating_type =>
	{
		this.setState({ rating_type });
		this.props.onChange("rating_type", rating_type, this.props.ID);
	}
	onSave = () =>
	{
		this.props.onSave( this.state, this.props.ID );
	}
	onDelete =() =>
	{
		this.props.onDelete( this.props.ID );
	}
	render()
	{		
		const del_btn = this.props.isNew ? null : <Popover
					position={Position.TOP_LEFT}
					content={
						<div className="square p-3">
							<div>
								<p>
									{__("Are you realy want delete?")}
								</p>
								<ButtonGroup className="p-2 tex-right">
									<Button
										intent={Intent.DANGER}
										text={__("Yes")}
										icon="cross"
										onClick={this.onDelete}
									/>
								</ButtonGroup>
							</div>
							
						</div>						
					}
				>
					<Button
						text={__("Delete")}
						icon="cross"
					/>
				</Popover>
				
		return <Fragment> 
			<FieldInput
				vertical={false}
				title={__("Title")}
				commentary={__("insert title by any symbols")}
				type="string" 
				value={this.state.post_title}
				onChange={this.onTitle}
			/>
			<FieldInput
				vertical={false}
				title={__("Color")}
				commentary={__("Choose color")}
				type="color" 
				value={this.state.color}
				onChange={this.onColor}
			/>
			<FieldInput
				vertical={false}
				title={__("Order")}
				commentary={__("Position this Category in list")}
				type="number" 
				value={this.state.order}
				onChange={this.onOrder}
			/>
			{
				this.props.options.rating_type === "each_category"
					?
					<> 
						<FieldInput
							vertical={false}
							title={__("Rating type")}
							commentary={__("Be sure to select the type of evaluation of the criteria for this category. Default - independent grade")}
							type="radio"
							values={ CATEGORY_RATING_TYPE() }
							value={this.state.rating_type}
							onChange={this.onRatingType}
						/>
					</>
					:
					null
			}
			<ButtonGroup className="p-2 tex-right">
				<Button
					text={__("Save")}
					icon="floppy-disk"
					onClick={this.onSave}
				/>
				{del_btn}
				<Button
					icon="undo"
					onClick={this.props.onClose}
				>
					{__("Close")}
				</Button>
			</ButtonGroup>
		</Fragment>
	}
}