import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { __ } from 'react-pe-utilities/dist'
import FormType from './FormType'
import  Style from 'style-it'

const FieldForm = props => { 
    //const [values, setValues] = useState( Array.isArray( props.value ) ? props.value : [ ] )
    const onValue = (value, field, i ) =>
    {
        //console.log( value, field, props.n, i )
        
        props.onChange( value, field, props.n , i)
    } 
    return <div className={`row data-list ${ props.require ? "text-danger" : "" } `}>
        <div className={
            `col-md-4 col-12 text-md-right text-center d-flex flex-column justify-content-center align-items-end`
        }>
            <div className='d-flex justify-content-between align-items-center w-100'>
                <div className='require-sign text-danger mr-4 h-100'>
                    {
                        props.require 
                            ? 
                            <div className='d-flex user-select-none descr text-danger'>
                                {__("Required field")}
                            </div> 
                            : 
                            null
                    }
                </div> 
                <div>
                    <div className='title'> 
                        { __( props.name ) }
                    </div>
                    <div className='descr'>
                        { __( props.description ) }
                    </div> 
                </div>
            </div>
                
                      
        </div>
        <div className='col-md-8 col-12 px-0 '>
        {
            props.yandex_form_field?.length > 0
                ?
                props.yandex_form_field?.map( (field, i) => 
                { 
                    const values = Array.isArray( props.value ) ? props.value : [ ]
                    return <Fragment key={ field + "_" + i }>
                    {
                        Style.it(
                            `
                            .row.dat,
                            .strob01
                            {
                                width:100%;
                            }
                            .layout-data-vert
                            {
                                max-width: 100%;
                                padding-right: 0;
                            }
                            .numm
                            {
                                width:20px;
                            }
                            .bp3-card
                            {
                                background-color:transparent!important;;
                                box-shadow:none!important;
                            } 
                            `,
                            <div className='d-flex align-items-center w-100' >
                                {
                                    props.yandex_form_field?.length > 1
                                        ? 
                                        <span className="numm">{i + 1}.</span>
                                        :
                                        <span className="numm"> </span>
                                }  
                                <FormType
                                    type={ props.type }
                                    values={ props.values }
                                    value={ values[i] }
                                    field={field}
                                    i={i}
                                    onChange={ (value ) =>  onValue(value, field, i) }
                                /> 
                            </div>
                        )
                    }
                    </Fragment>
                })                            
                :
                null
        }  
        </div>
    </div>
    
}

FieldForm.propTypes = {

}

export default FieldForm