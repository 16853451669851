import React, {Fragment,Component} from "react"; 
import {   
	Intent,  
	Card,  
	Button,  
	Slider,
	Dialog,
	FormGroup
} from "@blueprintjs/core";
import _fetch from "modules/pe-wpfest-admin-module/api";
import { __ } from "react-pe-utilities";
import { MediaChooser } from "react-pe-useful";
import CabinetView, { WPFEST_ADMIN_MY_ROUTE, WPFEST_ADMIN_STATUSES } from "../../CabinetView";
import { withRouter } from "react-router"
import { compose } from "recompose" 
import FieldInput from "react-pe-scalars/dist";
import MyEditor from "modules/pe-wpfest-admin-module/utilities/Editor"
import IndexDB from "modules/pe-admin-module/views/utilities/IndexDB"

class AboutPageDesign extends Component
{
	constructor(props)
	{
		super(props);
		// console.log(props)	
		this.state = 
		{
			status : this.props.data.status, 
			reglament_content : this.props.data.reglament_content || "", 
			max_raiting : 0, 
			fmru_evlbl_roles :[],
			all_roles :[],
			is_experts_criteries : 0,
			is_independent_grade_criteries : 0,
			is_register : 0,
			is_ganres : 0,
			is_diaries : 0,
			member_card : "box",
			member_id : 1,
			card_count : 10,
			icon:"",
			icon_id:"",
			start_date:null,
			finish_date:null,
			default_image:"",
			default_image_id:"",
			default_member_thrumb:"",
			default_member_thrumb_id:"",
			isOnline:true
		}
	}
	componentWillMount() 
	{
		console.log(this.props.blog_id);
		_fetch("site", {id:this.props.blog_id})
			.then(data =>
			{
				console.log( data );
				if(!data.isError)
				{			
					if( data.site )	
					{
						this.setState({
							reglament_content : data.reglament_content || "",
							status : data.site.options.status,
							icon : data.site.icon,
							icon_id : data.site.icon_id,
							start_date : data.site.options.start_date,
							finish_date : data.site.options.finish_date,
							default_image : data.site.default_image,
							default_image_id : data.site.default_image_id,
							default_member_thrumb : data.site.default_member_thrumb,
							default_member_thrumb_id : data.site.default_member_thrumb_id,
							all_roles : data.site.roles, 
							member_card : data.site.options.member_card,
							member_id : data.site.options.member_id,
							rating_type : data.site.options.rating_type,
							display_project_result : data.site.options.display_project_result,
							fmru_evlbl_roles : data.site.options.fmru_evlbl_roles,
							max_raiting : data.site.options.max_raiting ? parseInt(data.site.options.max_raiting) : 3,
							is_owner_rate : data.site.options.is_owner_rate == "1",
							is_experts_criteries : data.site.options.is_experts_criteries == "1",
							is_independent_grade_criteries : data.site.options.is_independent_grade_criteries == "1",
							is_register : data.site.options.is_register == "1",
							is_ganres : data.site.options.is_ganres == "1",
							is_diaries : data.site.options.is_diaries == "1",
							member_card_height : data.site.options.member_card_height,
							enabled_rules : data.site.options.enabled_rules == "1",
							card_count : data.site.options.member_per_page ? parseInt(data.site.options.member_per_page): 100
							
						});
					}	
					
					IndexDB.find(
						"peFestAdmin_mySites",
						"sites",
						"sites",
						this.props.blog_id
					)
						.then(res => { 
							IndexDB.save(
								{...res.data, ...data.site},
								"sites",
								this.props.blog_id,
								"sites",
								"peFestAdmin_mySites",
								1
							)
						})
				} 
				else
				{
					IndexDB.find(
						"peFestAdmin_mySites",
						"sites",
						"sites",
						this.props.blog_id
					)
						.then(res => {
							if(res.data)
							{				
								this.setState({
									status : res.data.options.status,
									reglament_content : res.data.reglament_content || "",
									icon : res.data.icon,
									icon_id : res.data.icon_id,
									start_date : res.data.start_date,
									finish_date : res.data.finish_date,
									default_image : res.data.default_image,
									default_image_id : res.data.default_image_id,
									default_member_thrumb : res.data.default_member_thrumb,
									default_member_thrumb_id : res.data.default_member_thrumb_id,
									all_roles : res.data.roles, 
									member_card : res.data.options.member_card,
									member_id : res.data.options.member_id,
									rating_type : res.data.options.rating_type,
									display_project_result : res.data.options.display_project_result,
									fmru_evlbl_roles : res.data.options.fmru_evlbl_roles,
									max_raiting : res.data.options.max_raiting ? parseInt(res.data.options.max_raiting) : 3,
									is_experts_criteries : res.data.options.is_experts_criteries == "1",
									is_independent_grade_criteries : res.data.options.is_independent_grade_criteries == "1",
									is_register : res.data.options.is_register == "1",
									is_ganres : res.data.options.is_ganres == "1",
									is_diaries : res.data.options.is_diaries == "1",
									member_card_height : res.data.options.member_card_height,
									enabled_rules : res.data.options.enabled_rules == "1",
									card_count : res.data.options.member_per_page ? parseInt(res.data.options.member_per_page): 100,
									isOnline: false
								});

							}
						})
				}
			});
	}
	onReglamentEdit = text =>
	{
		this.setState({reglament_content : text });
		//this.onUpdate()
	}
	render()
	{
		//console.log( this.state ); 
		return <div className='row'>
			<div className='col-12 '> 
				<Card fill={"true"} interactive={true} className="p-4 mb-2">
					<div className="lead mb-1">{__("Page of Festival rules")}</div>
					 <input
						type="checkbox"
						className="_checkbox"
						checked={ this.state.enabled_rules || false }
						onChange={ this.onIsEnabledRules }
						value="1"
						id="enabled_rules"
					/>
					<label htmlFor="enabled_rules"></label>
					<small className="text-secondary" style={{ position:"absolute", marginTop:10 }}>
						{__("Content of this page put to «About» link.")}
					</small>
				</Card>
				{
					!this.state.enabled_rules
						?
						null
						:
						<>
							<Card fill={"true"} className="p-4 mb-2">
								<div className="lead mb-1">{__("Default image")}</div>
								<div className="row">
									<div className=" col-12">
										<MediaChooser
											url={this.state.default_image}
											prefix={"icon"}
											height={230}
											width={"100%"}
											padding={5}
											onChange={this.onDefaultImage}
											id={this.state.default_image_id}
											isURLHide={true}
											isDescr={false}
											hideLib
										/>
										<div className="small mt-1">{
											__("This is the header image for the home page.")}
										</div>
									</div> 
								</div>
							</Card>
							<FormGroup
								label={__("Content of reglament page")}
								helperText={__("Set reglament content")}
								className="p-2"
							>
								<MyEditor
									text={this.state.reglament_content?.replace(
										/(<[^>]+\s+)(?:style\s*=\s*"(?!(?:|[^"]*[;\s])color\s*:[^";]*)(?!(?:|[^"]*[;\s])background-color\s*:[^";]*)[^"]*"|(style\s*=\s*")(?=(?:|[^"]*[;\s])(color\s*:[^";]*))?(?=(?:|[^"]*)(;))?(?=(?:|[^"]*[;\s])(background-color\s*:[^";]*))?[^"]*("))/gi,
										function (match, $1, $2, $3, $4, $5, $6, offset, string) {
											return $1 + ($2 ? $2       : '') + ($3 ? $3 + ';' : '')
													+ ($5 ? $5 + ';' : '') + ($2 ? $6       : '');
										}
									)}
									onChange={this.onReglamentEdit}
								/>
							</FormGroup>
						</>
				}
					
			</div>
		</div>;
	}
	onUpdate = (param, value) =>
	{
		// console.log(param, value);
		_fetch("updte_site", {param : param, value : value, blog_id:this.props.blog_id})
			.then(data =>
			{
				console.log( data );
				let state = Object.assign({}, this.state);
				state[param] = value;				
				this.setState(state);
			});
	}
	onDefaultImage = ( url, file, ID ) =>
	{
		console.log( url, file, ID );
		this.onUpdate( "default_image", { url: url, name: file.name || "festival_icon.jpg" } );
		this.setState({default_image: url});
	}
	onIsEnabledRules = evt =>
	{
		const val = evt.currentTarget.checked ? 1 : 0;
		this.onUpdate("enabled_rules", val);
		this.setState({enabled_rules : val});
	}
}

export default  compose(
	withRouter, 
)(AboutPageDesign)
