import React, { Component } from "react" 
import _fetch from "."
import { __ } from "react-pe-utilities" 
import FestMemberSetting from "./FestMemberSetting"
import { Callout, Intent } from "@blueprintjs/core"
import actions, { UPDATE_MEMBER_ACTION } from "modules/pe-fest-module/data/actions"

const MemberAdmin = props =>
{ 
    const { is_owner, is_member } = props
    const on = (data) =>
    {
      let member = {}
      member[data.field] = data.value
      console.log( member )
      actions(UPDATE_MEMBER_ACTION, {id: data.id, member})
    }
    return is_member
      ?
      <FestMemberSetting 
        {...props}
        user={props.user}
        is_owner={is_owner}
        is_member={is_member} 
        on={on}
      />
      : 
      <Callout intent={Intent.DANGER} className="p-4">
        {__("You are not member project")}
      </Callout> 
}
export default MemberAdmin
