import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import honeycombs from "../../assets/img/honeycombs.svg"
import { __ } from "react-pe-utilities"
import { Button, ButtonGroup, Collapse, Popover, Position } from '@blueprintjs/core' 
import User from 'modules/pe-fest-module/views/wpfestREST/utilities/User'
import { NavLink } from 'react-router-dom'
import actions, { SET_HONEYCOMDS_FILTER_ACTION } from 'modules/pe-fest-module/data/actions'
import { useFestStore } from 'modules/pe-fest-module/data/store'


const HoneycombsForm = props => { 
    let timeOut = -1
    const filters = useFestStore(state => state.memberFilters)
    const [title, setTitle] = useState(__("all Honeycombs"))
    const [isOpen, setOpen] = useState(false)
    const [isCollapse, setCollapse] = useState(false)
    const onOpen = boo =>
    {
        setOpen(boo)
    }
    const handleLoginOpen = () =>
    {
        clearTimeout( timeOut )
        onOpen(true)
    }
    const closeLoginOpen = () =>
    {
        clearTimeout( timeOut )
        onOpen(false)
    }
    useEffect(() => {
        setTitle(__(filters.honeycombs[0] == -1 ? "all Honeycombs" : title))
    }, [filters])
    useEffect(() =>
    {
        window.document.body.addEventListener("click", closeLoginOpen)
        return () => {
            window.document.body.addEventListener("click", closeLoginOpen)
            clearTimeout( timeOut )
        }
    }, [])
    const selectHoneycombs = id =>
    { 
        actions( SET_HONEYCOMDS_FILTER_ACTION, { honeycombs: [id] } )
        //console.log( id )
        if(id === -1)
        {
            setTitle( __("all Honeycombs") )
        }
        else if( id === "mine")
        {
            setTitle( __("Only mine Honeycombs") )
        }
        else
        {
            setTitle( props.honeycombs?.filter(h => h.ID === id)[0]?.post_title )
        }
        clearTimeout( timeOut )
        onOpen(false)
    } 
    const onMouseLeave = evt =>
    {
        clearTimeout( timeOut )
        timeOut = setTimeout(() =>
        {
            onOpen(false)
        }, 1000)
    } 
    if(!Array.isArray(props.honeycombs) || props.honeycombs.length === 0 ) return null
    return <>   
        <div 
            className="indicator lrg classic"             
            onMouseLeave={onMouseLeave}
        >
            <div className="n1" onClick={handleLoginOpen} >
                { title }
            </div>
            <div className="iconnn" onClick={handleLoginOpen} >
                <img src={ honeycombs } alt="" />
            </div>
            <Popover
              className="indicator-popover"
              popoverClassName="p-0" 
              position={ Position.BOTTOM_RIGHT }
              isOpen={isOpen}
              content={(
                <ButtonGroup 
                    vertical 
                    fill 
                    minimal 
                    onMouseEnter={() =>  clearTimeout( timeOut ) }                    
                    style={{ minWidth: 200 }}
                > 
                    {
                        filters.honeycombs[0] !== -1 || filters.tracks[0] !== -1
                            ?
                            <Button className='p-3' onClick={() => selectHoneycombs( -1 ) }>
                                {__("all Honeycombs")}
                            </Button>
                            :
                            null
                    }   
                    {
                        User.roles
                            .filter( role => ["Tutor"].filter(r => r === role ).length > 0 )
                            .length > 0 
                            ?   
                            <Button className='p-3' onClick={() => selectHoneycombs( "mine" ) }>
                                {__("Only mine Honeycombs")}
                            </Button> 
                            :
                            null
                    }
                    
                    {
                        User.roles
                            .filter( role => ["administrator", "Track_moderator"].filter(r => r === role ).length > 0 )
                                .length > 0
                                ?                                 
                                <NavLink
                                    to={`${props.route}/insert-honecombs`}
                                    className="btn btn-light p-3"
                                >
                                    <span className='flex-grow-100 small '>{__("insert new Honeycomds")}</span>
                                </NavLink>
                                :
                                null
                    }
                    {
                      Array.isArray( props.honeycombs )
                        ?
                        <>
                            <Button 
                                icon="chevron-down" 
                                minimal
                                small
                                className={isCollapse ? "hidden" : " p-3 "} 
                                onClick={event => {
                                    setCollapse(true)
                                    onOpen(true)
                                    event.preventDefault()
                                    event.stopPropagation()
                                }}
                            />
                            <Collapse isOpen={isCollapse} >
                                <div className="d-flex flex-column">
                                {
                                    props.honeycombs.map(h =>
                                    {
                                        return <div className='btn btn-light p-0' key={h.ID} onClick={() => selectHoneycombs(h.ID) }>
                                            <div className='d-flex'>
                                                <span className='flex-grow-100 p-3 small '>{ h.post_title }</span>
                                                <span className ='p-3 border-left border-white bg-secondary-super-light small '>{ h.count }</span>
                                            </div>
                                        </div>
                                        
                                    })
                                }                                    
                                </div>
                            </Collapse>
                        </>
                       
                        :
                        <div className='p-3' >
                            {__("no Honeycombs")}
                        </div>  
                    }
                </ButtonGroup>
              )}
            >
                <div style={{ marginTop: 10 }} />
            </Popover> 
        </div> 
    </> 
}

HoneycombsForm.propTypes = {}

export default HoneycombsForm