import _fetch from "modules/pe-wpfest-admin-module/api";
import React, { useState, useEffect } from "react";
import { __ } from "react-pe-utilities/dist"; 
import Style from "style-it";
import ReactHtmlTableToExcel from "react-html-table-to-excel"; 
import IndexDB from "modules/pe-admin-module/views/utilities/IndexDB";

const ShortMembers = props =>
{
	const[members, setMembers] = useState([])
	const[url, setURL] = useState("")
	const[table, setTable] = useState("")
	useEffect(() =>
	{
		IndexDB.find( 
			"peFestAdmin_mySites",
			"sites",
			"sites",
			props.blog_id
		)
			.then( sites =>
			{ 
				if(sites.data.short_members_csv)
				{
					setMembers(doIt(sites.data.short_members_csv))	 
					setURL(sites.data.url)
					setTable(sites.data.short_members_table)
				} 
			})
		//console.log( props); 
		_fetch( "statistics_short_members_2", {id : props.blog_id} )
			.then(data => 
				{
					//console.log(data);
					if(!data.isError)
					{
						setMembers(doIt(data.csv))	 
						setURL(data.url)
						setTable(data.table)
						IndexDB.find( 
							"peFestAdmin_mySites",
							"sites",
							"sites",
							props.blog_id
						)
							.then(res =>
							{
								// console.log( { 
								// 	...res.data, 
								// 	short_members_csv: data.csv,
								// 	short_members_table: data.table
								// } )
								IndexDB.save(
									{
										...res.data, 
										short_members_csv: data.csv,
										short_members_table: data.table
									},
									"sites",
									props.blog_id,
									"sites",
									"peFestAdmin_mySites",
									1
								)
								IndexDB.save(
									{
										...res.data, 
										short_members_csv: data.csv,
										short_members_table: data.table
									},
									"sites",
									props.blog_id,
									"sites",
									"peFestAdmin_allSites",
									1
								)
							})
					}
				})
	}, []) 

	const doIt = data =>
	{
		let members = []
		data.forEach((row, i) => {
			const mid = Number(row.member_id)
			const rating = Number(row.raiting)
			if(!members[mid]) 
				members[mid] = {
					id: mid,
					title : row.member,
					categories: [], 
					descriptions : [
						row.description
					],
					experts: [ ],
					rating: []
				}
			members[mid].experts = Array.isArray(members[mid].experts)
				?
				members[mid].experts.filter( e => e !== row.expert_id).push(row.expert_id)
				:
				[row.expert_id]
			members[mid].rating.push( rating )
			const catergory_id = Number( row.catergory_id )
			if(!members[mid].categories[catergory_id])
			{
				members[mid].categories[catergory_id] = {
					id :	catergory_id,
					title: 	row.catergory_name,
					criteries : [],
					rating: []
				}
			}
			members[mid].categories[catergory_id].rating.push(rating)
			const critery_id =  Number( row.critery_id)
			if(!members[mid].categories[catergory_id].criteries[ critery_id ])
			{
				members[mid].categories[catergory_id].criteries[ critery_id ] = {
					id : critery_id,
					title : row.critery,
					rating : []
				}
			}
			members[mid].categories[catergory_id].criteries[ critery_id ].rating.push( rating )
			//console.log( { ...members[mid].categories[catergory_id] } )
		})
		
		members =  members.filter( e => typeof e !== "undefined")
			.map( member => {
				member.categories = member.categories.filter(e => typeof e !== "undefined")
					.map(catergory => {
						catergory.criteries = catergory.criteries.filter(critery => typeof critery !== "undefined")
						// console.log( catergory )
						return catergory
					})
				// console.log( member )
				return member
			}) 
		members = members.map(member => {
			const sum0 = member.rating.reduce((total, curentValue) =>
				{
					return total += curentValue
				}, 0)
			return {
				id		: member.id.toString(),
				label	: <div className="d-flex justify-content-between w-100 pr-4 ">
					<div className="overflow-hidden mr-4 text-overflow-ellipsis">
						{ member.title } 
					</div>
					<div>
						{ (sum0 / member.rating.length).toPrecision(3) } 
					</div>
				</div>,
				parentId: null,
				items	: member.categories.map(catergory => {
					//console.log( catergory )
					const sum = catergory.rating.reduce((total, curentValue) =>
					{
						return total += curentValue
					}, 0)
					const cat = {
						id: 		catergory.id.toString(),
						label:		<div className="d-flex justify-content-between w-100 pr-4 ">
							<div className="overflow-hidden mr-4 text-overflow-ellipsis">
								{ catergory.title } 
							</div>
							<div>
								{ (sum / catergory.rating.length).toPrecision(3) } 
							</div>
						</div>,
						parentId: 	member.id.toString(),
						items : 	catergory.criteries.map(critery => {
							const sum2 = critery.rating.reduce((total, curentValue) =>
							{
								return total += curentValue
							}, 0)
							return {
								id:		critery.id,
								label :	<div className="d-flex justify-content-between w-100 pr-4 ">
									<div className="overflow-hidden mr-4 text-overflow-ellipsis">
										{ critery.title  } 
									</div>
									<div>
										{ (sum2 / critery.rating.length).toPrecision(3) } 
									</div>
								</div>,
								parentId: 	catergory.id.toString(),
							} 
						})
					} 
					//console.log( cat )
					return cat
				})
			}

		}) 
		// members = members.map(member => {
		// 	let m = {...member}
		// 	member.items.forEach(category =>
		// 	{
		// 		category.items.forEach( critery => {
		// 			members.push( critery )
		// 		})
		// 	})
		// 	console.log( m )
		// 	return m
		// })
		return members
	}
	
	return Style.it(
		`
		.tIBnI
		{
			width: 100%;
		}
		`,
		<div className="p-4">
			
			<a className="btn btn-link " href={ url } target="_blank">{__("Download CSV")}</a>
			<ReactHtmlTableToExcel
				id="test-table-xls-button"
				className="btn btn-link ml-4"
				table="table-to-xls"
				filename="members_results"
				sheet="members_results"
				buttonText={__("Download as XLS")}
			/>
			<div className="w-100 py-4 px-0" dangerouslySetInnerHTML={{__html : table}} />
			{/*
			<Tree 
				nodes={ members }
				nodes2={[
					{
					  id: 'kBlV3HWBPijtYliPKWTl-',
					  label: 'Root 1',
					  parentId: null
					},
					{
					  id: 'uVTc_UFbwEqXUp4xMQ8Pi',
					  label: 'Root 2',
					  parentId: null
					},
					{
					  id: 'aVuq5UAfrKytkRD5xUU01',
					  label: 'Root 3',
					  parentId: null
					},
					{
					  id: 'EdvqPxKvmcYdpTTSXC987',
					  items: [
						{
						  id: 'Aj29Q_3Ap8VJeADhmwCCS',
						  label: 'File 1',
						  parentId: 'EdvqPxKvmcYdpTTSXC987'
						},
						{
						  id: 'elxvDl75mQT89mMmzZ3kh',
						  label: 'File 2',
						  parentId: 'EdvqPxKvmcYdpTTSXC987'
						},
						{
						  id: '8UrJ-RU0Qso_1uAZru3Qk',
						  label: 'File 3',
						  parentId: 'EdvqPxKvmcYdpTTSXC987'
						}
					  ],
					  label: 'Child 1',
					  parentId: 'kBlV3HWBPijtYliPKWTl-'
					},
					{
					  id: 'WDgwgPTqIzQZisGmkf9Rd',
					  label: 'Child 2',
					  parentId: 'uVTc_UFbwEqXUp4xMQ8Pi'
					},
					{
					  id: 'W5NqKKbMk5qlj7ARFhIwW',
					  label: 'Child 3',
					  parentId: 'uVTc_UFbwEqXUp4xMQ8Pi'
					},
					{
					  id: 'YYA8-D5eZTGUMcTe5j-4k',
					  label: 'Child 4',
					  parentId: 'aVuq5UAfrKytkRD5xUU01'
					},
					{
					  id: '2hX6QjakY1_gH2zfWQ3oo',
					  label: 'Child Child 1',
					  parentId: 'EdvqPxKvmcYdpTTSXC987',
					  items: [
						{
							id: 'elxvDl75mQT89meeezZ3kh',
							label: 'File File 2',
							parentId: '2hX6QjakY1_gH2zfWQ3oo'
						},
						{
							id: '8UrJ-RU0Qso_1ueeru3Qk',
							label: 'File File 3',
							parentId: '2hX6QjakY1_gH2zfWQ3oo'
						}	
					  ]					
					},
					{
					  id: 'yWatuUc_V_q4qW8nCRqlr',
					  items: [
						{
						  id: 'q42JInLIbMzwIwyhUzidO',
						  label: 'File 3',
						  parentId: 'yWatuUc_V_q4qW8nCRqlr'
						},
						{
						  id: 'gs-CJIQLSHkgMOwUiWYYm',
						  label: 'File 4',
						  parentId: 'yWatuUc_V_q4qW8nCRqlr'
						},
						{
						  id: 'MJeyf7Zr1GXNxqTcR_hdY',
						  label: 'File 5',
						  parentId: 'yWatuUc_V_q4qW8nCRqlr'
						}
					  ],
					  label: 'Child Child 2',
					  parentId: 'WDgwgPTqIzQZisGmkf9Rd'
					},
					{
					  id: 'gdq_4TIZbZlDiVkEzssVH',
					  label: 'Child Child 3',
					  parentId: 'W5NqKKbMk5qlj7ARFhIwW'
					}
				]}
				animations={true}
				grow
				theme="light"
				size="full"
			/>
			<pre className="my-4 hidden" dangerouslySetInnerHTML={{__html: JSON.stringify( members, null, 2)}}/> 
			*/ }
		</div>
	)
}
export default ShortMembers