
import React, {Fragment,Component} from "react"; 
import CategoryForm from "./single/CategoryForm"; 
import { 
	Icon, Tag, 
	Intent, Tooltip, 
	Card, FormGroup, 
	Button, ButtonGroup,
	Position, Popover, 
	InputGroup, 
	Collapse,
	Pre,
	Dialog,
	Callout
 } from "@blueprintjs/core";
import _fetch from "modules/pe-wpfest-admin-module/api";
import { sprintf, __ } from "react-pe-utilities/dist";
import { Loading } from "react-pe-useful/dist";
import { getCookie, setCookie } from "modules/pe-wpfest-admin-module/utilities/utils";
import { post } from "jquery"; 
import CATEGORY_RATING_TYPE from "modules/pe-wpfest-admin-module/utilities/CategoryRatingType";

export default class Categories extends Component
{
	type = "category"
	selectedList = []
	constructor(props)
	{
		super(props);		
		this.state = { 
			current:-1, 
			loading: false,
			isNew:false, 
			post: this.props.data.post || [], 
			theadColor: this.props.theadColor || "#2d74ab",
			bulks : this.selectedList,
			mainBulk: false,
			isSaveSelected: this.selectedList.length > 0
		};
		this.init({
			form_component	: CategoryForm,		
			meta			: {color: "#FFF"}
		})		
	}
	componentDidMount()
	{
		let sel = getCookie(`pefest-admin-selected-${this.type}-${this.props.blog_id}`)
		this.selectedList = sel ? sel.split(",").map( e => Number(e) ) : []
		setTimeout(() =>
		{
			this.setState({
				isSaveSelected: this.selectedList.length > 0,
				bulks : this.selectedList,
				loading : true,
				isCollapseOpen: this.selectedList.length > 0
			})
		}, 100)
		this.componentMount()
	}
	componentMount()
	{
		
	}
	saveSelected = () =>
	{
		// console.log(this.state.bulks.length > 0)
		if(!this.state.isSaveSelected)
		{
			this.selectedList = this.state.bulks
			setCookie(`pefest-admin-selected-${this.type}-${this.props.blog_id}`, this.state.bulks.join(","))	
			this.setState({
				isSaveSelected : true
			})
		}
		else
		{
			setCookie(`pefest-admin-selected-${this.type}-${this.props.blog_id}`, "")
			this.setState({
				isSaveSelected : false,
				mainBulk: false,
				bulks : []
			})	
		}
	}
	render()
	{ 
		if( !this.state.loading ) return <Loading />

		const Form_component = this.form_component;			
		const thead = this.state.isNew
			? 
			<tr>
				<td colSpan={12} style={{ backgroundColor : this.state.theadColor }}>
					<Card fill={"true"} className="p-0 w-100 " interactive={true} >
						<div className="p-4 dark-bordered">
							<Form_component
								ID={-1}
								options={this.props.options}
								post_title={""}
								post_content={""}
								thumbnail={ 'thumbnail' }
								meta={ this.meta }
								color={"#FFFFFF"}
								order={ Array.isArray(post) ? post.length+1 : 1 }
								onChange={this.onChange}
								onSave={this.onSave}
								onDelete={this.onDelete}
								onClose={this.onClose}
								isNew={true}
							/>
						</div>
					</Card>
				</td>
			</tr> 
			: 
			null;
		const projects = this.state.post.length === 0 
			?
			<tr>
				<td colSpan={12}>
					<Callout className="p-5 text-center">
						{
							__("No elements exists")
						}
					</Callout>
				</td>	
			</tr>
			:
			this.state.post.map(elem => 
			{		
				//console.log( elem );
				const style = { 
					//backgroundColor : elem.id==this.state.current ? "#8A9BA833": "transparent",
					bodrerBottom: "5px solid #111",
					padding: 0
				};	
				let metas = {};
				for(var i in this.meta)
				{ 
					// console.log(i, elem[i], this.meta[i])
					// metas[i] = elem[i] ? elem[i] : this.meta[i];
					metas[i] = this.meta[i];
				}			
				const editForm = elem.id === this.state.current 
					? 
					<>				
						<tr className="opened">
						{
							this.rows( ).map( (e, eindex) => 
							{
								return <td className=" mr-2" key={e[0] + "" + eindex}>
									{this.onDefRow(e[0], elem, true)}
								</td> 
							})
						}
						</tr>
						<tr>
							<td colSpan={12} style={style}>
								<div className="bg-light open-bordered">
									<div className="p-4 ">
										<Form_component 
											ID={elem.id}
											{...elem}
											options={this.props.options}
											// catergories={elem.catergories}
											// fmru_ganre={elem.fmru_ganre}
											// fmru_honeycombs={elem.fmru_honeycombs}
											// fmru_track={elem.fmru_track}
											// form_fields={elem.form_fields}
											// post_title={elem.post_title}
											// post_content={elem.post_content}
											// thumbnail={elem.thumbnail}
											// user_login={elem.user_login}
											// leader_id={elem.leader_id}
											// tutor_id={elem.tutor_id}
											// member_0={elem.member_0}
											// member_1={elem.member_1}
											// member_2={elem.member_2}
											// member_3={elem.member_3}
											// member_4={elem.member_4}
											// member_5={elem.member_5}
											// member_6={elem.member_6}
											// member_7={elem.member_7}
											// member_8={elem.member_8}
											// member_9={elem.member_9}
											meta={metas}
											color={elem['color']}
											order={elem.order}
											rating_type={elem.rating_type}
											onChange={this.onChange}
											onSave={this.onSave}
											onDelete={this.onDelete}
											onClose={this.onClose}
											onForceDelete={this.onForceDelete}
											onBan={this.onBan}
										/>
									</div>
									
								</div>
							</td>
						</tr> 
					</>
					
					:
					<tr>
					{
						this.rows().map( e => {
							return <td key={ e[0] + elem.id }>
								{this.onDefRow(e[0], elem)}
							</td> 
						})
					}
					</tr>
				return <Fragment key={elem.id}>				
					{editForm}				
				</Fragment>
			})
		return <>
		 	<Collapse isOpen={this.state.isCollapseOpen} transitionDuration={ 4000 }>
				<div className="py-1 d-flex align-items-center flex-wrap ">
					<span className="p-2">
						{__("Bulk actions:")}
					</span>
					<Button 
						large
						minimal
						intent={Intent.DANGER}
						onClick={this.onDeleteCollectionOpen}
					>
						{__("Delete selected")}
					</Button>
					<Button 
						large
						minimal={!this.state.isSaveSelected}
						intent={Intent.SUCCESS}
						onClick={this.saveSelected}
					>
						{__("Save selection")}
					</Button>
					{
						this.getBulkActionButtons()
					}
				</div>
			</Collapse>
			<table className="table table-striped table-hover2 mb-5">
				<thead style={{backgroundColor : this.state.theadColor , color : "#EEE"}}>
					<tr className="">
					{
						this.rows().map( elem => {
							const col = elem[0] + Math.random() 
							return <th 
								col={ elem[0] } 
								key={ col } 
								width={elem[2]}
								className={elem[3]}
								style={{maxWidth:elem[2]}}
							>
								{ elem[1] }
							</th> 
						})
					}
					</tr>
					{thead}
					{this.addThead()}
				</thead>
				<tbody>
					{projects}
				</tbody>
			</table>
			<Dialog
				isOpen={this.state.isDeleteCollectionOpen}
				title={__("Delete collection")} 
				className="little"
				onClose={this.onDeleteCollectionOpen}
			>
				<div className="p-5">
					<div className="lead">
						{sprintf(__("Realy delete %s objects?"), this.state.bulks.filter( e => !isNaN(e) && e !== false ).length)}
					</div>
					<div className="d-flex align-items-center justify-content-end">
						<ButtonGroup >
							<Button onClick={this.onDeleteCollection}>
								{__("Yes")}
							</Button>
							<Button intent={Intent.DANGER} onClick={this.onDeleteCollectionOpen} icon="cross" />
						</ButtonGroup>
					</div>
				</div>
			</Dialog>
		</>
	}
	onEditForm = (evt, isOpen) =>
	{
		const _tid = parseInt(evt.currentTarget.getAttribute("tid"))
		const tid = !isOpen ? _tid : -1;
		// console.log( tid )
		this.setState({ current: tid, isNew: false })
	}
	onNew = () =>
	{
		this.setState({ current : -1, isNew : !this.state.isNew })
	}
	onClose=()=>
	{
		this.setState({ current : -1, isNew : false })
	}
	onChange=(field, value, id) =>
	{
		
	}
	onForceDelete = user_id =>
	{
		console.log("ForceDelete", user_id)
	} 
	onSave = (state, id) =>
	{
		console.log("save", state, id );
		_fetch( id > 0 ? "update_object" : "insert_object", { 
			format 	: this.props.format, 
			termin 	: this.props.termin, 
			data	: state,
			id		: id,
			blog_id	: this.props.blog_id
		})
			.then(data =>
			{
				//console.log(data); 
				if(data.post)	
					this.setState({ isNew:false, post:data.post })
			})
	}
	onDelete = id =>
	{
		_fetch("delete_object", { 
			format 	: this.props.format, 
			termin 	: this.props.termin, 
			id		: id,
			blog_id	: this.props.blog_id
		})
			.then(data =>
			{
				//console.log(data); 
				this.setState({ current:-1, isNew:false, post:this.state.post.filter(elem => elem.id != id) })
			})
	}
	
	/*
	//	override methods
	*/
	
	
	init(data)
	{
		this.form_component 	= data.form_component;
		this.meta		 		= data.meta;
		this.theadColor			= data.theadColor ||"#2d74ab";
		//this.setState({ theadColor: this.theadColor	});
	}
	getBulkActionButtons = () =>
	{
		return <>
			<Button large minimal>

			</Button>
		</>
	}
	onBulk = (evt, isMain, elem) =>
	{
		let bulks = []
		let isCollapseOpen
		let mainBulk
		const checked = evt.currentTarget.checked
		// console.log( checked, isMain, elem )
		if(isMain)
		{
			if(checked)
			{
				bulks = this.state.post.map(e => e.id)
			}
			else
			{
				bulks = []
			}
			this.setState({ 
				isCollapseOpen : checked,
				mainBulk : checked,
				isSaveSelected: false,
				bulks
			})
		}
		else
		{
			if(checked)
			{
				bulks = this.state.post.map((e, i) => e.id === elem.id 
					? 
					elem.id 
					:
					typeof this.state.bulks[i] === "undefined"
						?
						false
						:
						this.state.bulks[i]
				)
				isCollapseOpen = true
				mainBulk =  bulks.filter(e => !e).length == 0
			}
			else
			{
				bulks = this.state.post.map((e, i) => e.id === elem.id ? false : this.state.bulks[i] )
				isCollapseOpen = bulks.filter(e => e).length > 0
				mainBulk = bulks.filter(e => !!e).length == 0
				
			}
			this.setState({ 
				isCollapseOpen,
				mainBulk,
				isSaveSelected: false,
				bulks
			})
		}
	}
	bulk = (isMain, elem) =>
	{
		return <label className="_check_blue_ m-1" >
			<input 
				type="checkbox" 
				onChange={ evt => this.onBulk( evt, isMain, elem )}
				value="1"
				checked={ isMain ? this.state.mainBulk : this.state.bulks.filter (e => e === elem.id ).length > 0 }
			/>
		</label>
	}
	rows( )
	{
		let rows = [
			['bulk',	this.bulk( true, { id: 0 } ) , 50 ], 
			['color',	<Tooltip intent={Intent.DANGER}  position={Position.TOP} content={__("Color")}><Icon icon="tint" key={2}/></Tooltip>, 100], 
			[
				'order',	
				<div style={{minWidth:50, display:"flex", justifyContent:"center"}} key={"order"}>
					<Tooltip intent={Intent.DANGER}  position={Position.TOP} content={__("Order")}><Icon icon="sort-asc" key={3}/></Tooltip>
				</div>, 
				50
			], 
			['title',	<div style={{width: 300 }} key={"title"}>{__("Title")}</div>, 290, "flex-grow-100 px-2" ],
			['edit',	
				[
					!this.state.isNew 
						? 
						<div style={{ width:100}} key={"is_new"}>
							<div className="btn btn-link text-light btn-sm" onClick={this.onNew}> 
								<Tooltip 
									intent={Intent.DANGER} 
									position={Position.TOP} 
									content={__("Add new")}  
								>
									<Icon icon="plus" /> 
								</Tooltip>
							</div>
						</div>
						:						
						<div style={{ width:100}} key={"edit"}>
							<Tooltip 
								intent={Intent.DANGER} 
								content={__("Cancel")} 
								position={Position.TOP} 
							>
								<div className="btn btn-link text-light btn-sm" onClick={this.onNew}> 
									<Icon icon="undo" /> 
								</div>
							</Tooltip>
						</div>,
					<Fragment key={"row_edit"}>
						{ this.row_edit() }
					</Fragment>
				], 
				100,
				"d-flex"
			]
		];
		if( this.type === "category" && this.props.options.rating_type === "each_category")
		{
			rows.splice(
				4, 
				0, 
				[
					'rating',	
					<div style={{minWidth : 220}} key={"raiting"}>
						{ __( "Rating type" ) }
					</div>, 
					220, 
					"flex-grow-100 px-2" 
				]
			)	
		}
		return rows
	}
	onDefRow(col, elem, isOpen=false)
	{
		let txt;
		switch(col)
		{
			case "title":
				txt = this.getPostTitle(elem);
				break;
			case "edit":
				txt = <div 
					className="table-btn " 
					onClick={ evt => this.onEditForm(evt, isOpen)} 
					tid={ elem.id || elem.ID } 
				>
						{__(!isOpen ? "Show" : "Close")}
					</div>
				break;
			case "rating":
				const rt = CATEGORY_RATING_TYPE().filter( e => e._id === elem.rating_type)[0]
				const rating_type = rt 
					?
					__(rt.title)
					:
					<span className="text-danger">{__("not defined")}</span>
				txt = <div className=" small" >
					<div>{rating_type}</div>
				</div>
				break;
			default:
				txt = this.onRow(col, elem);
		}
		return txt;
	}
	onRow(col, elem)
	{
		let txt;
		switch(col)
		{
			case "bulk":
				txt = this.bulk(false, elem)
				break;
			case "color":
				txt = <div 
						className="color-display"
						style={{backgroundColor: elem.color }}
					/>
				break;
			case "order":
				txt = <div className="lead text-center w-100">{elem.order}</div> 
				break;
		}
		return txt;
	}
	getPostTitle(elem)
	{
		return <div>{elem.post_title}</div>;
	}
	row_edit()
	{
		return null
	}
	addThead()
	{
		return null;
	}
	onDeleteCollectionOpen = () =>
	{ 
		//console.log( this.state.bulks.filter( e => !isNaN(e) && e !== false ) )
		this.setState({isDeleteCollectionOpen : !this.state.isDeleteCollectionOpen})
	}
	onDeleteCollection = () =>
	{
		_fetch(
			"lead_delete_collection", 
			{
				blog_id		: this.props.blog_id,
				post_ids	: this.state.bulks.filter( e => !isNaN(e) && e !== false ),
				type 		: this.type
			}
		)
		.then(data =>
		{
			//console.log(data);
			this.setState({
				post : data.collection.post,
				isDeleteCollectionOpen:false
			})
		})
	}
}