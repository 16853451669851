
import React, {Fragment,Component} from "react"; 
import { __ } from "react-pe-utilities";
import { Icon, Tag, Intent } from "@blueprintjs/core";
import Categories from "./Categories";
import CriteryForm from "./single/CriteryForm";
import _fetch from "modules/pe-wpfest-admin-module/api";

export default class Criteries extends Categories
{	
	type = "critery"
	componentWillMount()
	{
		_fetch("lead_fest_ganres", {id: this.props.blog_id})
			.then(data =>
				{ 
					//console.log(data)
					let fmru_ganres = data.lead.post || []
					if(!data.isError)
					{					
						_fetch("lead_fest_milestones", {id: this.props.blog_id})
							.then(data =>
							{ 
								let fmru_milestones = data.lead?.post || []
								if(!data.isError)
								{
									_fetch("lead_fest_cats", {id:this.props.blog_id} ) 
										.then(data =>
										{		
											//console.log(data);
											if(!data.isError)
											{
												this.init({
													form_component	: CriteryForm,
													theadColor		: "#416348",	
													meta			: { 
														fmru_category: {}, 
														fmru_categories: data.lead.post,
														fmru_ganres: fmru_ganres,
														fmru_milestones: fmru_milestones
													}
												})
											}
											else
											{
												
											}
										})
								}
							})
					}
				})
	}
	rows()
	{
		let rw = super.rows().filter(elem => elem[0] != 'color' );
		if(this.props.options.is_independent_grade_criteries )
		{
			rw.splice( 3, 0,
				[
					'max_rating',	
					<div style={{minWidth:70, display:"flex", justifyContent:"center"}} key={"max_rating"}>
						{__("Max raiting")}
					</div>, 
					70
				]
			); 
		}
		rw.splice( 3, 0,
			[
				'milestones',	
				<div style={{minWidth:170, display:"flex", justifyContent:"center"}} key={"milestone"}>
					{__("Milestones")}
				</div>, 
				170
			]
		); 
		return rw;
	}
	onRow(col, elem)
	{
		let txt = super.onRow(col, elem);
		switch(col)
		{
			case "milestones":
				const fmru_milestone = elem.fmru_milestone?.map(e => <Tag 
					interactive
					round
					minimal
					key={e.id}
				>
					{e.post_title}
				</Tag>);
				txt = <div className="d-flex flex-centered">{fmru_milestone}</div>
				break
			case "max_rating":
				txt = <div className="d-flex flex-centered">{elem.max_rating}</div>
				break
		}
		return txt;
	}
	getPostTitle = elem =>
	{
		const tags = elem.fmru_category?.map(e => <Tag
					style={{backgroundColor : e.color}}
					interactive={true}
					key={e.id}
				>
					{e.post_title}
				</Tag>);
		
		return <Fragment>
			<div>{elem.post_title}</div>
			<div>{tags}</div>			
		</Fragment>
	}
}