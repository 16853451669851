import _fetch from "../views/wpfestREST"
import { CRITERY_PARENT_TYPES } from "../views/wpfestREST/core/rating/Critery"
import User from "../views/wpfestREST/utilities/User"
import { DEFAULT_MEMBER_FILTERS, useFestStore } from "./store"
 
export const UPDATE_FEST_ACTION = "update_fest"
export const ABOUT_FEST_ACTION = "about"
export const SET_TRACK_FILTER_ACTION = "set_track_filter"
export const UPDATE_MEMBER_ACTION = "update_member"
export const UPDATE_OWNERS_ACTION = "update_owners"
export const SET_HONEYCOMDS_FILTER_ACTION = "set_honeycombs_filter"
export const LOAD_MEMBER_ACTION = "fmru_player"
export const LOAD_MEMBERS_LIST_ACTION = "list"
export const GET_MEMBER_CRITERY_ACTION = "get_member_critery"
export const CHANGE_CRITERY_ACTION = "change_critery"
export const GET_SINGLE_MEMBER_ACTION = "get_single_member"
export const GET_TRACK_MODERATOR_ACTION = "get_track_moderator"
export const PRE_REGISTER_USER_ACTION = "pre_register_user"
export const STATISTICS_FULL_DATA_ACTION = "statistics_full_data"


const actions = (action, data) =>
{ 
    let response = {},
        fests = [], 
        fest = {},
        festData = {},
        all_honeycombs  = [],
        tracks  = [],
        categories  = [],
        orphans  = [],
        uniqs  = [],
        aut_criteries  = [],
        milestones  = [],
        allMembers  = [],
        members  = [],
        my_members  = [],
        singleMember = {},
        singleCritery = {},
        singleCategory = {},
        filters = {
            honeycombs: [],
            tracks: []
        } 
    switch(action)
    {  
        case PRE_REGISTER_USER_ACTION:
            if(data.options)
                useFestStore.getState().setOptions( data.options )
            if(data.ganres)
                useFestStore.getState().setGanres( data.ganres )
            return response
        case UPDATE_FEST_ACTION:
            fests = [ ...useFestStore.getState().fests ]
            fests[ Number(data.id) ] = {
                ...fests[ Number(data.id) ],
                ...data.fest
            }
            console.log( data )
            useFestStore.getState().setFests( fests )
            if(data.options)
                useFestStore.getState().setOptions( data.options )
            if(data.ganres)
                useFestStore.getState().setGanres( data.ganres )
            if(data.tracks)
                useFestStore.getState().setTracks( { tracks: data.tracks } )
            if(data.milestones)
                useFestStore.getState().setMilestones( data.milestones )
            return response
        case ABOUT_FEST_ACTION: 
            useFestStore.getState().setMyMembers({ myMembers:data.my_members })
            useFestStore.getState().setOptions( { 
                memberCount: data.full_members,
                countOfResults: data.countOfResults 
            })
            return response
        case SET_TRACK_FILTER_ACTION:
            // console.log(data)
            if(!Array.isArray(data.tracks) || data.tracks.length === 0 ) 
            {
                data.tracks = [-1]
            }
            useFestStore.getState().setMemberFilters({ tracks : data.tracks, honeycombs: [-1]  })           
            members = useFestStore.getState().allMembers.filter(member => { 
                return data.tracks[ 0 ] === -1
                    ?
                    true
                    :
                    data.tracks[ 0 ] === "mine"
                        ?
                        useFestStore.getState().allMyMembers
                            .filter(mm => {
                                return mm.track?.ID === member.track?.ID
                            }).length > 0
                        :
                        member.track?.ID == data.tracks[ 0 ]
            }) 
            useFestStore.getState().setMembers({ members })
            my_members = useFestStore.getState().allMyMembers.filter(member => {
                return data.tracks[ 0 ] === -1
                    ?
                    true
                    :
                    data.tracks[ 0 ] === "mine"
                        ?
                        true
                        :
                        member.track?.ID == data.tracks[ 0 ]
            }) 
            useFestStore.getState().setMyMembers({ myMembers: my_members })
            return response
        case SET_HONEYCOMDS_FILTER_ACTION:
            filters = useFestStore.getState().setMemberFilters({ honeycombs : data.honeycombs, tracks: [-1] })           
            members = useFestStore.getState().allMembers.filter(member => {
                return data.honeycombs[ 0 ] === -1
                    ?
                    true
                    :
                    data.honeycombs[ 0 ] === "mine"
                        ?
                        useFestStore.getState().myHoneycombs.filter(mh => mh === member.honeycombs?.ID).length > 0
                        :
                        member.honeycombs?.ID == data.honeycombs[ 0 ]
            }) 
            useFestStore.getState().setMembers({ members })
            my_members = useFestStore.getState().allMyMembers.filter(member => {
                return data.honeycombs[ 0 ] === -1
                    ?
                    true
                    :
                    data.honeycombs[ 0 ] === "mine"
                        ?
                        true
                        :
                        member.honeycombs.ID == data.honeycombs[ 0 ]
            }) 
            useFestStore.getState().setMyMembers({ myMembers: my_members })
            return response
        case LOAD_MEMBERS_LIST_ACTION:
            // записываем прилетевшие с сервера Проекты 
            if( !data.members && !Array.isArray(data.members) )    
            {
                useFestStore.getState().setAllMembers({ allMembers: [] })
                useFestStore.getState().setMembers({ members: [ ] })
                return response  
            }   
            members     =  useFestStore.getState().members     
            allMembers  =  useFestStore.getState().allMembers     
            data.members.forEach( member =>
            {
                members[member.id] = {
                    ...members[member.id],
                    ...member
                }
                allMembers[member.id] = {
                    ...allMembers[member.id],
                    ...member
                }
            })
            // console.log( data )   
            useFestStore.getState().setMyHoneycombs({ myHoneycombs: data.my_honeycombs })
            useFestStore.getState().setOptions({ options: data.options })
            useFestStore.getState().setAllMembers({ allMembers })
            useFestStore.getState().setMembers({ members })
            useFestStore.getState().setAllMyMembers({ allMyMembers : data.my_members })
            useFestStore.getState().setMyMembers({ myMembers : data.my_members })
            useFestStore.getState().setTracks({ tracks : data.tracks }) 
            useFestStore.getState().setHoneycombs( data.all_honeycombs ) 
            useFestStore.getState().setMyTracks( data.my_tracks ) 
            
            if( useFestStore.getState().memberFilters !== DEFAULT_MEMBER_FILTERS )
            {
                actions( SET_TRACK_FILTER_ACTION, useFestStore.getState().memberFilters )
            }

            //timeout for refresh user's cookie login\logout
            setTimeout(() => {
                if(
                    User.isRole([ "Track_Moderator", "Tutor", "Project_author" ])
                    && useFestStore.getState().myMembers.length > 0 
                    //&& useFestStore.getState().memberFilters !== DEFAULT_MEMBER_FILTERS
                )
                {
                    actions(SET_TRACK_FILTER_ACTION, {tracks: ["mine"], honeycombs: [-1]}) 
                }
                else
                {
                    // actions(SET_TRACK_FILTER_ACTION, {tracks: [-1], honeycombs: [-1]}) 
                } 
            }, 100)
                          
            response = members
            return response
        case LOAD_MEMBER_ACTION: 
            // записываем прилетевший с сервера Проект ( FestMember )
            if(!data.member)    return response
            members = useFestStore.getState().members 
            allMembers = useFestStore.getState().allMembers 
            const loadedMember = {
                ...( members[ Number(data.member.id) ] || {} ),
                ...data.member,
                id:             Number(data.member.id),
                experts :       data.experts,
                is_independent_grade_criteries:   data.is_independent_grade_criteries, 
                expert_descr:   data.denabexpert_descrled,
                count_juri:     data.count_juri,
                ganres:         data.ganres || [],
                categories:     data.categories || [],
                orphans:        data.orphans || [],
                aut_criteries:  data.aut_criteries || [],
                uniqs:          data.uniqs || [],
                r2 :            data.r2,
                rait :          data.rait,
                correct_member_rate : data.correct_member_rate,
                tracks :        data.tracks,
                average_rating: data.summ_average_rating,
                milestones:     data.milestones,
                my_honeycombs : data.my_honeycombs,
                owners:     {
                    lead:   data.owners.leader,
                    tutor:  data.owners.tutor
                }
            } 
            members[ Number(data.member.id) ] = {...loadedMember} 
            allMembers[ Number(data.member.id) ] = {...loadedMember} 
            useFestStore.getState().setMembers( members ) 
            useFestStore.getState().setAllMembers( allMembers ) 
            useFestStore.getState().setHoneycombs( data.all_honeycombs ) 
            useFestStore.getState().setOptions({ options: data.options })
            response.members = members
            return response 
        case GET_SINGLE_MEMBER_ACTION: 
            members = useFestStore.getState().members
            members[ Number(data.member_id) ] = {
                ...useFestStore.getState().members,
                experts :       data.experts,
                honeycombs :    data.honeycombs,
                max_raiting :   data.max_raiting,
                milestones :    data.milestones,
                my_honeycombs : data.my_honeycombs,
                orphans :       data.orphans,
                owners :        data.owners,
                r2 :            data.r2,
                rait :          data.rait,
                tracks :        data.tracks,
                uniqs :         data.uniqs,
            } 
            response = members[Number(data.member_id)]
            console.log( response )
            useFestStore.getState().setMembers( members )
            return response
        case GET_MEMBER_CRITERY_ACTION:
            //получаем данные критерия (Critery)
            members = useFestStore.getState().members
            if( (singleCritery = members[data.member_id].orphanse.filter(cr => cr.id === data.critery_id)).length > 0)
            {
                response.critery = singleCritery[0]
            }
            else if( (singleCritery = members[data.member_id].uniqs.filter(cr => cr.id === data.critery_id)).length > 0)
            {
                response.critery = singleCritery[0]
            }
            else if( (singleCategory = members[data.member_id].categories.filter(cat => {
                return cat.criteries.filter(cr => cr.id === data.critery_id).length > 0
            })).length > 0 )
            {
                response.critery = singleCategory[0].criteries.filter(cr => cr.id === data.critery_id)[0]
            }
            return response
        case CHANGE_CRITERY_ACTION: 
            members = useFestStore.getState().members
            allMembers = useFestStore.getState().allMembers
            singleMember = allMembers[data.member_id]
            // console.log( "before: ", allMembers[data.member_id] )
            switch(data.type)
            {
                case CRITERY_PARENT_TYPES.category:
                    
                    break
                case CRITERY_PARENT_TYPES.uniqs:
                    const uniqs = singleMember.uniqs.map( cr => {
                        if(cr.id === data.critery_id)
                        {
                            cr = {
                                ...cr, 
                                critery_rating: data.critery_rating, 
                                raiting: data.raiting, 
                                description:data.description 
                            }
                        }
                        return cr
                    })
                    singleMember = { ...singleMember, uniqs }
                    break
                case CRITERY_PARENT_TYPES.orphans:
                default:
                    const orphans = singleMember.orphans.map( cr => {
                        if(cr.id === data.critery_id)
                        {
                            cr = {
                                ...cr, 
                                critery_rating: data.critery_rating, 
                                raiting: data.raiting, 
                                description: data.description 
                            }
                        }
                        return cr
                    })
                    singleMember = { ...singleMember, orphans }
                    
                    break
            }
            allMembers[ data.member_id ] = singleMember
            members[ data.member_id ] = singleMember
            // console.log( "after: ", allMembers[data.member_id] )
            useFestStore.getState().setMembers( members )
            useFestStore.getState().setAllMembers( allMembers )
            return response
        case GET_TRACK_MODERATOR_ACTION:
            useFestStore.getState().setAlOwners(  data.all_owners )  
            allMembers = useFestStore.getState().allMembers
            if(Array.isArray(data.members))
            {
                data.members.forEach(member => {
                    allMembers[ member.id ] = {
                        ...allMembers[ member.id ],
                        ...member
                    }
                })
                useFestStore.getState().setAllMembers( { allMembers } )
            } 
            useFestStore.getState().setMyTracks( data.my_tracks )
            useFestStore.getState().setOptions({ options: data.options })
            return response
        case UPDATE_MEMBER_ACTION:
            allMembers = useFestStore.getState().allMembers        
            allMembers[data.id] = {
                ...allMembers[data.id],
                ...data.member
            }
            console.log(data,  allMembers[data.id] )
            useFestStore.getState().setAllMembers({allMembers})    
            return response
        case UPDATE_OWNERS_ACTION:
            useFestStore.getState().setAlOwners(  data.all_owners )
            return response 
        case STATISTICS_FULL_DATA_ACTION:
            //console.log( data.full_ratings, data.members, data.users, useFestStore.getState().tracks, useFestStore.getState().milestones )
            useFestStore.getState().setStatistics({
                full_ratings: data.full_ratings,
                members: data.members,
                users:data.users
            }) 
            return response
        default:
            return response
    }
}
export default actions