import { Button, Card, Collapse, Dialog, Icon, Intent } from "@blueprintjs/core";
import _fetch from "modules/pe-wpfest-admin-module/api";
import { getCookie } from "modules/pe-wpfest-admin-module/utilities/utils";
import React from "react"
import { useEffect } from "react";
import { useState } from "react";
import FieldInput from "react-pe-scalars/dist";
import { AppToaster, Loading } from "react-pe-useful/dist";
import { sprintf, __ } from "react-pe-utilities/dist";
import { useHistory } from "react-router";
import CabinetView, { WPFEST_ADMIN_MY_ROUTE } from "../../CabinetView";
import SiteSingle from "../SiteSingle";
import ClearMaster from "./inheritance/ClearMaster";

import { ReactComponent as Stripe } from "../../../assets/img/stripe.svg"
import { ReactComponent as Succesor } from "../../../assets/img/succesor.svg"
import { ReactComponent as Clear } from "../../../assets/img/clear.svg"
import { ReactComponent as DeleteFest } from "../../../assets/img/deleteFest.svg"

const EXPORT_GRANDING_SYSTEM = "export"
const TEMPLATE_GRANDING_SYSTEM = "template"
const Inheritance = props =>
{
    const [isOpen1, onOpen1] = useState(true)
    const [isOpen2, onOpen2] = useState(false)
    const [isClearOpen, setClearOpen] = useState(false)
    const [isDeleteOpen, setDeleteOpen] = useState(false)
    const [newTitle, onNewTitle] = useState("")
    const [newURL, onNewURL] = useState("")
    const [newGranding, onNewGranding] = useState( TEMPLATE_GRANDING_SYSTEM )
    const [newStirpe, onNewStirpe] = useState("")
    const [selectProject, changeSelectProjects] = useState([])
    const [selectCategory, changeSelectCategories] = useState([])
    const [selectCritery, changeSelectCriteries] = useState([])
    const [selectGanre, changeSelectGanres] = useState([])
    const [patterns, setPatterns] = useState( [] ) 
    const [newType, onNewType] = useState( "" )
    const [loading, setLoading] = useState( true )
    const history = useHistory()
    useEffect(() =>
    { 
        _fetch( "init" )
            .then(data => 
                { 
                    console.log( data )
                    setPatterns( data.sites || [] )  
                    setLoading( false ) 
                })
        getSelectionProjects()
        getSelectionCategories()
        getSelectionCriteries()
        getSelectionGanres()
        return () => {
            clearTimeout(timeOut1)
            clearTimeout(timeOut2)
            clearTimeout(timeOut3)
            clearTimeout(timeOut4)
        }
    },[])

    let timeOut1 = 1
    let timeOut2 = 2
    let timeOut3 = 3
    let timeOut4 = 4
    const switch1 = () =>
    {
        onOpen1(!isOpen1)
        onOpen2(!isOpen2)
    }
    const changeNewStirpe = value =>
    {
        console.log( value )
        onNewStirpe( value )
    }
    const getSelectionProjects = () =>
    {
        let sel = getCookie(`pefest-admin-selected-project-${ props.blog_id }`)	    
        timeOut1 = setTimeout(() =>
		{
            const selectedList = sel ? sel.split(",").map( e => Number(e) ).filter(e => !isNaN(e)) : []
            console.log( selectedList )
			changeSelectProjects (selectedList)
		}, 100)
    }
    const getSelectionCategories = () =>
    {
        let sel = getCookie(`pefest-admin-selected-category-${ props.blog_id }`)	    
        timeOut2 = setTimeout(() =>
		{
            const selectedList = sel ? sel.split(",").map( e => Number(e) ).filter(e => !isNaN(e)) : []
            console.log( selectedList )
			changeSelectCategories (selectedList)
		}, 100)
    }
    const getSelectionCriteries = () =>
    {
        let sel = getCookie(`pefest-admin-selected-critery-${ props.blog_id }`)	    
        timeOut3 = setTimeout(() =>
		{
            const selectedList = sel ? sel.split(",").map( e => Number(e) ).filter(e => !isNaN(e)) : []
            console.log( selectedList )
			changeSelectCriteries (selectedList)
		}, 100)
    }
    const getSelectionGanres = () =>
    {
        let sel = getCookie(`pefest-admin-selected-ganre-${ props.blog_id }`)	    
        timeOut3 = setTimeout(() =>
		{
            const selectedList = sel ? sel.split(",").map( e => Number(e) ).filter(e => !isNaN(e)) : []
            console.log( selectedList )
			changeSelectGanres (selectedList)
		}, 100)
    }
    const selectProjects = () =>
    {
        _fetch( "lead_fest_projects", {id: props.blog_id } ).
			then(data =>
			{
				SiteSingle.instance.setState({ 
					active:         "projects", 
					active2:        0, 
					currentButton:  SiteSingle.instance.allButtons.projects,
					data:           data.lead 
				});
			});
    }
    const selectCategories = () =>
    {
        _fetch( "lead_fest_cats", {id: props.blog_id } ).
			then(data =>
			{
				SiteSingle.instance.setState({ 
					active:         "rate", 
					active2:        "categories", 
					currentButton:  SiteSingle.instance.allButtons.rate.children.categories,
					data:           data.lead 
				});
			});
    }
    const selectCriteries = () =>
    {
        _fetch( "lead_fest_criteries", {id: props.blog_id } ).
			then(data =>
			{
				SiteSingle.instance.setState({ 
					active:         "rate", 
					active2:        "criteries", 
					currentButton:  SiteSingle.instance.allButtons.rate.children.criteries,
					data:           data.lead 
				});
			});
    }
    const selectGanres = () =>
    {
        _fetch( "lead_fest_ganres", {id: props.blog_id } ).
			then(data =>
			{
				SiteSingle.instance.setState({ 
					active:         "rate", 
					active2:        "ganres", 
					currentButton:  SiteSingle.instance.allButtons.rate.children.ganres,
					data:           data.lead 
				});
			});
    }
	const onClearOpen = () =>
	{
        setClearOpen(!isClearOpen) 
	}
	const onDeleteOpen = () =>
	{
        setDeleteOpen(!isDeleteOpen) 
	}
    const onStartClean = data =>
    {
        console.log( data )
        // setClearOpen(false)
        _fetch( "clean_festival", { blog_id : props.blog_id, data: data } )
			.then(data => 
            { 
                console.log( data.results ) 
                setClearOpen( false )
                AppToaster.show({
                    message: <div dangerouslySetInnerHTML={{__html: __("Success removed festival data:") + " <br>" + Object.keys(data.results).map(e => __(e)). join(", <br>")}}></div>, 
                    intent: Intent.SUCCESS,
                    className: "p-3"
                })
            })
    }
	const onDelete = () =>
	{
		_fetch( "delete_single_site", { blog_id : props.blog_id } )
			.then(data => 
				{ 
					console.log(data) 
					history.push( "/" + CabinetView.WPFESTAdminRoute + "/" + WPFEST_ADMIN_MY_ROUTE )
				})
	}
    
    if(loading)     return <Loading />
    
    const iconSize = 50
    const serverURLs = CabinetView.serverURL.split("//")
    return <div className="">
        <div className="p-5 alert alert-secondary w-100 mb-2 m-0">
            <p className="display-6 font-italic">
                Будь осторожен, мой друг! Это — Чернобыль. Место, которое пахнет смертью.
            </p>
            <p className="mt-2 title text-center">
                Вергилий
            </p>
        </div>
        <Card
            fill={"true"} 
            interactive={true} 
            className="p-4 mb-2"
        >
            <div className="lead mb-5">{__("Stirpes")}</div>
            <div className="flex-centered w-100">
                <div className=" ">
                    <Stripe width={iconSize} height={iconSize} className="m-5" />
                </div>
                <div className="w-100">
                    <FieldInput
                        type="string"
                        field="stirpe"
                        title={__("Predecessor")}
                        value={""}
                        editable={false}
                    /> 
                    <FieldInput
                        type="string"
                        field="stirpe"
                        title={__("stirpe Festival")}
                        value={newStirpe}
                        onChange={ changeNewStirpe }
                    />
                </div>
            </div>
        </Card>
        <Card
            fill={"true"} 
            interactive={true} 
            className="p-4 mb-2"
        >
            <div className="lead mb-2">{__("Creating successor")}</div>
            <div className="flex-centered w-100">
                <div className=" ">
                    <Succesor width={iconSize} height={iconSize} className="m-5" />
                </div>
                <div className="w-100"> 
                    <Collapse isOpen={isOpen1}>
                        {
                            newStirpe
                                ?
                                <Button
                                    fill
                                    large
                                    intent={Intent.SUCCESS}
                                    minimal
                                    onClick={switch1}
                                >
                                    {__("Start creation new successor")}
                                </Button>
                                :
                                <div className="alert alert-danger p-5 lead text-center">
                                    {__("For creating successor need Stripe name")}
                                </div>
                        }
                        
                    </Collapse>
                    <Collapse 
                        isOpen={isOpen2} 
                        transitionDuration={1000}
                        children ={ <>
                            <div className="pb-5">                        
                                <FieldInput
                                    type="string"
                                    field="title"
                                    title={__("Insert title successor Festival")}
                                    value={newTitle}
                                    onCange={ value => onNewTitle(value) }
                                />                                        
                                                
                                <div className="row  dat strob01">
                                    <div className="col-md-3 layout-label">
                                        {__("URL of successor Festival (only singleworld name)")}
                                    </div>
                                    <div className="col-md-9 layout-data">
                                        <div className="py-2 w-100 d-flex align-items-center">  
                                            <i className="fas fa-desktop mr-2" />                              
                                            <span className="prefix">{ serverURLs[0] + "//" }</span>
                                            <input
                                                type="text"
                                                className="form-control input dark"
                                                value={ newURL }
                                                onChange={ evt => onNewURL( evt.currentTarget.value ) }
                                            />
                                            <span className="postfix ml-2">{ "." + serverURLs[serverURLs.length - 1] }</span> 
                                            <Button
                                                className="right"
                                                icon="cross"
                                                minimal
                                                onClick={ () => onNewURL('') }
                                            />
                                        </div>                                
                                    </div>
                                </div>                  

                                <div className="row  dat strob01">
                                    <div className="col-md-3 layout-label">
                                        {__("Export projects")}
                                    </div>
                                    <div className="col-md-9 layout-data">
                                        <div className="py-2 w-100">
                                            <div className="pb-2">
                                                {sprintf(__("%s projects selected for export to successor"), selectProject.length )}
                                            </div>
                                            <Button fill large alignText="left" onClick={selectProjects}>
                                                {__("Select over projects collection")}
                                            </Button>
                                            <div className="small opacity_75 pt-2">
                                                {__("Check bulk of every Project to export and click «Save selction» button")}
                                            </div>
                                        </div>                                
                                    </div>
                                </div>
                                            
                                <FieldInput
                                    type="radio" 
                                    title={__("Grading system transfer settings")}
                                    value={newGranding}
                                    values={[
                                        {
                                            _id:    EXPORT_GRANDING_SYSTEM,
                                            title:  "Export from here",
                                            commentary: "Transfer selected categories, criteria and genres from this Festival"
                                        },
                                        {
                                            _id:    TEMPLATE_GRANDING_SYSTEM,
                                            title:  "Use site template",
                                            commentary: "The rating system will be imported from one of the ready-made Festival templates. It must be chosen"
                                        }
                                    ]}
                                    onChange={ ( value, field) => onNewGranding( value, field ) }
                                />  
                                <Collapse
                                    isOpen={ newGranding === EXPORT_GRANDING_SYSTEM}
                                    children ={ <>
                                        <div className="row  dat strob01">
                                            <div className="col-md-3 layout-label">
                                                {__("Export Categories")}
                                            </div>
                                            <div className="col-md-9 layout-data">
                                                <div className="py-2 w-100">
                                                    <div className="pb-2">
                                                        {sprintf(__("%s categories selected for export to successor"), selectCategory.length )}
                                                    </div>
                                                    <Button fill large   alignText="left" onClick={selectCategories}>
                                                        {__("Select over categories collection")}
                                                    </Button>
                                                    <div className="small opacity_75 pt-2">
                                                        {__("Check bulk of every Category to export and click «Save selction» button")}
                                                    </div>
                                                </div>                                
                                            </div>
                                        </div>              
                                        <div className="row  dat strob01">
                                            <div className="col-md-3 layout-label">
                                                {__("Export Criteries")}
                                            </div>
                                            <div className="col-md-9 layout-data">
                                                <div className="py-2 w-100">
                                                    <div className="pb-2">
                                                        {sprintf(__("%s criteries selected for export to successor"), selectCritery.length )}
                                                    </div>
                                                    <Button fill large   alignText="left" onClick={selectCriteries}>
                                                        {__("Select over criteries collection")}
                                                    </Button>
                                                    <div className="small opacity_75 pt-2">
                                                        {__("Check bulk of every Critery to export and click «Save selction» button")}
                                                    </div>
                                                </div>                                
                                            </div>
                                        </div>            
                                        <div className="row  dat strob01">
                                            <div className="col-md-3 layout-label">
                                                {__("Export Ganres")}
                                            </div>
                                            <div className="col-md-9 layout-data">
                                                <div className="py-2 w-100">
                                                    <div className="pb-2">
                                                        {sprintf(__("%s ganres selected for export to successor"), selectGanre.length )}
                                                    </div>
                                                    <Button fill large   alignText="left" onClick={selectGanres}>
                                                        {__("Select over ganres collection")}
                                                    </Button>
                                                    <div className="small opacity_75 pt-2">
                                                        {__("Check bulk of every Ganre to export and click «Save selction» button")}
                                                    </div>
                                                </div>                                
                                            </div>
                                        </div>
                                    </>}
                                />    
                                <Collapse
                                    isOpen={ newGranding === TEMPLATE_GRANDING_SYSTEM}
                                    children={ 
                                        <div className="row  dat strob01">
                                            <div className="col-md-3 layout-label">
                                                {__("Get granding system from template site")}
                                            </div>
                                            <div className="col-md-9 layout-data">
                                                <div className="py-2 w-100 d-flex align-items-center"> 
                                                    <select 
                                                            className="form-control input dark" 
                                                            value={newType} 
                                                            onChange={evt => onNewType( evt.currentTarget.value ) }
                                                        >
                                                        {
                                                            patterns.map(site => {
                                                                return <option
                                                                    key={ site.domain_id }
                                                                    value={ site.domain_id }
                                                                >
                                                                    {site.title}
                                                                </option>
                                                            })
                                                        }    
                                                    </select>
                                                </div>
                                            </div> 
                                        </div> 
                                    }
                                />            
                                
                            </div> 
                            <Button
                                fill
                                large
                                intent={ Intent.SUCCESS }
                                onClick={ switch1 }
                            >
                                {__("Create successor right now")}
                            </Button>
                        </>}
                    /> 
                </div>
            </div>
        </Card>
        <Card fill={"true"} className="p-4 mb-2">
            <div className="lead mb-2">{__("Clean festival data")}</div>
            <div className="flex-centered w-100">
                <div className=" ">
                    <Clear width={iconSize} height={iconSize} className="m-5" />
                </div>
                <div className="w-100">
                    <Button
                        intent={Intent.WARNING}
                        className="lead px-5 py-4"
                        minimal
                        onClick={onClearOpen}            
                    >
                        <Icon icon="filter-remove" className="mr-3"/>
                        {__("Start clean Master")}
                    </Button>
                </div>                 
            </div>
            
        </Card>	
        <Card fill={"true"} className="p-4 mb-2">
            <div className="lead mb-2">{__("Delete the festival and its data permanently")}</div>
            <div className="flex-centered w-100">
                <div className=" ">
                    <DeleteFest width={iconSize} height={iconSize} className="m-5" />
                </div>
                <div className="w-100">
                    <Button
                        intent={Intent.DANGER}
                        className="lead p-4"
                        fill
                        onClick={ onDeleteOpen }
                    >
                        <Icon icon="trash" className="mr-3"/>
                        {__("Delete now")}
                    </Button> 
                </div>
            </div>
        </Card>
        <Dialog
            isOpen={ isDeleteOpen}
            onClose={ onDeleteOpen}
            title={__("Delete the festival and its data permanently")}
            className="little"
        >
            <div className="p-4">
                <Button
                    intent={Intent.DANGER}
                    className="lead p-4"
                    fill
                    onClick={ onDelete }
                >
                    {__("Delete now")}
                </Button>
            </div>	
        </Dialog>
        <Dialog
            isOpen={ isClearOpen}
            onClose={ onClearOpen} 
            className=""
        >
            <div className="p-0">
                <div className="p-4">
                    <ClearMaster 
                        onStartClean={onStartClean}
                    />
                </div>
                
            </div>	
        </Dialog>
    </div>
}
export default Inheritance;