import { Intent } from "@blueprintjs/core";
import IndexDB from "modules/pe-admin-module/views/utilities/IndexDB";
import _fetch from "modules/pe-wpfest-admin-module/api";
import React, { useEffect, useState } from "react"
import { AppToaster, Loading } from "react-pe-useful/dist";
import { __ } from "react-pe-utilities/dist"; 
import CabinetView from "../CabinetView";

const Options = props =>
{   
    //console.log(props)
    const [options, setOptions]  = useState( [] )
    const [loading, setLoading] = useState( true ) 
    const [isOnline, setOnline] = useState( true ) 
    useEffect(() => {
        IndexDB.find(
            "peFestAdmin_options",
            "global_options",
            "global_options",
            "global_options"
        )
            .then(res =>
                {
                    setOptions( res.data )
                    setLoading( false )
                })
        _fetch( "get_global_options" )
            .then(data => 
                {
                    const global_options = data.global_options
                    setOptions( global_options ) 
                    setLoading( false ) 
                    if(!data.isError)
                    {
                        IndexDB.save( 
                            global_options, 
                            "global_options", 
                            "global_options", 
                            "global_options",
                            "peFestAdmin_options",  
                            1 
                        )
                    } 
                    else
                    {
                        IndexDB.recover("peFestAdmin_options", "global_options", "global_options",)
                            .then(g =>
                                {
                                    console.log( g[0] )
                                    if(g[0])
                                    {
                                        setOptions( g[0].data )
                                    }
                                })
                        setOnline(false)
                    }
                     
                })
    }, [])
    
    if(loading)
        return <Loading />
    
    const changeValue = (evt, key) => { 
        let prevOptions = JSON.stringify({...options})
        let _options = {...options}
        const value = evt.target.value
        _options[key].value = value 
        setOptions( _options );
        //console.log(_options)
        
        _fetch( "update_global_option", { field : key, value : value, title : _options[key].title, descr : _options[key].descr } )
            .then(data => 
                {
                    if(data.isError)
                    {
                        const op = JSON.parse( prevOptions )
                        // console.log( op ) 
                        setOptions( op ) 
                    }
                    else
                    {
                        // console.log( data );
                        //setOptions( data.global_options ) 
                        //setLoading( false )  
                    }
                    
                })
    }
    const lines = options 
        ?
        Object.keys(options).map((key, i) => {
            return <div className="row" key={i}>
                <div className="col-md-3 layout-label">
                     { options[ key ].title }
                </div>
                <div className="col-md-9 layout-data">
                    <input 
                        type="text"
                        className="form-control input"
                        value= { options[ key ].value }
                        onChange={evt => changeValue(evt, key) }
                        disabled={!isOnline}
                    />
                </div>
                <div className="col-md-9 offset-md-3 small" >
                    {__(options[ key ].descr)}
                </div>
            </div>
        })
        :
        null
    return <div className="p-3 w-100 text-light"> 
        { lines }
        <div className="py-3 row">
            <div className="col-md-3 layout-label" >
                {__("server URL")}
            </div>
            <div className="col-md-9 layout-data">
                <input 
                    type="text"
                    className="form-control input"
                    value= { CabinetView.serverURL } 
                    disabled
                />
            </div>
            <div className="col-md-9 offset-md-3 small" >
                {__("This parameter set in route's options in PE-Admin Site map")}
            </div>
        </div>
    </div>
}
export default Options