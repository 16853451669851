import React from 'react'
import PropTypes from 'prop-types'
import getFestivalRoute from 'modules/pe-wpfest-admin-module/utilities/getFestivalRoute'
import { Link } from 'react-router-dom'
import { __ } from 'react-pe-utilities/dist'
import { moduleExists } from 'react-pe-layouts'

const GotoFestivalPanel = props => {
    const routeURL = getFestivalRoute().reduce((prevousValue, currentValue, currentIndex, array) => {
        if(prevousValue)
        {
            return prevousValue
        }
        else
        {
            if( currentValue.extend_params?.festID &&  currentValue.extend_params?.festID == props.blog_id )
            {
                return `/${ currentValue.route }`
            } 
            else if( !currentValue.extend_params?.festID )
            {
                return `/${ currentValue.route }/${ props.blog_id }`
            }
            return null
        }
        
    }, null)
    // const routeURL = r.extend_params?.festID
    //     ?
    //     `/${ r.route }`
    //     :
    //     `/${ r.route }/${ props.blog_id }`
    return moduleExists( "pe-fest-module" ) && routeURL 
        ?
        <div className="px-4 py-1 mb-2">
            <div className="float-right">
                <Link 
                    className="small"
                    to={ routeURL } 
                >
                    {__("Goto")}
                </Link>
            </div>
            <div className="small mb-1">{ props.domain }</div>						
        </div>
        :
        <div className="px-4 py-1 mb-2">
            <div className="float-right">
                <a 
                    className="small"
                    href={ props.url }
                    target="_blank"
                >
                    {__("Goto")}
                </a>
            </div>
            <div className="small mb-1">{ props.url }</div>						
        </div>
}

GotoFestivalPanel.propTypes = {}

export default GotoFestivalPanel