import React, { Component, } from "react"
// import $ from "jquery"
import { Link } from "react-router-dom" 
//import _fetch from "."
import { __ } from "react-pe-utilities"
import GanreIcon from "../../GanreIcon"
import WPFestSettings from "../../utilities/WPFestSettings"

class FestMemberTwoSideCard extends Component 
{
  componentDidMount()  
  {
    //$(".share").socialLikes()
  }

  componentDidUpdate(nextProps) 
  {
    //$(".share").socialLikes()
  }
  get_form_fields = () =>
  {
    const getField = ( fest_form_fields, form_fields, i ) =>
    {
      if(!form_fields) return null
      switch( fest_form_fields.type )
      {
        case "url":
          return <a 
            href={form_fields} 
            target="_blank"
            className="btn btn-sm btn-primary ml-2 float-right"
            onClick={evt => {
              window.open(form_fields, '_blank')
              evt.preventDefault();
              evt.stopPropagation();
            }}
          >
            {__("Link")} {i + 1}
          </a>
        case "string":
        case "number":
        default:
          return form_fields
      }
    }
    //console.log(this.state.form_fields)
    return Array.isArray(this.props.festData.fest_form_fields)
      ?
      this.props.festData.fest_form_fields
        //.filter( form => !form.hide_in_card )
          .map((form , i ) => {
              return !form.hide_in_card 
                && this.props.form_fields[i] 
                && this.props.form_fields[i].filter( e => !!e ).length > 0
                ?
                <li 
                  className="list-group-item " 
                  style={{ minHeight: 64, overflow: "hidden", texOverflow: "ellipsis" }}
                  key={i}            
                >
                  <div className=" ">
                    <div className="">
                      { form.name }
                    </div>
                    <div>
                    {
                      Array(form.count).fill(1).map((formForm, ii) =>
                      {
                        const value = Array.isArray( this.props.form_fields ) 
                          && Array.isArray( this.props.form_fields[i] )
                            ?
                            getField(form, this.props.form_fields[i][ii], ii )
                            :
                            null
                        return <div key={ii} className="text-data">{value}</div>
                      })
                    }
                    </div>
                  </div>
                </li>
                :
                null
          })
      :
      null
  }
  render() 
  {
    //console.log(this.props)
    const { id, o, e, img, title, content, ganres, r, r2, festData } = this.props

    const _ganres = festData?.is_ganres
      ?
      ganres.map((ganre, index) => <GanreIcon ganre={ganre} key={ganre.id} />)
      :
      []
    const __ganres =  festData?.is_ganres
      ?
      ganres.map((ganre, index) => `<strong style='color:${ganre.color}'>${ganre.name}</strong>`)
      :
      []
    const _ganre = ganres[0]
    const gcolor = _ganre ? _ganre.color : "#111111"

    const url = [1, 2].filter((e) => e === WPFestSettings.status).length > 0
      ? `${this.props.route}/member/${id}`
      : `${this.props.route}/member/${id}`

    return (
      <div className="two-side-card-cont col-md-4 col-sm-6 m-0 p-0">      
          <Link className="two-side-card" to={url}>
            <div className="text-overlay">
              <div
                className="two-side-card-img h-100 w-100 "
                style={{
                  backgroundImage: `url(${img})`,
                  //borderColor: gcolor,
                }}
                data-mid={id}
                data-fmru_type="fmru_player"
                data-args={id}
              >
                <div className="card-id">
                  {o}
                </div>
              </div>
                
              <div
                className="two-side-card-title"
                title={title}
                style={{ 
                  overflow: "hidden",
                  textOverflow: "ellipsis", 
                  position: "relative",
                  display: "flex",
                }}
              >
                <h5 className="card-title m-0 d-flex w-100 justify-content-center align-items-center text-center" >
                  {title}
                </h5>
              </div>

              <div className="card-icons">
                {_ganres}
              </div>
            </div>
            <div className="purchase-button-container ">
              <ul className="list-group list-group-flush flex-grow-100 w-100 ">                           
                <li className="list-group-item hidden" style={{ height: 64, overflow: "hidden", texOverflow: "ellipsis" }}>
                  <span className="discr">
                    {content}
                  </span>
                </li>
                { this.get_form_fields() }
                <li className="list-group-item">
                  <span className="discr">
                    {__("Raiting:")}
                  </span>
                  <span className="data float-right">
                    {r2}
                  </span>
                </li>
                <li className="list-group-item">
                  <span className="discr">
                    {__("Expert count:")}
                  </span>
                  <span className="data selected float-right">
                    {e === 0 ? "-" : e}
                  </span>
                </li> 
              </ul>
              {
                this.props.isMine
                  ?
                  <div className=" mine-corner over-side ">
                    { __( "My project" ) }
                  </div>
                  :
                  null
              }
            </div>
            {
              this.props.isMine
                ?
                <div className="mine-corner d3d">
                  { __( "My project" ) }
                </div>
                :
                null
            }
          </Link>
      </div>
    )
  }
}
export default FestMemberTwoSideCard
