import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Card } from '@blueprintjs/core'
import { __ } from 'react-pe-utilities/dist'
import MyEditor from 'modules/pe-wpfest-admin-module/utilities/Editor'
import _fetch from 'modules/pe-wpfest-admin-module/api'

const LettersAndMeggages = props => { 
    const [ newProjectEmail, setNewProjectEmail ] = useState( props.data.new_project_email )
    const [ newProjectMessage, setNewProjectMessage ] = useState( props.data.new_project_message )
    console.log( newProjectEmail )
    const changeNewProjectEmail = text =>
    {
        setNewProjectEmail( text )
        _fetch("updte_site_messages", { new_project_email: text, blog_id: props.blog_id })
            .then( data => {
                console.log( data.lead.newProjectEmail ) 
                setNewProjectEmail( data.lead.newProjectEmail ) 
            })
    } 
    const changeNewProjectMessage = text =>
    {
        setNewProjectMessage( text )
        _fetch("updte_site_messages", { new_project_message: text, blog_id: props.blog_id })
            .then( data => {
                console.log( data.lead.newProjectMessage ) 
                setNewProjectMessage( data.lead.newProjectMessage ) 
            })
    } 
    return <div className='row'>
        <div className='col-12 '>
            <Card fill={"true"} className="p-4 mb-2" data-element="status">
                <div className="lead mb-1">{__("Email text for new Project registration")}</div>
                <MyEditor
                    text={newProjectEmail}
                    onChange={text => changeNewProjectEmail(text) }
				/>     
            </Card>
            <Card fill={"true"} className="p-4 mb-2" data-element="status">
                <div className="lead mb-1">{__("Modal message after new Project registration")}</div>
                <MyEditor
                    text={newProjectMessage}
                    onChange={text => changeNewProjectMessage(text) }
				/>     
            </Card>
        </div>
    </div>
}

LettersAndMeggages.propTypes = {}

export default LettersAndMeggages