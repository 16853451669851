import React, { Component, Fragment, } from "react"
import { compose } from "recompose"
import { withRouter, } from "react-router"
import _fetch from "."
import GanreIcon from "./GanreIcon"
import { __ } from "react-pe-utilities" 
import FieldInput from "react-pe-scalars/dist"
import  Style from 'style-it'
import { Button, ButtonGroup, Collapse, Intent } from "@blueprintjs/core"
import WPFestSettings from "./utilities/WPFestSettings"
import CorrectRaiting from "./core/CorrectRaiting"
import MemberRatingDisplay from "./core/MemberRatingDisplay"
import CorrectHoneycombs from "./core/CorrectHoneycombs"
import { isPartner } from "./utilities/isPartner"
import User from "./utilities/User"
import { NavLink } from "react-router-dom"

class FestMemberData extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...props,
      isTrackEditForms: false,
      isCollapseOpen: false,
      isEditForms: false
    }
  }
  componentDidUpdate( nextProps, nextState )
  { 
    if( nextProps.form_fields  !== this.state.form_fields )
    { 
      this.setState({ form_fields: nextProps.form_fields })
    }
    if( nextProps.isEditForms  !== this.state.isEditForms )
    { 
      this.setState({ isEditForms: nextProps.isEditForms })
    }
  }
  get_form_fields = () =>
  {
    const { is_owner, is_member } = this.props
    return Array.isArray(this.props.options.form_fields)
      ?
      this.props.options.form_fields 
        .filter((form, i) => {
          return !form.hide_in_page  || 
            ( 
              is_owner  ||
              is_member ||
              ( this.props.moderator?.id != User.id || this.props.moderator2?.id != User.id ) &&
              User.isRole( "Track_moderator" ) && User.id > 0
            ) ||
            User.isRole( "administrator" )
        })
        .map((form , i ) =>
        { 
          // console.log( form ) 
          switch( form.type )
          {
            case "url":
              return <li className="list-group-item transparent " key={i}>
                <div className="row m-0 align-items-center">
                  <div className="col-md-5 col-sm-4 text-md-right text-center text-secondary">
                    { __(form.name) }
                  </div>
                  <div className="col-md-7 col-sm-8 text-md-left text-center">  
                  { this.getLink(form, i) }  
                  </div>
                </div>
              </li>
            default:
              return <li className="list-group-item transparent " key={i}>
                <div className="row m-0 align-items-center">
                  <div className="col-md-5 col-sm-4 text-md-right text-center text-secondary">
                    {__(form.name)}
                  </div>
                  <div className="col-md-7 col-sm-8 text-md-left text-center forms-short">
                  { this.getForm( form, i ) }  
                  </div>
                </div>
              </li>
          }
        })
      : 
      null
  }
  getLink = (form, i) =>
  {
    return Array(form.count).fill(1).map((formForm, ii) =>
      {
        if(Array.isArray( this.state.form_fields ) && Array.isArray( this.state.form_fields[i] ))
        {
          const value =  this.state.form_fields[i][ii] 
          const links = value?.split(" http").map((l, iii) => {
            const link = (iii > 0 ? "http" + l : l).replaceAll("%2F", "/").replace("%2F", "/")
            const labels = l.split("/")
            const label = labels[ labels.length - 1 ] ? labels[ labels.length - 1 ] : __("Link")
            return <div key={link + iii} className="my-2">
              <a href={ link } target="_blank"  rel="noopener noreferrer">
                { label }
              </a>
            </div>
          })
          return links
        } 
        return null                 
      })
  }
  isOwner = () =>
  { 
    return this.props.user?.id &&  
      (
        this.props.user?.id === this.props?.owners?.leader?.id || 
        this.props.user?.id === this.props?.owners?.tutor?.id  
        // || this.props.user?.roles.filter( role => role === "Track_moderator").length > 0
      )
  }
  getMemberRuleLabel = () =>
  { 
    return this.isOwner() && !User.isRole(["Tutor", "Project_author"])
      ?
      ". waiting for confirmation of the registration from the track moderator."
      :
      ""
  }
  
  onEditFormToggle = () =>
  {
    this.props.onEditFormToggle( !this.state.isEditForms )
    this.setState({ isEditForms : !this.state.isEditForms })
  }
  getForm = ( form, i ) =>
  {  
    return Array(form.count).fill(1).map((formForm, ii) =>
    {
      if(Array.isArray( this.state.form_fields ) && Array.isArray( this.state.form_fields[i] ))
      {
        const value =  this.state.form_fields[i][ii] || ""
        if( !value ) return null
        switch(form.type)
        {
          case "select":
            return <Fragment key={value.toString() + ii}>         
              <FieldInput  
                visualization="festFormsFields"           
                vertical
                editable={ this.isOwner() && this.state.isEditForms }
                type={ "select" }
                value={ value }
                values={ (form.values || "").split("/").map(value => {
                  return {
                    _id: value, 
                    title: value
                  }
                }) }
                onChange={value => this.onFormChange(value, i, ii )}
              /> 
            </Fragment>
          case "radio":
            return <Fragment key={value.toString() + ii}>          
              <FieldInput  
                visualization="festFormsFields"           
                vertical
                editable={ this.isOwner() && this.state.isEditForms }
                type={ "tags" }
                value={ value }
                values={ (form.values || "").split("/") }
                onChange={value => this.onFormChange(value, i, ii )}
              />
            </Fragment>
          default:
            return <Fragment key={value.toString() + ii}>          
              <FieldInput            
                vertical
                editable={ this.isOwner() && this.state.isEditForms }
                type={ form.type }
                value={ value }
                values={ (form.values || "").split("/") }
                onChange={value => this.onFormChange(value, i, ii )}
              />
            </Fragment>
        }
        
      } 
      return null
    })
  }
  onChangeValue = (value, field ) =>
  {
    console.log(value, field )
    let state = {...this.state}
    state[field] = value
    this.setState(state)
  }
  correctRate = (rating) =>
  {
    // console.log(rating)
    const command = "correct_member_rate"
    const args = {
      member_id     : this.props.memberId,
      correct_rate  : rating,
      unixtime      : Date.now()
    }
    _fetch(
	    "get_fest_command", 
	    {...args, festId: WPFestSettings.festId, command },
	    WPFestSettings.serviceURL,
	    WPFestSettings.getToken( WPFestSettings.url ),
	    "wpfa",
	  )
	    .then((_data) => {
			  //const data = _data.data 
			  //console.log( _data ) 			 
	    })
  }
  onFormChange = (value, i, ii) =>
  {
    const form_fields = [...this.state.form_fields]
    form_fields[i][ii] = value
    this.setState( form_fields ) 
    // console.log("value:", value, "i:", i, "ii:", ii)
  }
  onChangeContent = text => this.setState( { content: text } ) 
  onCollapseToggle = () => this.setState({ isCollapseOpen: !this.state.isCollapseOpen })


  render() {
    // console.log( this.props )
    const cardIcons = this.props.ganres?.map((ganre, index) => <GanreIcon ganre={ganre} key={ganre.id} />)
 

    const articleElements = this.props.experts?.map((expert) => expert.display_name)
    const experts = articleElements?.length > 0 
      ? 
      (
        <li className="list-group-item transparent">
          <div className="row margin0">
            <div className="col-md-5 col-sm-4 text-md-right text-center text-secondary">
              {__("Experts, who put raiting:")}
            </div>
            <div className="col-md-7 col-sm-8 text-bold text-md-left text-center">
              <strong>{articleElements.join(", ")}</strong>
            </div>
          </div>
        </li>
      ) 
      : 
      ""
    const ganres = this.props.ganres?.map((ganre, index) => (
      <span className="ganre_title" style={{ backgroundColor: ganre.color }} key={`mg_${ganre.id}`}>
        {ganre.name}
      </span>
    )) 
    return Style.it(
      `
      .layout-label-vert
      {
        display:none;
      }
      .layout-data-vert
      {
        flex-direction: column;
      }`,
      <div className="row"> 
        <div className="col-12 flex-centered">
          <div
            className=" display-5 text-center "
            dangerouslySetInnerHTML={{ __html: this.props.title }}
          />
          <div className=" display-5 opacity_5 mx-4">|</div>
          <Button minimal onClick={this.onCollapseToggle}>
            <MemberRatingDisplay 
              correct_member_rate={this.props.correct_member_rate}
              countedRating={this.props.r2}
              className={"display-5"}
            />
          </Button>
        </div>
        <div className="col-12 flex-centered">
        {
          this.isOwner()
            ?
            <span className="my-project-label ">
            {
              __("My project")
            }
            { 
              __(this.getMemberRuleLabel())
            }
            </span>
            :
            isPartner(this.props.memberId, this.props.my_honeycombs)
              ?
              <span className="my-honeycombs-label ">
              {
                __("My honeycombs's partner")
              }
              </span>
              :
              null
        }
        </div>
        <div className="col-12 d-flex justify-content-center">
          <Button 
            minimal 
            onClick={this.onCollapseToggle} 
            icon={
              this.state.isCollapseOpen
                ?
                "chevron-up"
                :
                "chevron-down"
            }
          />
        </div>
        <Collapse isOpen={this.state.isCollapseOpen} className="w-100 mb-3">
          <div className="row my-4"> 
            <div className="col-md-3">
                <img src={ this.props.img } alt="" style={{ width:"100%", height:"auto" }} />
                
                {
                  this.isOwner()
                    ?
                    <>                    
                      <div className="mine-corner mine-corner2">
                        {__("My project")}
                      </div>
                      <div className="d-flex"> 
                        <NavLink 
                          className="bp3-button bp3-fill bp3-minimal min-height-25 rounded-0"
                          to={`${this.props.route}/member/${this.props.memberId}/dev`} 
                        >
                          <span className="small">{__( "Edit" )}</span>
                        </NavLink>
                      </div>
                    </>                   
                    :
                    isPartner(this.props.memberId, this.props.my_honeycombs)
                      ?
                      <div className="mine-honeycombs-corner mine-corner2">
                          {__("My honeycombs")}
                      </div>
                      :
                      null
                }
                
            </div>
            <div className="col-md-9">
              <ul className="list-group list-group-flush">
                <li className="list-group-item transparent">
                  <div className="row m-0 align-items-center">
                    <div className="col-md-5 col-sm-4 text-md-right text-center text-secondary">
                      {__("Designation")}
                    </div>
                    <div
                      className="col-md-7 col-sm-8 text-bold text-md-left text-center"
                      dangerouslySetInnerHTML={{ __html: this.props.title }}
                    />
                     
                  </div>
                </li>
                <li className="list-group-item transparent">
                  <div className="row m-0">
                    <div className="col-md-5 col-sm-4 text-md-right text-center text-secondary">
                      {__("Description")}
                    </div>
                    <div
                      className="col-md-7 col-sm-8 critery_cell1 text-md-left text-center"
                      dangerouslySetInnerHTML={{ __html: this.props.content }}
                    /> 
                  </div>
                </li> {/* */}
                {
                  this.props.track?.ID
                    ?
                    <li className="list-group-item transparent">
                      <div className="row m-0">
                        <div className="col-md-5 col-sm-4 text-md-right text-center text-secondary align-items-center d-flex justify-content-end">
                          <span className=" ">
                            {__("Track")}
                          </span> 
                        </div>
                        <div className="col-md-7 col-sm-8 critery_cell1 lead text-md-left text-center">
                          <div className="d-flex align-items-center ">
                            {
                              this.props.track.thumbnail
                                ?
                                <img src={this.props.track.thumbnail} alt="track" className='track-miniature'/>
                                :
                                null
                            }  
                            { this.props.track.post_title }
                          </div>
                        </div>
                      </div>
                    </li>
                    :
                    null
                }
                <li className="list-group-item transparent">
                  <div className="row m-0">
                    <div className="col-md-5 col-sm-4 text-md-right text-center text-secondary align-items-center d-flex justify-content-end">
                      <span className=" ">
                        {__("My honeycombs")}
                      </span> 
                    </div>
                    <div className="col-md-7 col-sm-8 critery_cell1 ">
                      <CorrectHoneycombs
                        honeycombs={this.props.honeycombs}
                        member_id={this.props.id}
                        all_honeycombs={this.props.all_honeycombs || []}
                        festid={this.props.festid}
                      />
                    </div>
                  </div>
                </li> 
                {
                  this.props.is_ganres
                    ?
                    <li className="list-group-item transparent">
                      <div className="row m-0">
                        <div className="col-md-5 col-sm-4 text-md-right text-center text-secondary">
                          <span className=" ">
                            {__("Ganres:")}
                          </span> 
                        </div>
                        <div className="col-md-7 col-sm-8 critery_cell1 lead text-md-left text-center">
                          {cardIcons}
                        </div>
                      </div>
                    </li>
                    :
                    null
                }
                {
                  WPFestSettings.fmru_evlbl_roles.filter( role => role === "Tutor" ).length > 0 &&
                    User.isRole( [ "Tutor" , "Track_moderator", "administrator" ] ) &&
                    this.props.owners.tutor?.id > 0 
                    ?
                    <li className="list-group-item transparent">
                      <div className="row m-0">
                        <div className="col-md-5 col-sm-4 text-md-right text-center text-secondary">
                          <span className=" ">
                            {__("Tutor")}:
                          </span> 
                        </div>
                        <div className="col-md-7 col-sm-8 critery_cell1 pl-4 text-md-left text-center">
                          { this.props.owners.tutor.display_name } | { this.props.owners.tutor.user_email }
                        </div>
                      </div>
                    </li>
                    :
                    null
                }
                {
                  WPFestSettings.fmru_evlbl_roles.filter( role => role === "Project_author" ).length > 0 &&
                    User.isRole( [ "Project_author" , "Track_moderator", "administrator" ] ) &&
                    this.props.owners.leader?.id > 0 
                    ?
                    <li className="list-group-item transparent">
                      <div className="row m-0">
                        <div className="col-md-5 col-sm-4 text-md-right text-center text-secondary">
                          <span className=" ">
                            {__("Project_author")}:
                          </span> 
                        </div>
                        <div className="col-md-7 col-sm-8 critery_cell1 pl-4 text-md-left text-center">
                          { this.props.owners.leader.display_name } | { this.props.owners.leader.user_email }
                        </div>
                      </div>
                    </li>
                    :
                    null
                }
                {/*
                <li className="list-group-item transparent ">
                  <div className="row m-0">
                    <div className="col-md-5 col-sm-4 text-md-right text-center text-secondary">
                      {__("Socials:")}
                    </div>
                    <div className="col-md-7 col-sm-8 critery_cell1 justify-end text-md-left text-center">
                      {socials}
                    </div>
                  </div>
                </li>
                */}
                { this.get_form_fields()}
                
                <li className="list-group-item transparent">
                  <div className="row m-0 align-items-center">
                    <div className="col-md-5 col-sm-4 text-md-right text-center text-secondary">
                      {__("Valuations:")}
                    </div>
                    <div  className="col-md-7 col-sm-8 d-flex critery_cell1 text-md-left text-center pr-4">
                      
                      <CorrectRaiting
                        countedRating={ this.props.r2 }
                        correct_member_rate={ this.props.correct_member_rate }
                        user={ this.props.user }
                        correctRate={ this.correctRate }
                      />
                      
                    </div>
                  </div>
                </li> 
                { experts } 
              </ul>
            </div>
          </div>  
        </Collapse>
      </div>
      
    )
  }
}
export default compose(
  withRouter,
)(FestMemberData)
