
const RussionRegionList = () =>
{
    return [
        "Алтайский край",
        "Амурская область",
        "Архангельская область",
        "Астраханская область",
        "Белгородская область",
        "Брянская область",
        "Владимирская область",
        "Волгоградская область",
        "Вологодская область",
        "Воронежская область",
        "Москва",
        "Санкт-Петербург",
        "Севастополь",
        "Донецкая Народная Республика",
        "Еврейская автономная область",
        "Забайкальский край",
        "Запорожская область",
        "Ивановская область",
        "Иные территории,включая город и космодром Байконур",
        "Иркутская область",
        "Кабардино-Балкарская Республика",
        "Калининградская область",
        "Калужская область",
        "Камчатский край",
        "Карачаево-Черкесская Республика",
        "Кемеровская область — Кузбасс",
        "Кировская область",
        "Костромская область",
        "Краснодарский край",
        "Красноярский край",
        "Курганская область",
        "Курская область",
        "Ленинградская область",
        "Липецкая область",
        "Луганская Народная Республика",
        "Магаданская область",
        "Московская область",
        "Мурманская область",
        "Ненецкий автономный округ",
        "Нижегородская область",
        "Новгородская область",
        "Новосибирская область",
        "Омская область",
        "Оренбургская область",
        "Орловская область",
        "Пензенская область",
        "Пермский край",
        "Приморский край",
        "Псковская область",
        "Республика Адыгея (Адыгея)",
        "Республика Алтай",
        "Республика Башкортостан",
        "Республика Бурятия",
        "Республика Дагестан",
        "Республика Ингушетия",
        "Республика Калмыкия",
        "Республика Карелия",
        "Республика Коми",
        "Республика Крым",
        "Республика Марий Эл",
        "Республика Мордовия",
        "Республика Саха (Якутия)",
        "Республика Северная Осетия — Алания",
        "Республика Татарстан (Татарстан)",
        "Республика Тыва",
        "Республика Хакасия",
        "Ростовская область",
        "Рязанская область",
        "Самарская область",
        "Саратовская область",
        "Сахалинская область",
        "Свердловская область",
        "Смоленская область",
        "Ставропольский край",
        "Тамбовская область",
        "Тверская область",
        "Томская область",
        "Тульская область",
        "Тюменская область",
        "Удмуртская Республика",
        "Ульяновская область",
        "Хабаровский край",
        "Ханты-Мансийский автономный округ — Югра",
        "Херсонская область",
        "Челябинская область",
        "Чеченская Республика",
        "Чувашская Республика — Чувашия",
        "Чукотский автономный округ",
        "Ямало-Ненецкий автономный округ",
        "Ярославская область"
    ]
}
export default RussionRegionList