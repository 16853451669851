import React, { Component } from "react"
import { compose } from "recompose" 
import { withRouter, } from "react-router"  
import { __ } from "react-pe-utilities" 
import CategoryList from "./CategoryList"
import CriteryUniqList from "../../CriteryUniqList" 
import ExpertDescriptions from "../../ExpertDescriptions"  
import Style from "style-it" 

class FestMemberRaiting extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...props,
    }
  }  

  render() {
    // console.log( this.props.milestone );
    // console.log( this.props.my_honeycombs)
    const articleElements = this.props.experts?.map((expert) => expert.display_name)
    const experts = articleElements?.length > 0 
      ?  
      <div className=" transparent">
        <div className="row mt-3">
          <div className="col-md-4 test-descr-title">
            {__("Experts, who put raiting:")}
          </div>
          <div className="col-md-8 ">
            <div className="font-weight-bold">{ articleElements.join(", ") }</div>
          </div>
        </div>
      </div> 
      : 
      "" 
    const categories = this.props.categories || []
    //console.log( "FestMemberRaiting.orphans:", this.props )
    const expert_descr = this.props.expert_descr || []
    const { uniqs } = this.props
    return Style.it(
      `
      input[type=radio].radio:not(checked) + label { 
          padding: 37px 40px 0 0; 
      }
      input[type=radio].radio:not(checked) + label:before, .my_button {
          width: 40px;
          height: 40px; 
      }
      `,
      <div className="row">
        <div className="col-12">
          {/* <div> { experts } </div> */}
          <CategoryList
            categories={categories}
            r2={this.props.r2}
            orphans={this.props.orphans}
            rating_type={this.props.rating_type}
            max_raiting={this.props.max_raiting || 5}
            user={this.props.user}
            member_id={this.props.id}
            milestone={this.props.milestone}
            my_honeycombs={this.props.my_honeycombs}
            is_owner={this.props.is_owner}
          />
          {
            this.props.is_experts_criteries
              ?
              <CriteryUniqList
                categories={categories}
                uniqs={uniqs}
                max_raiting={this.props.max_raiting || 5}
                user={this.props.user}
                member_id={this.props.id}
                milestone={this.props.milestone}
                my_honeycombs={this.props.my_honeycombs}
                is_owner={this.props.is_owner}
              />
              :
              null
          }
          <ExpertDescriptions
            data={expert_descr}
            member_id={this.props.id}
            denabled={this.props.denabled}
            user={this.props.user}
            milestone={this.props.milestone}
            is_owner={this.props.is_owner}
          />
        </div>
      </div>
    )
  }
}
export default compose(
  withRouter,
)(FestMemberRaiting)
