import React, {Component} from "react";  
import {  
	Icon, 
	Intent, 
	InputGroup,
	Card, 
	Elevation, 
	Button, 
	ButtonGroup, 
	TextArea, 
	Tooltip,
	Position,
	PopoverInteractionKind, 
	Popover
} from "@blueprintjs/core"; 
import { __ } from "react-pe-utilities/dist";
import _fetch from "modules/pe-wpfest-admin-module/api";
import { AppToaster } from "react-pe-useful/dist";

export default class PatternSiteCard extends Component
{
	constructor(props)
	{
		super(props);
		this.state = {
			title: props.title,
			domain: props.domain,
			isPopover:false,
			isDrop:true,
			search:[]
		}
	}
	componentDidMount() 
	{
		document.body.addEventListener('click', this.onMouseLeaveHandler);
	}
	 componentWillUnmount() 
	{
		document.body.removeEventListener('click', this.onMouseLeaveHandler);
	}	
	onMouseLeaveHandler = e =>
	{	
		if( e.target.closest("#form_dalog") ) return;
		this.setState({
			isDrop:false
		});
	}
	onTitle = evt =>
	{
		const value = evt.currentTarget.value;
		this.props.onTitle( value, this.props.num );
		//this.setState({title : value});
	}
	onDomain = evt =>
	{
		const value = evt.currentTarget.value;
		this.props.onDomain( value, this.props.num );
		//this.setState({domain : value});
	}
	onContent = evt =>
	{
		const value = evt.currentTarget.value;
		this.props.onContent( value, this.props.num );
		//this.setState({domain : value});
	}
	onSearch = () =>
	{
		console.log( this.props.domain );
		if( this.props.domain.length < 3 )
		{
			AppToaster.show({  
				intent: Intent.DANGER,
				icon: "disable", 
				timeout:10000,
				className: "px-4 py-4",
				message: __("Need string more 3 symbols.")				
			});
			return;
		}
		_fetch(
			"search_site",
			this.props.domain
			).then(data => {
				console.log(data);
				let search = [];
				data.sites.forEach(elem => {
					search.push({
						id : elem.blog_id,
						domain : elem.domain.substring( 0, elem.domain.indexOf(".") ),
						description : elem.description
					});					
				});
				console.log(search);
				this.setState({search, isDrop : !this.state.isDrop});
			}) 
	}
	onSearchResult = evt =>
	{
		const btn = evt.currentTarget;
		this.props.onDomain( btn.getAttribute("_text"), this.props.num );
		this.setState({ isDrop : false });
	}
	render()
	{
		const search = this.state.search.map(elem => <Button 
			key={elem.id}
			onClick={this.onSearchResult} 
			text={elem.domain}
			_text={elem.domain}
		/>);
		return <Card  interactive={true} elevation={Elevation.TWO} className=" w-100 p-4">
			<div className='lead w-100'>
				{__("Pattern-site title")}
			</div>
			<input 
				type="text"
				className="form-control"
				value={this.props.title}
				onChange={this.onTitle}
			/>
			<div className="lead w-100 mt-4">
				{__("Pattern-site domain")}
			</div>
			<div className="d-block position-relative">
				<InputGroup
					large={true}
					value={this.props.domain}
					onChange={this.onDomain}
					className="text-left"
					rightElement={<Tooltip
						 position={Position.TOP_RIGHT}
						 content={__("Search domain site")}
					>
						<Button
							intent={Intent.SUCCESS}
							minimal={true}
							icon="search"
							className="pt-fill"
							onClick={ this.onSearch }
						/>
					</Tooltip>}
				/>
				<div
					style={{
						position:"absolute", 
						left:0, 
						top:40, 
						width:"100%", 
						maxHeight:350, 
						minHeight:30,
						backgroundColor:"#CED9E0",
						overflowY:"auto",
						zIndex:3,
						padding:12
					}}
					id="form_dalog"
					className={this.state.isDrop ? "" : "hidden" }
				>
					<ButtonGroup vertical={true} fill={true} >
						{search}
					</ButtonGroup>
				</div>
			</div>
			<div className="input-group">
				
				
			</div>
			<small className="w-100 text-secondary">{__("Write pattern-site domain (only last level name)")}</small>
			<div className="lead w-100 mt-4">
				{__("Pattern-site description")}
			</div>
			<div className="w-100">
				<TextArea
					large={true}
					value={this.props.description}
					className={"w-100 pt-fill"}
					rows={8}
					onChange={this.onContent}
					style={{ minHeight: 220, textAlign:"left" }}
				/>
			</div>
			<div className="row">
				<div className="col-12 mt-4">
					<Popover 
						isOpen={this.state.isPopover}
						interactionKind={PopoverInteractionKind.HOVER_TARGET_ONLY}
						//hasBackdrop={true}
						content={
							<div className="mcard p-4">
								<div className="mb-3">{__("Your realy want remove this site from patterns?")}</div>
								<ButtonGroup>
									<Button 
										intent={Intent.DANGER}
										onClick={()=> {
											this.props.onRemove(this.props.num);
											this.setState({isPopover : !this.state.isPopover});
										}}
									>
										{__("Yes")}							
									</Button>
									<Button 
										intent={Intent.NONE}
										onClick={()=> {
											this.setState({isPopover : !this.state.isPopover});
										}}
									>
										{__("No")}
									</Button>
								</ButtonGroup>
							</div>
						}>
						<Button 
							icon="minus" 
							text={__("Remove")} 
							onClick={()=>this.setState({isPopover : !this.state.isPopover}) }
						/>
					</Popover >
				</div>
			</div>
		</Card>
	}
}