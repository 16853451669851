import { Card, Collapse } from "@blueprintjs/core"
import _fetch from "modules/pe-wpfest-admin-module/api"
import React, { useState } from "react"
import { useEffect } from "react"
import { Loading } from "react-pe-useful/dist"
import { __ } from "react-pe-utilities/dist"
import FormFields from "./FormFields"

const ExtendFields = props =>
{
    const[loading, changeLoading] = useState( true )
    const[form_fields, setFormFields] = useState( props.options?.form_fields || [] )
    // useEffect(() => {
    //     _fetch("site", {id: props.blog_id})
	// 		.then(data =>
	// 		{
	// 			console.log( data );
    //             let form_fields = Array.isArray(data.site?.options?.form_fields)
	// 					?
	// 					data.site.options.form_fields
	// 					:
	// 					[]
    //             setFormFields( form_fields )
    //             changeLoading(false)
    //         })
    // }, [])
    const onFormFieldChange = (form_fields ) =>
	{
        console.log( form_fields )
		onUpdate("form_fields", form_fields);
		setFormFields( form_fields )
	}
    const onUpdate = ( param, value ) =>
    {
        _fetch("updte_site", {param : param, value : value, blog_id: props.blog_id})
			.then(data =>
			{
				console.log( data ); 
			});
    }
    // if(loading) return <Loading />

    return <div className="p-4 mb-2">
        <div className="lead mb-1">{__("Every Member's fields")}</div>
        <div className="small my-2">
            {__("These fields will be displayed in each Project's account with their own values. These values can be edited by Project owners")}
            
        </div>
        
        <FormFields
            data={ form_fields }
            onChange={ onFormFieldChange } 
        /> 
    </div>
}
export default ExtendFields