import React, {Fragment,Component} from "react"; 
import { Icon, Tag, Intent, Tooltip, Card, Position, Popover, Button } from "@blueprintjs/core";
import Categories from "./Categories";
import ProjectForm from "./single/ProjectForm";
import ProjectBatchForm from "./single/ProjectBatchForm";
import { __ } from "react-pe-utilities/dist";
import _fetch from "modules/pe-wpfest-admin-module/api";
import IndexDB from "modules/pe-admin-module/views/utilities/IndexDB";
import MilestoneForm from "./single/MilestoneForm";

export default class Milestones extends Categories
{
	type = "milestone"
	componentMount()
	{
		this.init({
			form_component	: MilestoneForm,
			theadColor		: "#5a856b",
			meta			: { 
				festivalOptions	: this.props.options,
				order 			: this.state.post.length > 0 
					? 
					parseInt(this.state.post[this.state.post?.length - 1].order) + 1 
					: 
					0
			}
		})
	}
	componentWillMount()
	{ 
		
		IndexDB.find( 
				"peFestAdmin_mySites",
				"sites",
				"sites",
				this.props.blog_id
			)
				.then( sites =>
				{
					let fmru_ganres = sites.data.ganres
						?
						sites.data.ganres
						:
						[]
					let users = sites.data.users
						?
						sites.data.users
						:
						[] 
				})
	}
	row_edit()
	{
		return <div className="btn btn-link text-light btn-sm" onClick={this.onBitchCreation}>
			<Tooltip 
				intent={Intent.DANGER} 
				position={Position.TOP} 
				content={__(!this.state.is_batch_creation
					? 
					"batch creation" 
					: 
					"close"
				)} 
				key={"bcr"}
			>
				<Icon icon={!this.state.is_batch_creation ? "layout-grid" : "undo" } />
				
			</Tooltip>
		</div> 
	}
	rows()
	{
		let rw = super.rows().filter(elem => elem[0] != 'color');
		rw.splice( 3, 0, [
			'is_attachments_required',	
			<div style={{minWidth:40, display:"flex", justifyContent:"center"}} key={"is_attachments_required"}>
				<Tooltip 
					intent={Intent.DANGER}  
					position={Position.TOP} 
					content={__("Attachments are required")}
				>
					<Icon icon="paperclip" />
				</Tooltip>
			</div>, 
			50
		], [
			'is_auto_rait',	
			<div style={{minWidth:40, display:"flex", justifyContent:"center"}} key={"is_auto_rait"}>
				<Tooltip 
					intent={Intent.DANGER}  
					position={Position.TOP} 
					content={__("Owner may auto rait")}
				>
					<Icon icon="ungroup-objects" />
				</Tooltip>
			</div>, 
			50
		])
		return rw;
	}
	onRow(col, elem)
	{
		let txt = super.onRow(col, elem);
		switch(col)
		{
			 case "is_attachments_required":
				txt = <div className="text-center w-100">
					<Icon 
						icon={elem.is_attachments_required ? "tick" : "cross"} 
						intent={elem.is_attachments_required ? Intent.SUCCESS : Intent.DANGER} 
						iconSize={16} 
					/> 
				</div>
			 	break
			 case "is_auto_rait":
				txt = <div className="text-center w-100">
					<Icon 
						icon={elem.is_auto_rait ? "tick" : "cross"} 
						intent={elem.is_auto_rait ? Intent.SUCCESS : Intent.DANGER} 
						iconSize={16} 
					/> 
				</div>
			 	break
		}
		return txt;
	}
	getPostTitle = elem =>
	{
		const fields = Array.isArray( elem.form_fields ) && Array.isArray( this.props.options.form_fields )
			?	
			elem.form_fields.map((form, i) => { 
				if(!this.props.options.form_fields[i]) return
				let label = form ? form.join(" ") : ""
				switch(this.props.options.form_fields[i].type)
				{
					case "url":
						label = form.map((e, ii) => {
							return e ? <a key={ii} href={e} target="_blank" className="ml-2">{__("link")}</a> : null	
						})
						break;
					default:
						label = form ? form.join(" ") : ""
				}
				return this.props.options.form_fields[i].hide_in_projects
					?
					null
					:				
					<Tag 
						interactive={true}
						className="small"	 
						minimal
						key={i}
						style={{margin: 2, fontSize: 10, padding: "0 15px", minHeight: 12 }}
						round
					>
						{ this.props.options.form_fields[i].name }:<span className="title ml-2">{label}</span> 
					</Tag>
			})
			:
			null
		//console.log( elem )
		const tags = elem.fmru_ganre?.map(e => <Tag
					style={{backgroundColor : e.color, marginRight:2}}
					interactive={true}
					key={e.id}
				>
					{e.post_title}
				</Tag>);
		return <Fragment>
			<div style={{width: 240}} className="title">{elem.post_title}</div>
			<div style={{width: 240}}>{tags}</div>
			<div style={{width: 240}}>{fields}</div>
		</Fragment>
	}
	addThead()
	{
		return this.state.is_batch_creation ?
		<tr>
			<td colSpan={12}>
				<ProjectBatchForm 
					fmru_ganres = {this.meta.fmru_ganres}
					goClose = {this.onBitchCreation}
					onCreate = {this.onCreate}
					blog_id = {this.props.blog_id}
				/>
			</td>
		</tr> : null;
	}
	onApproved = (evt, elem) =>
	{
		//console.log( elem.id )
		let state = {} 
		const post = [...this.state.post].map((e, i)=> {
			if(e.id === elem.id)
			{
				elem.post_status = evt.currentTarget.checked ? "publish" : "pending"
				state = e

			}
			return e;
		})
		this.setState({post})
		_fetch( "update_object" , { 
			format 	: this.props.format, 
			termin 	: this.props.termin, 
			data	: state,
			id		: elem.id,
			blog_id	: this.props.blog_id
		})
			.then(data =>
			{
				console.log(data);
				// if(data.post) this.setState({ post:data.post })
			}) 
	}
	onCreate = data =>
	{
		this.setState({post:data});
	}
	onBitchCreation = () =>
	{
		this.setState({is_batch_creation:!this.state.is_batch_creation, isNew:false});
	} 
}