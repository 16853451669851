import React, { Component } from "react"
import { compose } from "recompose"
import { withRouter } from "react-router" 
import { Intent } from "@blueprintjs/core" 
import { __ } from "react-pe-utilities"  
import _fetch from "../.." 
import { AppToaster, Loading } from 'react-pe-useful'
import WPFestSettings from "../../utilities/WPFestSettings"

class Login extends Component {
	state = {
	  is: false,
	  url: this.props.url || "",
	  wpfestlogin: "",
	  wpfestpassword: "",
	  msg:"",
	  isLoading: false
	}

	render() 
	{
		// console.log(this.state.url)
		const msg = this.state.msg
			?
			<div 
				className="p-2 text-danger"
				style={{ width: 205 }}
				dangerouslySetInnerHTML={{__html: __(this.state.msg)}}
			/>
			:
			null
	  	return <div className="layout-center w-100">
			<div className="small">
				{__("Log in")}
			</div>
			{
				this.state.isLoading
					?
					<div className="flex-centered" style={{width:200, height:178}}>
						<Loading />
					</div>
					:
					<div>
						<div className="form-group justify-content-center d-flex flex-column">
							{
								this.props.hideUrl
									? 
									null
									:							(
									<input
										type="text"
										className="form-control input dark"
										placeholder={__("WP-Fest server URL")}
										value={this.state.url}
										onChange={this.onURL}
									/>
									)
							}
						</div>
						<div className="form-group justify-content-center d-flex flex-column">
							<input
							type="text"
							className="form-control input dark"
							placeholder={__("Login")}
							value={this.state.wpfestlogin}
							onChange={this.onLogin}
							/>
						</div>
						<div className="form-group justify-content-center d-flex flex-column">
							<input
							type="password"
							className="form-control input dark"
							placeholder={__("password")}
							value={this.state.wpfestpassword}
							onChange={this.onPassword}
							/>
						</div>
						{ msg }
						<div className="form-group justify-content-center d-flex flex-column">
							<div className="btn btn-success" onClick={this.onAdd}>
								{__("Log in")}
							</div>
						</div>
					</div>
			}
			
		</div>
	}

	onURL = (evt) => {
	  this.setState({ url: evt.currentTarget.value })
	}

	onLogin = (evt) => {
	  this.setState({ wpfestlogin: evt.currentTarget.value })
	}

	onPassword = (evt) => {
	  this.setState({ wpfestpassword: evt.currentTarget.value })
	}

	onAdd = () => {
	  this.setState({ msg : "", isLoading: true })
	  _fetch(
		"get_fest_command",
	    { 
			log: this.state.wpfestlogin, 
			psw: this.state.wpfestpassword, 
			remember: true, 
			command: "login", 
			festId: this.props.festid 
		}, 
		WPFestSettings.serviceURL,
		"",
		"wpfa"
	  )
	    .then((_data) => 
		{
		  const data = _data.data 
	      if (data.error) 
		  {
	        let d = data.error
	        if (data.error === "TypeError: Failed to fetch") {
	          d = "Unknown URL"
	        }
	        AppToaster.show({
	          intent: Intent.DANGER,
	          icon: "tick",
	          message: __(d),
	        })
			this.setState({isLoading: false})
	        return
	      }
		  if(!data.user.ID)
		  {
			this.setState({ msg : data.msg, isLoading: false })
			return
		  }
	      // console.log( data, this.state.url )
	      this.props.onLogin(data, this.state.url)
	      
	    },
	    (error) => {
	      console.log(error)
		  this.setState({isLoading: false})
	    })
	}

	onLogout = () => {
	  // User.deleteData();
	  this.setState({ is: !this.state.is })
	  // this.props.history.push("/" + this.props.root);
	}
}
export default compose(
  withRouter,
)(Login)
