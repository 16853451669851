import React, { Component } from "react"
import { compose } from "recompose"
import { Mutation, withApollo } from "react-apollo"
import { withRouter } from "react-router"
import { Button, Intent } from "@blueprintjs/core"
import { NavLink } from "react-router-dom"
import { __ } from "react-pe-utilities"
import { getAllRoutes, mutationToken, queryUserInfo } from "react-pe-layouts" 
import { AppToaster } from 'react-pe-useful'
import {UserContext} from "react-pe-layout-app"

class LoginForm extends Component
{
    constructor(props)
    {
        super(props)
        this.state = {login:"", password:"", showPass:false}
    }
    login = (e) => { this.setState({ login: e.currentTarget.value }) }
    passWord = (e) => { this.setState({ password: e.currentTarget.value }) }
    showPass = (e) => this.setState({showPass:true})
    hidePass = (e) => this.setState({showPass:false})
    render()
    {
        return <div className="col-12">
            <div className="p-5 w-100">
              <Mutation mutation={mutationToken()}>
                {(token, { data }) => (
                  <UserContext.Consumer>
                  {(
                      context => {
                            return <form onSubmit={(evt) => this.onLogin(evt, token, context)}>
                                <div className="form-group w-100">
                                    <label htmlFor="exampleInputEmail1">
                                        {__("Email")}
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={__("Email")}
                                        onChange={this.login}
                                    />
                                </div>
                                <div className="form-group position-relative">
                                    <label htmlFor="exampleInputPassword1">
                                        {__("Password")}
                                    </label>
                                    <input
                                        type={ this.state.showPass ? "text" : "password" }
                                        className="form-control"
                                        placeholder={__("Password")}
                                        onChange={this.passWord}
                                    />
                                    <Button 
                                        minimal 
                                        style={{
                                            position: "absolute",
                                            top: 30,
                                            right: 3,
                                        }}
                                        icon={ this.state.showPass ? "eye-open" : "eye-off" }
                                        onMouseDown={this.showPass}
                                        onMouseUp={this.hidePass}
                                    />
                                </div>
                                <div className="form-group form-check">
                                    <label
                                        className="form-check-label text-left"
                                        htmlFor="exampleCheck1"
                                    >
                                        {__("If you do not have a profile, then you can: ")}
                                        <NavLink
                                        to="/register"
                                        className="text-center"
                                        >
                                        {__("Register")}
                                        </NavLink>
                                    </label>
                                </div>
                                <div className="form-group form-check">
                                    <label
                                        className="form-check-label text-left"
                                        htmlFor="exampleCheck1"
                                    >
                                        {__("If you have forgotten your password: ")}
                                        <NavLink
                                        to="/remember"
                                        className="text-center"
                                        >
                                        {__("Remind password")}
                                        </NavLink>
                                    </label>
                                </div>
                                <input type="submit" className="btn btn-primary py-2 px-5 rounded-pill" value={__("Enter")} />
                            </form>
                    }
                  )}
                  </UserContext.Consumer>
                )}
              </Mutation>
            </div>
          </div>
    }

    onLogin = (evt, token, context) => 
    {
        evt.preventDefault()
        token({
            variables:
            {
                input: {
                    grant_type: "wp-ciba",
                    login: this.state.login || "",
                    password: this.state.password || "",
                },
            },
            update: (store, { data }) => {
                //console.log(data)
                if (data.token) 
                {
                    //console.log(data)
                    AppToaster.show({
                        intent: Intent.SUCCESS,
                        icon: "tick",
                        message: __("You enter by User"),
                    })
                    localStorage.setItem(
                        "token",
                        data.token.access_token,
                    )
                    // context.setUser( queryUserInfo() );
                    // this.props.history.replace("/")
                    this.props.client.query({ query: queryUserInfo(), fetchPolicy: 'network-only' })
                        .then(
                            result => {
                                //console.log(result)
                                context.setUser(result.data.userInfo);
                                if(this.props.onResult)
                                {
                                    this.props.onResult( this.props.onResultArgs )
                                }
                                else
                                {
                                    // при условии - переходим в Кабинет Редактора
                                    const routes = getAllRoutes().filter( route => route.component === "EditorCabinet" )
                                    const usl = typeof routes[0] !== "undefined" && 
                                        result.data.userInfo.roles
                                            .filter( role => role === "administrator" || role === "editor" )
                                                .length > 0
                                    
                                    this.props.history.replace( usl ? routes[0].route : '/' )
                                }                        
                            },
                        );

                } 
                else 
                {
                    AppToaster.show({
                        intent: Intent.DANGER,
                        icon: "tick",
                        message: __("You not enter by User"),
                    })
                }
            },
            refetchQueries: [{ query: queryUserInfo(), variables: {} }],
        })
    }
}

export default compose(
    withApollo,
    withRouter,
  )( LoginForm )