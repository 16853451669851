import React from 'react'
import PropTypes from 'prop-types'

const TrackLabel = props => { 
    return props.track
        ?
        <div className={props.className ? props.className : `fest-card-track-label`} >
            {
                props.track?.thumbnail 
                    ? 
                    <img src={props.track?.thumbnail} alt="track" className='track-miniature'/> 
                    :
                    null
            }
            { props.track?.post_title }
        </div>
        :
        null
}

TrackLabel.propTypes = {}

export default TrackLabel