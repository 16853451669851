import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link, useHistory } from 'react-router-dom'
import { __ } from 'react-pe-utilities/dist'
import __cases from 'pe-react-utilities/__cases'
import actions, { SET_TRACK_FILTER_ACTION } from 'modules/pe-fest-module/data/actions'
import { Button, Collapse, Dialog, Icon, Intent } from '@blueprintjs/core'
import Style from 'style-it'
import WPFestSettings from '../../utilities/WPFestSettings'

const FestTrackCard = props => {
    const history = useHistory()
    const[isOpen, setOpen] = useState(false)
    const[isVideoOpen, setVideoOpen] = useState(false)
    const[random,] = useState( Math.random() )
    const url = `${props.route}/members` 
    const onClick = () => {
      actions( SET_TRACK_FILTER_ACTION, { tracks: [ props.data.ID ] } )
      history.location.state = { tracks: [ props.data.ID ] }
      //console.log( history.location )
      history.push( url )
    }
    const onOpenClick = evt =>
    {
      evt.stopPropagation()
      evt.preventDefault()
      setOpen(true)
    }
    const onCloseClick = evt =>
    {
      evt.stopPropagation()
      evt.preventDefault()
      setOpen(false)
    }
    const playVideo = evt =>
    {
      evt.stopPropagation()
      evt.preventDefault()
      setVideoOpen(true)
    }
    const stopVideo = evt =>
    {
      evt.stopPropagation()
      evt.preventDefault()
      setVideoOpen(false)
    }
    const getAvatar = () =>
    {
      //console.log( props.data.illustration )
      return props.data.moderator?.id
        ?
        <div className='d-flex m-3 mt-auto pb-4'>
          <div className={ ` mr-3` } style={{ width:100, minWidth:100 }}>
            <div 
              className='my-avatar-cont'
              style={{
                backgroundImage:`url(${ WPFestSettings.serviceURL }/avatars/avatar${ props.data.moderator?.id }.jpg?${ random })`
              }}
            />
          </div> 
          <div>
            <div className=' lead text-secondary'>
              { __( "Track expert" ) }
            </div>
            <div className='title'>{ props.data.moderator?.display_name }</div>
            <Collapse isOpen={ isOpen } className={`w-100`}>
              <div className='mt-1' dangerouslySetInnerHTML={{ __html: props.data.moderator?.user_descr }} />
            </Collapse>            
          </div>
        </div> 
        :
        null
    }
    const trimmedContent = trimComtent( props.data.content.toString(), 20 )
    return Style.it(
      `
      .cclosed
      {
        
      }
      .cclosed .bp3-collapse-body
      {
        
      }
      .content-collapse
      {
        transition: height 400ms ease-in-out!important;
         
      }
      `,
      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
        <div className="card h-100 m-0 p-0 position-relative pointer hover" onClick={onClick}>
            <div
              className="card-img"
              style={{
                backgroundImage: props.data.illustration
                  ?
                  `url(${props.data.illustration})`
                  :
                    props.data.video_id 
                    ? 
                    `url(https://img.youtube.com/vi/${ props.data.video_id }/0.jpg)` 
                    : 
                    null,
                //borderColor: gcolor,
                height: 220,
                backgroundSize: "cover",
                backgroundRepeat:"no-repeat"
              }} 
            > 
            {
              props.data.video_id
                ?
                <>
                  <Button className='play-track-video-btn' minimal small onClick={playVideo}>
                    { __( "Play video" ) }
                  </Button>
                  <Dialog
                    isOpen={isVideoOpen}
                    onClose={stopVideo}
                  >
                    <div className='position-relative'>
                      <iframe 
                        width="800" 
                        height="450" 
                        src={`https://www.youtube.com/embed/${props.data.video_id}?autoplay=1`}
                        frameBorder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        allowFullScreen
                      />
                      <Button className='position-absolute top right px-3 py-2' onClick={stopVideo} intent={Intent.DANGER}>
                        <Icon iconSize={33} color="#FFFFFF" icon="cross" />
                      </Button>
                    </div>
                    
                  </Dialog>
                </>
                :
                null
            }
            </div> 
            <div
              className="card-header d-flex flex-centered flex-column"
              title={props.data.post_title}
              style={{
                height: 76,
                overflow: "hidden",
                padding: "0 1.25em",
                position: "relative",
                display: "table",
                paddingTop: 14
              }}
            >
                <h5 className="m-0 w-100 text-center " style={{ lineHeight: 1.0 }} >
                  {props.data.post_title}  
                </h5>
                <div>
                  <span className='mr-2'>{props.data.count}</span>
                  <span>{__cases(props.data.count, "projects")}</span>
                </div>
            </div>
                <div className='track-icon' style={{backgroundImage: `url(${props.data.thumbnail})`}} />
            <ul className="list-group list-group-flush flex-grow-100 ">
              <li className="list-group-item h-100 d-flex flex-column">              
                {
                  trimmedContent.isTrim || props.data.moderator?.user_descr.length > 0
                    ?
                    <>
                      <Collapse isOpen={!isOpen} className={` ${ !isOpen ? "cclosed" : "" } `}>
                        <div className="px-4 pt-4 pb-5 flex-centered flex-column content-collapse">
                          <div className="h-100" dangerouslySetInnerHTML={{__html: trimmedContent.content}} />
                          
                        </div>                                               
                      </Collapse>
                      <Collapse isOpen={isOpen} className={` ${ isOpen ? "cclosed" : "" } `}> 
                        <div className="px-4 pt-4 pb-5 flex-centered flex-column content-collapse">
                          <div 
                            className="h-100" 
                            dangerouslySetInnerHTML={{__html: props.data.content}} 
                          />
                        </div>
                      </Collapse>
                      { getAvatar() }  
                      <Button 
                        icon={ isOpen ? "chevron-up" : "chevron-down"}
                        className="position-absolute w-100 bottom"
                        minimal 
                        onClick={ isOpen ? onCloseClick : onOpenClick } 
                      />                    
                    </>
                    : 
                    <>
                      <div className="p-4">
                        { trimmedContent.content }
                      </div>
                      <div className="p-4 mt-auto" >
                        { getAvatar() }                                               
                      </div>
                    </>
                } 
              </li>
            </ul>
        </div>
    </div>
  )
}

FestTrackCard.propTypes = {}

export default FestTrackCard

const trimComtent = (content, wordsCount) =>
{
  const trims = content.split(" ")
  if(trims.length > wordsCount)
  {
    
    return {
      content: trims.slice( 0, wordsCount ).join(" ") + "...", 
      isTrim: true, 
      trimContent: trims.slice( wordsCount, -1 ).join(" ") 
    }
  }
  else
  {
    return {content, isTrim:false}
  }
}