import React, {Fragment,Component} from "react"; 
import {   
	Intent,  
	Card,  
	Button,  
	Slider,
	Dialog
} from "@blueprintjs/core";
import _fetch from "modules/pe-wpfest-admin-module/api";
import { __ } from "react-pe-utilities";
import { MediaChooser } from "react-pe-useful";
import CabinetView, { WPFEST_ADMIN_MY_ROUTE, WPFEST_ADMIN_STATUSES } from "../../CabinetView";
import { withRouter } from "react-router"
import { compose } from "recompose" 
import FieldInput from "react-pe-scalars/dist";
import IndexDB from "modules/pe-admin-module/views/utilities/IndexDB";

class Settings extends Component
{
	constructor(props)
	{
		super(props);
		// console.log(props)	
		this.state = 
		{
			status : this.props.data.status, 
			max_raiting : 0, 
			fmru_evlbl_roles :[],
			all_roles :[],
			is_experts_criteries : 0,
			is_independent_grade_criteries : 0,
			is_register : 0,

			is_ganres : 0,
			is_diaries : 0,
			member_card : "box",
			member_id : 1,
			card_count : 10,
			icon:"",
			icon_id:"",
			start_date:null,
			finish_date:null,
			default_image:"",
			default_image_id:"",
			default_member_thrumb:"",
			default_member_thrumb_id:"",
			isOnline:true
		}
	}
	componentWillMount() 
	{
		//console.log(this.props.blog_id);
		_fetch("site", {id:this.props.blog_id})
			.then(data =>
			{
				// console.log( data );
				if(!data.isError)
				{			
					if( data.site )	
					{
						this.setState({
							client_url : data.site.options.client_url,
							vk : data.site.options.vk,
							vk2 : data.site.options.vk2,
							link1 : data.site.options.link1,
							link2 : data.site.options.link2,
							email : data.site.options.email,
							email2 : data.site.options.email2,
							telegramm : data.site.options.telegramm,
							telegramm2 : data.site.options.telegramm2,
							phone : data.site.options.phone,
							phone2 : data.site.options.phone2,
							status : data.site.options.status,
							icon : data.site.icon,
							icon_id : data.site.icon_id,
							start_date : data.site.options.start_date,
							finish_date : data.site.options.finish_date,
							default_image : data.site.default_image,
							default_image_id : data.site.default_image_id,
							default_member_thrumb : data.site.default_member_thrumb,
							default_member_thrumb_id : data.site.default_member_thrumb_id,
							all_roles : data.site.roles, 
							member_card : data.site.options.member_card,
							member_id : data.site.options.member_id,
							rating_type : data.site.options.rating_type,
							display_project_result : data.site.options.display_project_result,
							fmru_evlbl_roles : data.site.options.fmru_evlbl_roles,
							max_raiting : data.site.options.max_raiting ? parseInt(data.site.options.max_raiting) : 3,
							is_owner_rate : data.site.options.is_owner_rate == "1",
							is_experts_criteries : data.site.options.is_experts_criteries == "1",
							is_independent_grade_criteries : data.site.options.is_independent_grade_criteries == "1",
							is_register : data.site.options.is_register == "1",
							is_register_project : data.site.options.is_register_project == "1",
							is_ganres : data.site.options.is_ganres == "1",
							is_diaries : data.site.options.is_diaries == "1",
							member_card_height : data.site.options.member_card_height,
							enabled_rules : data.site.options.enabled_rules == "1",
							card_count : data.site.options.member_per_page ? parseInt(data.site.options.member_per_page): 100
							
						});
					}	
					
					IndexDB.find(
						"peFestAdmin_mySites",
						"sites",
						"sites",
						this.props.blog_id
					)
						.then(res => { 
							IndexDB.save(
								{...res.data, ...data.site},
								"sites",
								this.props.blog_id,
								"sites",
								"peFestAdmin_mySites",
								1
							)
						})
				} 
				else
				{
					IndexDB.find(
						"peFestAdmin_mySites",
						"sites",
						"sites",
						this.props.blog_id
					)
						.then(res => {
							if(res.data)
							{				
								this.setState({
									client_url : data.site.options.client_url,
									link1 : data.site.options.link1,
									link2 : data.site.options.link2,
									vk : data.site.options.vk,
									vk2 : data.site.options.vk2,
									email : data.site.options.email,
									telegramm : data.site.options.telegramm,
									telegramm2 : data.site.options.telegramm2,
									phone : data.site.options.phone,
									phone2 : data.site.options.phone2,
									status : res.data.options.status,
									icon : res.data.icon,
									icon_id : res.data.icon_id,
									start_date : res.data.start_date,
									finish_date : res.data.finish_date,
									default_image : res.data.default_image,
									default_image_id : res.data.default_image_id,
									default_member_thrumb : res.data.default_member_thrumb,
									default_member_thrumb_id : res.data.default_member_thrumb_id,
									all_roles : res.data.roles, 
									member_card : res.data.options.member_card,
									member_id : res.data.options.member_id,
									rating_type : res.data.options.rating_type,
									display_project_result : res.data.options.display_project_result,
									fmru_evlbl_roles : res.data.options.fmru_evlbl_roles,
									max_raiting : res.data.options.max_raiting ? parseInt(res.data.options.max_raiting) : 3,
									is_experts_criteries : res.data.options.is_experts_criteries == "1",
									is_independent_grade_criteries : res.data.options.is_independent_grade_criteries == "1",
									is_register : res.data.options.is_register == "1",
									is_register_project : res.data.options.is_register_project == "1",
									is_ganres : res.data.options.is_ganres == "1",
									is_diaries : res.data.options.is_diaries == "1",
									member_card_height : res.data.options.member_card_height,
									enabled_rules : res.data.options.enabled_rules == "1",
									card_count : res.data.options.member_per_page ? parseInt(res.data.options.member_per_page): 100,
									isOnline: false
								});

							}
						})
				}
			});
	}

	render()
	{
		//console.log( this.state );
		const frm = WPFEST_ADMIN_STATUSES
			.map((elem, i) => <Fragment key={i}>
				<div className='mb-1'>					
					<input 
						type='radio' 
						name='status'
						className='radio_full' 
						id={'status' + i}
						value={elem}
						checked={this.state.status == i}
						onChange={this.onPhase}
						n={i}
					/>
					<label htmlFor={'status' + i} data-hint={__(elem)}>
						<div className={ this.state.status == i ? "cat_color_label ggreen" : "cat_color_label"} />
					</label>					
				</div>
			</Fragment>);
		const member_card = cardTypes().map((elem, i) => <Fragment key={i}>
				<div className='mb-1'>					
						<input 
							type='radio' 
							name='member_card'
							className='radio_full' 
							id={'member_card' + i}
							value={elem.type}
							checked={this.state.member_card == elem.type}
							onChange={this.onMemberCard}
							n={i}
						/>
						<label htmlFor={'member_card' + i} className="_radio_label_">
							<div className={ this.state.member_card == elem.type ? "cat_color_label ggreen" : "cat_color_label"} />
							<div className="label_title" >
								{__(elem.type)}
							</div>
							<div className="label_description" >
								{__(elem.description)}
							</div>
						</label>
					
				</div>
			</Fragment>);
		const roles = this.state.all_roles.map(elem =>
		{
			return <div key={elem} className="mb-1">
				<input					
					type="checkbox"
					className="_checkbox"
					value={elem}
					id={elem}
					onChange={this.onRole}
					checked={ this.state.fmru_evlbl_roles.filter(e=> elem==e).length > 0 }
				/>
				<label htmlFor={elem}>{__(elem)}</label>
			</div>
		})	
		return <div className='row'>
			<div className='col-12 '>
				<Card fill={"true"} interactive={true} className="p-4 mb-2" data-element="status">
					<div className="lead mb-1">{__("Status")}</div>
					{frm}
				</Card>
				<Card fill={"true"} interactive={true} className="p-4 mb-2" data-element="client_url">
					<div className="lead mb-1">{__("Oficial  Festival's URL")}</div>  
					<FieldInput
						title={__("")}						
						vertical
						value={this.state.client_url}
						commentary={__('Goto to main page of Festival and copy URL. This data need for correct auto e-mails')}
						type="url" 
						on={this.onClientUrl}
						onChange={this.onClientUrl}
					/>
				</Card>
				<Card fill={"true"} interactive={true} className="p-4 mb-2" data-element="start_date, finish_date">
					<div className="row">
						<div className="col-md-6 col-12">
							<div className="lead mb-1">{__("Start date")}</div> 
							<FieldInput
								title={__("")}						
								vertical
								value={this.state.start_date}
								type="date" 
								on={this.onStartDate}
								onChange={this.onStartDate}
							/>
						</div>
						<div className="col-md-6 col-12">
							<div className="lead mb-1">{__("Finish date")}</div> 
							<FieldInput
								title={__("")}						
								vertical
								value={this.state.finish_date}
								type="date" 
								on={this.onFinishDate}
								onChange={this.onFinishDate}
							/>
						</div>
					</div>
				</Card>
				<Card fill={"true"} interactive={true} className="p-4 mb-2" data-element="vk,vk2,email,email2,phone,phone2"> 
					<div className="row">
						<div className="col-12">
							<div className="lead mb-1">{__("Contacts")}</div> 
							<FieldInput
								title={__("Link")}	 
								value={this.state.link1}
								type="url" 
								on={value => this.onValueChange("link1", value)}
								onChange={value => this.onValueChange("link1", value)}
							/>
							<FieldInput
								title={__("Link alternative")}	 
								value={this.state.link2}
								type="url" 
								on={value => this.onValueChange("link2", value)}
								onChange={value => this.onValueChange("link2", value)}
							/>
							<FieldInput
								title={__("VKontakte")}	 
								value={this.state.vk}
								type="url" 
								on={value => this.onValueChange("vk", value)}
								onChange={value => this.onValueChange("vk", value)}
							/>
							<FieldInput
								title={__("VKontakte alternative")}	 
								value={this.state.vk2}
								type="url" 
								on={value => this.onValueChange("vk2", value)}
								onChange={value => this.onValueChange("vk2", value)}
							/>
							<FieldInput
								title={__("E-mail")}	 
								value={this.state.email}
								type="email" 
								on={value => this.onValueChange("email", value)}
								onChange={value => this.onValueChange("email", value)}
							/>
							<FieldInput
								title={__("E-mail alternative")} 
								value={this.state.email2}
								type="email" 
								on={value => this.onValueChange("email2", value)}
								onChange={value => this.onValueChange("email2", value)}
							/>
							<FieldInput
								title={__("Telegramm")} 
								value={this.state.telegramm}
								type="url" 
								on={value => this.onValueChange("telegramm", value)}
								onChange={value => this.onValueChange("telegramm", value)}
							/>
							<FieldInput
								title={__("Telegramm alternative")} 
								value={this.state.telegramm2}
								type="url" 
								on={value => this.onValueChange("telegramm2", value)}
								onChange={value => this.onValueChange("telegramm2", value)}
							/>
							<FieldInput
                				visualization="festFormsFields" 
								title={__("Phone")}	 
								value={this.state.phone}
								className={` w-100 input dark form-control `}
								editable
								type="phone" 
								on={value => this.onValueChange("phone", value)}
								onChange={value => this.onValueChange("phone", value)}
							/>
							<FieldInput
                				visualization="festFormsFields" 
								title={__("Phone alternative")} 
								value={this.state.phone2}
								className={` w-100 input dark form-control `}
								editable
								type="phone" 
								on={value => this.onValueChange("phone2", value)}
								onChange={value => this.onValueChange("phone2", value)}
							/>
						</div>
					</div>
				</Card>
				<Card fill={"true"} interactive={true} className="p-4 mb-2" data-element="icon,default_member_thrumb">
					<div className="lead mb-1">{__("Icons")}</div>
					<div className="row">
						<div className="col-md-6 col-12" data-element="icon">
							<MediaChooser
								url={this.state.icon}
								prefix={"icon"}
								height={60}
								padding={0}
								onChange={this.onIcon}
								id={this.state.icon_id}
								isURLHide={true}
								isDescr={false}
								hideLib
							/>
							<div className="small mt-1">{__("Logotype")}</div>
						</div>
						<div className="col-md-6 col-12" data-element="default_member_thrumb">
							<MediaChooser
								url={this.state.default_member_thrumb}
								prefix={"deficon"}
								height={60}
								padding={0}
								onChange={this.onDefIcon}
								id={this.state.default_member_thrumb_id}
								isURLHide={true}
								isDescr={false}
								hideLib
							/>
							<div className="small mt-1">{__("Default Project's icon")}</div>
						</div>
					</div>
				</Card>	 
				<Card fill={"true"} interactive={true} className="p-4 mb-2" data-element="rating_type">
					<div className="lead mb-1">{__("Rating method")}</div>
					<FieldInput
						title={__("")}						
						vertical
						value={this.state.rating_type}
						type="radio"
						values={[
							{
								_id : "independent_grade",
								title: "Independent grade",
								commentary: "The expert assigns a score by pressing the button with the desired numerical value"
							},
							{
								_id : "distribution_procentage",
								title: "Distribution procentage",
								commentary: "The Expert assesses by manipulating the sliders, the sum of the values of which does not exceed 100"
							},
							{
								_id : "each_category",
								title: "for each category",
								commentary: ""
							}
						]}
						on={this.onRatingMethodType}
						onChange={this.onRatingMethodType}
					/>
				</Card>				
				<Card fill={"true"} interactive={true} className="p-4 mb-2" data-element="display_project_result">
					<div className="lead mb-1">{__("Display Project's result method")}</div>
					<FieldInput					
						vertical
						value={this.state.display_project_result}
						type="radio"
						values={[
							{
								_id : "average",
								title: "Average rate"
							},
							{
								_id : "summae",
								title: "Summae of average of all ctireties rates"
							}
						]}
						default="average"
						on={this.onDisplayProjectResult}
						onChange={this.onDisplayProjectResult}
					/>
				</Card>
				{
					this.state.rating_type === "independent_grade" || this.state.rating_type === "each_category"
						?
						<Card fill={"true"} interactive={true} className="p-4 mb-2" data-element="max_raiting">
							<div className="lead mb-1">{__("Max raiting setting for independing raiting")}</div>
							<Slider
								min={0}
								max={10}
								stepSize={1}
								labelStepSize={1}
								onChange={this.onMaxRating}
								value={this.state.max_raiting}
								vertical={false}
							/>
						</Card>
						:
						null
				}
				{
					this.state.rating_type === "independent_grade" || this.state.rating_type === "each_category"
						?
						<Card fill={"true"} interactive={true} className="p-4 mb-2" data-element="is_independent_grade_criteries">
							<div className="lead mb-1">{__("Maximize grade each critery")}</div>
							<input
								type="checkbox"
								className="_checkbox"
								checked={this.state.is_independent_grade_criteries}
								onChange={this.onIsIndependedGradeCriteries}
								id="is_independent_grade_criteries"
							/>
							<label htmlFor="is_independent_grade_criteries"> </label>
						</Card>
						:
						null
				}
				<Card fill={"true"} interactive={true} className="p-4 mb-2" data-element="is_owner_rate">
					<div className="lead mb-1">{__("Expert can evaluate projects of which he is the owner")}</div>
					<input
						type="checkbox"
						className="_checkbox"
						checked={this.state.is_owner_rate || false}
						onChange={this.onIsOwnerRate}
						id="is_owner_rate"
					/>
					<label htmlFor="is_owner_rate"> </label>
				</Card>
				<Card fill={"true"} interactive={true} className="p-4 mb-2" data-element="card_count">
					<div className="lead mb-1">{__("Count of Member's Cards per screen")}</div>
					 <Slider
						min={0}
						max={200}
						stepSize={1}
						labelStepSize={10}
						onChange={this.onCardCount}
						value={this.state.card_count}
						vertical={false}
					/>
				</Card>
				<Card fill={"true"} interactive={true} className="p-4 mb-2" data-element="is_experts_criteries">
					<div className="lead mb-1">{__("Experts's criteries enabled")}</div>
					<input
						type="checkbox"
						className="_checkbox"
						checked={this.state.is_experts_criteries}
						onChange={this.onIsExpertCriteries}
						id="is_experts_criteries"
					/>
					<label htmlFor="is_experts_criteries"> </label>
				</Card>
				<Card fill={"true"} interactive={true} className="p-4 mb-2" data-element="is_ganres">
					<div className="lead mb-1">{__("Is ganres exists")}</div>
					 <input
						type="checkbox"
						className="_checkbox"
						checked={this.state.is_ganres}
						onChange={this.onIsGanres}
						id="is_ganres"
					/>
					<label htmlFor="is_ganres"></label>
				</Card>
				<Card fill={"true"} interactive={true} className="p-4 mb-2" data-element="is_register">
					<div className="lead mb-1">{__("Visitors can create accounts")}</div>
					 <input
						type="checkbox"
						className="_checkbox"
						checked={this.state.is_register}
						onChange={this.onIsRegister}
						id="is_register"
					/>
					<label htmlFor="is_register"></label>
				</Card>
				<Card fill={"true"} interactive={true} className="p-4 mb-2" data-element="is_register">
					<div className="lead mb-1">{__("Visitors can create new Project")}</div>
					 <input
						type="checkbox"
						className="_checkbox"
						checked={this.state.is_register_project}
						onChange={this.onIsRegisterProject}
						id="is_register_project"
					/>
					<label htmlFor="is_register_project"></label>
				</Card>
				<Card fill={"true"} interactive={true} className="p-4 mb-2" data-element="is_diaries">
					<div className="lead mb-1">{__("Project authors keep a diary")}</div>
					 <input
						type="checkbox"
						className="_checkbox"
						checked={this.state.is_diaries}
						onChange={this.onIsDiareis}
						value="1"
						id="is_diaries"
					/>
					<label htmlFor="is_diaries"></label>
				</Card> 
				<Card fill={"true"} interactive={true} className="p-4 mb-2" data-element="member_card">
					<div className="lead mb-2">{__("Default Member's card")}</div>
					{member_card}
				</Card>
				{
					this.state.member_card === "card"
						?
						<Card fill={"true"} interactive={true} className="p-4 mb-2" data-element="member_card_height">
							<div className="lead mb-1">{__("Height of card's thumbnail")}</div>							
							<Slider
								min={0}
								max={400}
								stepSize={1}
								labelStepSize={50}
								onChange={this.onMemberCardHeight}
								onRelease={this.onMemberCardHeightRelease}
								// labelRenderer={this.onMemberCardHeight}
								value={ this.state.member_card_height || 100 }
								vertical={ false }
							/>  
						</Card>
						:
						null
				}
				<Card fill={"true"} interactive={true} className="p-4 mb-2" data-element="fmru_evlbl_roles">
					<div className="lead mb-2">{__("Evalble special roles")}</div>
					{roles}
				</Card>	
				<Card fill={"true"} interactive={true} className="p-4 mb-2" data-element="member_id">
					<div className="lead mb-1">{__("Next new Member's order")}</div>
					 <input
						type="number"
						className="form-control input dark"
						value={this.state.member_id}
						onChange={this.onMemberID} 
					/>
				</Card>
			</div>
			
		</div>;
	}
	onUpdate = (param, value) =>
	{
		// console.log(param, value);
		_fetch("updte_site", {param : param, value : value, blog_id:this.props.blog_id})
			.then(data =>
			{
				//console.log( data );
				let state = Object.assign({}, this.state);
				state[param] = value;				
				this.setState(state);
			});
	}
	onRole = evt =>
	{
		const checked = evt.currentTarget.checked;
		const rol = evt.currentTarget.value;
		let fmru_evlbl_roles = this.state.fmru_evlbl_roles.slice(0);		
		if(checked)
		{
			fmru_evlbl_roles.push(rol);
		}
		else
		{
			fmru_evlbl_roles = fmru_evlbl_roles.filter(e => e != rol);
		}
		console.log(fmru_evlbl_roles);
		this.onUpdate("fmru_evlbl_roles", fmru_evlbl_roles);
		//this.setState({ status : n });
	}
	onPhase = evt =>
	{
		const n = parseInt(evt.currentTarget.getAttribute("n"));
		this.onUpdate("status", n);
		//this.setState({ status : n });
	}
	onClientUrl = data =>
	{ 
		this.onUpdate("client_url", data);
		this.setState({ client_url : data });
	}
	onStartDate = date =>
	{ 
		this.onUpdate("start_date", date);
		this.setState({ start_date : date });
	}
	onFinishDate = date =>
	{ 
		this.onUpdate("finish_date", date);
		this.setState({ finish_date : date });
	}
	onMemberID = evt =>
	{
		const value = evt.currentTarget.value;
		this.onUpdate("member_id", value);
		this.setState({ member_id : value });
	}
	onMemberCard = evt =>
	{
		const value = evt.currentTarget.value;
		this.onUpdate("member_card", value);
		this.setState({ member_card : value });
	}
	onMaxRating = n =>
	{
		this.onUpdate("max_raiting", n);
		this.setState({max_raiting:n});
	}
	onCardCount = n =>
	{
		this.onUpdate("member_per_page", n);
		this.setState({card_count:n});
	}
	onRatingMethodType = rating_type =>
	{
		this.onUpdate("rating_type", rating_type);
		this.setState({ rating_type });
	}
	onDisplayProjectResult = display_project_result =>
	{
		this.onUpdate("display_project_result", display_project_result);
		this.setState({ display_project_result });
	}
	onIcon = ( url, file, ID ) =>
	{
		console.log( url, file, ID );
		this.onUpdate( "logotype_0", { url:url, name:file.name || "festival_icon.jpg" } );
		this.setState({icon:url});
	}
	onDefaultImage = ( url, file, ID ) =>
	{
		console.log( url, file, ID );
		this.onUpdate( "default_image", { url: url, name: file.name || "festival_icon.jpg" } );
		this.setState({default_image: url});
	}
	onDefIcon = ( url, file, ID ) =>
	{
		this.onUpdate( "default_member_thrumb", { url:url, name:file.name } );
		this.setState({default_member_thrumb:url});
	} 
	onIsExpertCriteries = evt =>
	{
		const val = evt.currentTarget.checked ? 1 : 0;
		this.onUpdate("is_experts_criteries", val);
		this.setState({is_experts_criteries : val});
	}
	onIsIndependedGradeCriteries = evt =>
	{
		const val = evt.currentTarget.checked ? 1 : 0;
		this.onUpdate("is_independent_grade_criteries", val);
		this.setState({is_independent_grade_criteries : val});
	}
	onIsOwnerRate = evt =>
	{
		const val = evt.currentTarget.checked ? 1 : 0;
		this.onUpdate("is_owner_rate", val);
		this.setState({is_owner_rate : val});
	}
	onMemberCardHeight = value => { 
		this.setState({ member_card_height : value })
	}
	onMemberCardHeightRelease = value => {
		this.onUpdate( "member_card_height", value );
		this.setState({ member_card_height : value })
	}
	onIsRegister = evt =>
	{
		const val = evt.currentTarget.checked ? 1 : 0;
		this.onUpdate("is_register", val);
		this.setState({is_register : val});
	}
	onIsRegisterProject = evt =>
	{
		const val = evt.currentTarget.checked ? 1 : 0;
		this.onUpdate("is_register_project", val);
		this.setState({is_register_project : val});
	}
	onIsGanres = evt =>
	{
		const val = evt.currentTarget.checked ? 1 : 0;
		this.onUpdate("is_ganres", val);
		this.setState({is_ganres : val});
	}
	onIsDiareis = evt =>
	{
		console.log("onIsDiareis");
		const val = evt.currentTarget.checked ? 1 : 0;
		this.onUpdate("is_diaries", val);
		this.setState({is_diaries : val});
	}
	onValueChange = (field, value) =>
	{
		let state = {...this.state}
		state[field] = value
		this.setState(state)
		this.onUpdate(field, value)
	}
}

export default  compose(
	withRouter, 
)(Settings)

export const cardTypes = () =>
{
	return [
		{
			type:'card',
			description: 'Medium duty design. You want projects to be smartly presented but compact enough'
		},
		{
			type: 'twosidecard',
			description: 'Choose this design if Projects have image icons and many meaningful additional fields'
		},
		{
			type: 'stroke',
			description: 'Compact and informational design'
		},
		{
			type: 'siple_stroke',
			description: 'Only title stroke'
		},
		{
			type: 'box',
			description: 'You need maximum anonymity of projects so that experts and viewers have minimal attachment'
		}
	]
}