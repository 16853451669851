import { Button, Dialog, Icon, Intent } from "@blueprintjs/core";
import _fetch from "modules/pe-wpfest-admin-module/api";
import React from "react"
import { Component } from "react"; 
import { __ } from "react-pe-utilities/dist";

class MergeLocal extends Component
{
    constructor(props)
    {
        super(props)
        this.state = {
            isMergeOpen : false
        }
    }
    onMergeOpen = bool =>
    {
        this.setState({isMergeOpen:bool})
    }
    onstartLoad = () =>
    {
        _fetch( "merge_local" )
			.then(data => 
			{
				console.log(data);
                if(data.isError)
                {
                    return 
                }
				if(data.is_login)
				{
                    
				}
			});

    }
    render()
    {
        return <>
            <Button
                className="text-light hint hint--bottom"
                large
                minimal
                onClick={() => this.onMergeOpen(true)}
                data-hint={__("Merge data to local")} 
            >
                <Icon icon="briefcase" color="#FFFFFF"/>
            </Button>
            <Dialog
                isOpen={this.state.isMergeOpen}
                onClose={() => this.onMergeOpen(false)}
                className="little2"
            >
                <div className="p-5">
                    <div 
                        className="mb-5 text-center " 
                        dangerouslySetInnerHTML={{__html : __("You will be able to manage the Festivals without an internet connection. Changes you make while offline will be merged the next time you log on. To get this feature, copy your data to the local database of this browser.Keep in mind that storing the dump will require a certain amount of space in the permanent memory of this device.")}}
                    /> 
                    <Button 
                        intent={Intent.DANGER} 
                        large
                        fill
                        className="text-center p-3"
                        onClick={ this.onstartLoad }
                    >
                        {__("Copy my data to local")}
                    </Button>
                </div>
            </Dialog>
        </>
    }
}

export default MergeLocal