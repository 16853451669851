import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import FieldForm from './kraevedRegister/FieldForm'
import { __ } from 'react-pe-utilities/dist'

const KraefestRegisterForm = props => {
    const onExtendFetchParams = (value, field, n, i) =>
    { 
        props.onExtendFetchParams( value, field, n, i )
    }
    //console.log(props)
    switch ( props.role )
    { 
        case "Tutor": 
        case "Project_author":
            if( !Array.isArray( props.options?.form_fields ) ) return null
            // console.log( props.options?.form_fields )
            const form_fields = props.options.form_fields.map((field, n) => 
            {                
                return <FieldForm
                    key={ field.yandex_form_field + Math.random() + "_" + n }
                    n={n}
                    { ...field } 
                    value={ props.isNew ? [ ] : props.form_fields[n] } 
                    onChange={onExtendFetchParams}
                /> 
            })  
            return  <> 
                { form_fields }
            </>
        case "Expert":
        default:
            return null
    }
    
     
}

KraefestRegisterForm.propTypes = {}

export default KraefestRegisterForm