import React, {Component, Fragment} from "react";  
import { Icon, Tag, Intent, Tooltip, Card, FormGroup, InputGroup, NumericInput, TextArea, Button } from "@blueprintjs/core";
import _fetch from "modules/pe-wpfest-admin-module/api";
import { __ } from "react-pe-utilities";
import MyEditor from "modules/pe-wpfest-admin-module/utilities/Editor";
import IndexDB from "modules/pe-admin-module/views/utilities/IndexDB";
import { AppToaster } from "react-pe-useful/dist";
 
export default class Content extends Component
{
	state ={
		name		: '',
		url			: '',
		descr		: '',
		description	: '',
		domain_content : '',
		blog_id		: -1,
		author		: {},
		prototyper	: {},
		isLoad		: false
	};
	componentWillMount()
	{
		//console.log(this.props );
		_fetch("lead_fest_content", {id:this.props.blog_id})
			.then(d =>
			{	
				const data = {...d}
				// console.log(data.lead);
				if(!data.isError)
				{	
					this.setState({
						name		: data.lead.name,
						url			: data.lead.url,
						domain_type	: data.lead.domain_type,
						domain_type_id		: data.lead.domain_type_id,
						domain_description	: data.lead.domain_description,
						descr		: data.lead.descr,
						description	: data.lead.description,
						domain_content		: data.lead.domain_content,
						blog_id		: data.lead.id,
						author		: data.lead.author || {},
						prototyper	: data.lead.prototyper || {},
						isLoad		: true
					});
					IndexDB.find(
						"peFestAdmin_mySites",
						"sites",
						"sites",
						this.props.blog_id
					).then(res => { 
						if(res)
						{
							IndexDB.save(
								{...(res?.data || {} ), ...data.lead},
								"sites",
								this.props.blog_id,
								"sites",
								"peFestAdmin_mySites",
								1
							)
						}
						
					})
				}
				else
				{
					// console.log("no content", this.props )
					IndexDB.find(
						"peFestAdmin_mySites",
						"sites",
						"sites",
						this.props.blog_id
					)
						.then(res => {
							if(res.data)
							{
								this.setState({
									name				: res.data.name,
									url					: res.data.url,
									domain_type			: res.data.domain_type,
									domain_type_id		: res.data.domain_type_id,
									domain_description	: res.data.domain_description,
									descr				: res.data.descr,
									description			: res.data.description,
									domain_content		: res.data.domain_content || "",
									blog_id				: res.data.id,
									author				: res.dataauthor || {},
									prototyper			: res.dataprototyper || {},
									isLoad				: true
								})
							}
						})
						.catch( (err) => {
							// handle error 
							AppToaster.show({
								intent:Intent.DANGER,
								message:__("Error load restored presets. No internet connection.")
							}) 
						})
				}				
			});
	}
	onName = evt =>
	{
		this.setState({name : evt.currentTarget.value });
		this.onUpdate()
	}
	onDescrEdit = text =>
	{
		//console.log(text);
		this.setState({domain_content : text });
		this.onUpdate()
	}
	onDescr = evt =>
	{
		this.setState({descr : evt.currentTarget.value });
		this.onUpdate()
	}
	onDescription = evt =>
	{
		this.setState({description : evt.currentTarget.value });
		this.onUpdate()
	}
	onUpdate = () =>
	{
		setTimeout(() => {
			console.log( this.props.blog_id, this.state )
			_fetch("lead_fest_content_update", {id:this.props.blog_id, data:this.state})
				.then(data =>
				{		
					console.log(data);	
				});
		}, 200)
		
	}
	render()
	{
		return !this.state.isLoad ? null : <div className='row'>
			<div className='col-12 mb-5'>
				<Card fill={"true"} interactive={true} className="p-4 mb-2">
					<div className="lead mb-1">{__("Parameters")}</div>
					<div className=" mt-3 row">
						<div className="col-md-2 small">{__("Model:")} </div>
						<div className="col-md-10 font-weight-bold text-danger">
							{this.state.domain_type}
						</div>
					</div>
					<div className=" mb-3 row">
						<div className="col-md-2 small">{__("Description:")} </div>
						<div className="col-md-10 text-danger small">
							{this.state.domain_description}
						</div>
					</div>
					<FormGroup
						label={__("Title")}
						helperText={__("insert title by any symbols")}
						className="p-2"
					>
						<InputGroup  
							fill={true}  
							large={true}  
							value={this.state.name}
							onChange={ this.onName}
						/>
					</FormGroup>
					
					<FormGroup
						label={__("Undertitle")}
						helperText={__("insert undertitle by any symbols")}
						className="p-2"
					>
						<InputGroup 
							className="text-secondary"								
							fill={true}  
							large={false}  
							value={this.state.description}
							onChange={ this.onDescription}
						/>
					</FormGroup>
					{
						this.props.options.enabled_rules
							?
							<FormGroup
								label={__("Content of description page")}
								helperText={__("Set description")}
								className="p-2"
							>
								<MyEditor
									text={this.state.domain_content?.replace(
										/(<[^>]+\s+)(?:style\s*=\s*"(?!(?:|[^"]*[;\s])color\s*:[^";]*)(?!(?:|[^"]*[;\s])background-color\s*:[^";]*)[^"]*"|(style\s*=\s*")(?=(?:|[^"]*[;\s])(color\s*:[^";]*))?(?=(?:|[^"]*)(;))?(?=(?:|[^"]*[;\s])(background-color\s*:[^";]*))?[^"]*("))/gi,
										function (match, $1, $2, $3, $4, $5, $6, offset, string) {
											return $1 + ($2 ? $2       : '') + ($3 ? $3 + ';' : '')
													+ ($5 ? $5 + ';' : '') + ($2 ? $6       : '');
										}
									)}
									onChange={this.onDescrEdit}
								/>
							</FormGroup>
							:
							null
					}
					
				</Card>
				<div className="my-3 hidden">
					<Button
						text={__("Update")}
						onClick={this.onUpdate}
					/>
				</div>
			</div>
		</div>;
	}
}