import { Button, Dialog, Intent } from "@blueprintjs/core";
import IndexDB from "modules/pe-admin-module/views/utilities/IndexDB";
import _fetch from "modules/pe-wpfest-admin-module/api";
import React, { useEffect, useState } from "react"
import FieldInput from "react-pe-scalars/dist";
import { AppToaster, Loading, Pagi } from "react-pe-useful/dist";
import { __ } from "react-pe-utilities/dist";
import { useHistory } from "react-router";

const All = props =>
{
    const history = useHistory()
    const all_sites_number = 100
    const [sites, setSites] = useState( props.sites )
    const [pattern_sites, setPattern_sites] = useState([]) 
    const [users, setUsers] = useState( props.users ) 
    const [offset, onOffset] = useState( 0 )
    const [loading, setLoading] = useState( true )
    const [isNewOpen, onNewOpen] = useState( false )
    const [newTitle, onNewTitle] = useState( "" )
    const [newType, onNewType] = useState( "" )
    const [newAdress, onNewAdress] = useState( "" )
    const [newMap, onNewMap] = useState( )
    const [isOnline, setOnline] = useState( true ) 
    useEffect(() => {
        IndexDB.recover("peFestAdmin_allSites", "sites", "sites")
            .then(sites =>
            {
                //console.log(sites)
                const allSites = Array.isArray(sites)
                    ?
                    sites.map(site => site.data)
                    :
                    []
                setSites( allSites ) 
            }) 
        IndexDB.recover("peFestAdmin_allUsers", "users", "users")
            .then(users =>
            {
                //console.log(users)
                const allUsers = Array.isArray(users)
                    ?
                    users.map(user => user.data)
                    :
                    []
                setUsers( allUsers ) 
            }) 
        _fetch( "get_all_sites", {number:100, offset: 0})
            .then(data => 
                {
                    if(!data.isError)
                    {
                        let sites = data.sites || []
                        let users = data.users || []
                        setSites( sites ) 
                        setUsers( users ) 
                        setPattern_sites(data.pattern_sites || [] )
                        setLoading( false ) 
                        sites.forEach( site =>  {
                            IndexDB.save( 
                                site, 
                                "sites", 
                                site.blog_id, 
                                "sites",
                                "peFestAdmin_allSites",  
                                1 
                            ) 
                        }) 	
                        users.forEach( user =>  { 
                            IndexDB.save( 
                                user, 
                                "users", 
                                user.id, 
                                "users",
                                "peFestAdmin_allUsers",  
                                1 
                            )
                        }) 
                    } 
                    else
                    { 
                        IndexDB.recover("peFestAdmin_allSites", "sites", "sites")
                            .then(sites => {
                                IndexDB.recover("peFestAdmin_pattern_sites", "sites", "sites")
                                    .then(psites =>
                                    {                                          
                                        setPattern_sites( psites.map(ps => ps.data) ) 
                                        IndexDB.recover("peFestAdmin_allUsers", "users", "users")
                                            .then(users => {
                                                setUsers( users.map(u => u.data) )
                                                setSites( sites.map(s => s.data) )
                                                setLoading( false ) 
                                               // console.log(users)
                                                //console.log(sites)
                                            })
                                    }) 
                            })
                        setOnline(false)
                    }	 
                })	
    }, [])
    if(loading)
        return <Loading />
    //console.log(sites)

    const onPagi = n =>
    {
        //console.log( n )
        onOffset( n )
    }
    const onSite = evt =>
	{
		const domain_id = evt.currentTarget.getAttribute("domain_id");
		const domain = evt.currentTarget.getAttribute("domain");
        history.push( props.preroute + "/" + props.route + "/site/" + domain_id)
	}
    const userList = users. map(user => {
        return <option key={user.id} value={user.id}>
            {user.post_title}
        </option>
    })
    const onSelectClick = evt =>
    {
        evt.preventDefault()
        evt.stopPropagation()
    }
    const onNewDialogOpen = bool =>
    {
        isOnline 
            ? 
            onNewOpen(bool)
            :
            AppToaster.show({
                intent: Intent.DANGER,
                className: " lead ",
                icon: "tick", 
                message: __("No edit without internet"),
            })
    }
    const onDemiurgChange = evt =>
    {
        if(!isOnline)
        {
            AppToaster.show({
                intent: Intent.DANGER,
                className: " lead ",
                icon: "tick", 
                message: __("No edit without internet"),
            })
            return
        }
        const blog_id = evt.currentTarget.getAttribute("blog_id")
        const demiurg_id = evt.currentTarget.value
        _fetch( "change_demiurg", {blog_id, demiurg_id})
            .then(data => 
                {
                    console.log( data ); 
                    setSites( [...sites].map(site => {
                        if(Number(site.blog_id) === Number(data.blog_id))
                        {
                            site.demiurg_id = Number(data.demiurg_id)
                        }
                        return site
                    }))
                })	
    }
    const siteList = sites.map(elem => {
        const isPattern = pattern_sites.filter(p => p.domain_id == elem.blog_id).length > 0
        return <li className="list-group-item m-0 p-0" key={elem.blog_id}>
            <div 
                className="row pointer tex-sm-left text-left  p-0 pr-4"
                domain={elem.domain}
                domain_id={elem.blog_id}
                onClick={ onSite} 
            >
                <div className="col-md-1 col-12 d-flex align-items-center"><div className="px-4">{elem.blog_id}</div></div>
                <div 
                    className="col-md-5 col-12 lead d-flex align-items-center" 
                >
                    { elem.domain}
                </div>
                <div className="col-md-3 d-flex align-items-center">
                    { 
                        isPattern 
                            ? 
                            <span className="member_span text-danger">{__("is pattern")}</span> 
                            :  
                            elem.domain_type 
                                ? 
                                <span className="member_span">{elem.domain_type}</span> 
                                : 
                                null 
                    }
                </div>
                <div className="col-md-3">
                    <select 
                        className="form-control input dark m-0" 
                        value={elem.demiurg_id}
                        onClick={ onSelectClick }
                        onChange={ onDemiurgChange }
                        blog_id={ elem.blog_id }
                    >
                        <option value={-1}> -- </option>
                        {userList}
                    </select> 
                </div>
            </div>
        </li>
    });
    return <div className="py-3 row flex-column">
        <ul className="list-group">
            {siteList}                
        </ul>
        <div className="row">
            <div className=" col-sm-7 lightner d-flex">
                <Pagi
                    all={Math.floor( sites.length / all_sites_number )}
                    current={ offset }
                    onChoose={ onPagi }
                /> 
            </div>
            <div className="col-sm-5 text-right">
                <Button 
                    text={__("Create new Festival")} 
                    onClick={ () => onNewDialogOpen(true) } 
                    fill={true} 
                    minimal={true}
                    className="h-100 text-light"
                />
            </div>
        </div> 
        <Dialog
            isOpen={ isNewOpen }
            onClose={ () => onNewOpen(false) }
            title={__( "Create new Festival" )}
            className="little"
        >
            <div className="p-4">                
                <FieldInput
                    value={ newTitle }
                    type="string"
                    title={__("Title")}
                    vertical={true} 
                    onChange={ ( value ) => onNewTitle( value ) }
                />              
                <FieldInput
                    value={ newType }
                    type="select"
                    values={ sites.map(site => {
                        return { _id: site.domain_id, title: site.title, descr: site.description }
                    }) }
                    title={__("Type of Festival")}
                    vertical={true} 
                    onChange={ ( value ) => onNewType( value ) }
                />             
                <FieldInput
                    value={ newAdress }
                    type="string"
                    title={__("New Site | web address")}
                    commentary={__("Only lowercase letters (a-z) and numbers are allowed.")}
                    vertical={true} 
                    onChange={ ( value ) => onNewAdress( value ) }
                />            
                <FieldInput
                    value={ newMap }
                    type="geo"
                    title={__("Put geoposition")}
                    commentary={__("Set place of Event")}
                    vertical={true} 
                    onChange={ ( value ) => onNewMap( value ) }
                />    
                <div className="p-4">
                    <Button >
                        {__("Insert")}    
                    </Button>    
                </div>          
            </div>
        </Dialog>
    </div>
}
export default All