import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router'
import FestReglament from './FestReglament'
import FestMembers from './FestMembers'
import FestRegister from './FestRegister'
import FestTrackModerator from './FestTrackModerator'
import FestRequests from './FestRequests'
import FestRegisterNewProject from './FestRegisterNewProject'
import FestNewHoneycombForm from './FestNewHoneycombForm'
import FestNewProjectForm from './FestNewProjectForm'
import FestResults from './FestResults'
import FestMember from './FestMember'
import FestRegisterRole from './FestRegisterRole'
import FestAbout from './FestAbout'
import _fetch from '.'
import { Loading } from 'react-pe-useful/dist'
import WPFestSettings from './utilities/WPFestSettings'
import actions, { UPDATE_FEST_ACTION } from 'modules/pe-fest-module/data/actions'
import { useFestStore } from 'modules/pe-fest-module/data/store'
import NoMatchView from 'modules/pe-basic-module/views/NoMatchView'
import Fest404 from './Fest404'
import FestStatistics from './FestStatistics'

const Fest = props => {
    const [loading, setLoading] = useState( true )
    const rt = props.route
    const { route, preroute, festID } = props
    useEffect(() => {
        //TODO - убрать перерендер родителя при смене роута
        if(!useFestStore.getState().fests[festID])
        {
            WPFestSettings.set({ festURL: props.route })
            _fetch(
                "get_site_init_data",
                { id: festID },
                WPFestSettings.serviceURL,
                "",
                "wpfa", 
            )
                .then( data => {
                    if(data.url) {
                        // console.log( data.data  )
                        actions( 
                            UPDATE_FEST_ACTION, 
                            {
                                id: festID,
                                fest: {
                                    url: data.url,
                                    id : festID,
                                    serviceURL: WPFestSettings.serviceURL,
                                    title: data.data.festival_title,
                                    logo: data.data.logo,

                                },
                                options: data.data.options,
                                tracks: data.data.tracks,
                                milestones: data.data.milestones,
                                ganres: data.data.ganres,
                            }
                        ) 
                        setLoading(false)
                    } 
                })
        }
        else
        {
            setLoading(false)
        }
        
    }, [])
    if( loading ) return <div className="layout-state p-0 m-0">
        <Loading />
    </div>
    // console.log( WPFestSettings.getFestURL() )
    return <Switch> 
        <Route
            exact
            path={route}
        > 
        {
            useFestStore.getState().options.enabled_rules
                ?
                <FestAbout 
                    route={route} 
                    preroute={preroute}
                    festID={festID}
                />  
                :
                <FestMembers 
                    route={`${rt}`}
                    preroute={preroute}
                    festID={festID} 
                />
        }                    
        </Route>
        <Route
            strict
            path={`${rt}/member/:memberId`}
        >
            <FestMember  
                preroute={preroute}
                route={`${rt}`} 
                festID={festID}
            />
        </Route>
        <Route
            exact
            path={`${rt}/results`}
        >
            <FestResults 
                route={`${rt}`} 
                festID={festID}
            />
        </Route>
        <Route
            exact
            path={`${rt}/insert-project`}
        >
            <FestNewProjectForm 
                route={`${rt}`} 
                festID={festID}
            />
        </Route>
        <Route
            exact
            path={`${rt}/insert-honecombs`}
        >
            <FestNewHoneycombForm 
                route={`${rt}`} 
                festID={festID}
            />
        </Route>
        <Route
            exact
            path={`${rt}/register-project`}
        >
            <FestRegisterNewProject 
                route={`${rt}`} 
                festID={festID}
            />
        </Route>
        <Route
            exact
            path={`${rt}/admin-request-roles`}
        >
            <FestRequests 
                route={`${rt}`} 
                festID={festID}
                isNew
            />
        </Route>
        <Route
            exact
            path={`${rt}/track-moderator`}
        >
            <FestTrackModerator 
                route={`${rt}`}
                festID={festID} 
                isNew
            />
        </Route>
        <Route
            exact
            path={`${rt}/register`}
        >
            <FestRegister 
                route={`${rt}`}
                festID={festID} 
            />
        </Route>
        <Route
            exact
            path={`${rt}/register/:role`}
        >
            <FestRegisterRole 
                route={`${rt}`} 
                festID={festID}
            />
        </Route>
        <Route
            exact
            path={`${rt}/members`}
        >
            <FestMembers 
                route={`${rt}`}
                festID={festID} 
            />
        </Route>
        <Route
            strict
            path={`${rt}/reglament`}
        >
            <FestReglament 
                preroute={preroute}
                route={`${rt}`}
                festID={festID} 
            />
        </Route> 
        <Route
            strict
            path={`${rt}/statistic`}
        >
            <FestStatistics
                preroute={preroute}
                route={`${rt}`}
                festID={festID} 
            />
        </Route> 
        <Route path="*" >
            <Fest404
                preroute={preroute}
                route={`${rt}`}
                festID={festID} 
            />
        </Route>
    </Switch> 
}

Fest.propTypes = {}

export default Fest