import {
  ButtonGroup, Button, Drawer, Position, Dialog, Popover, Intent,
} from "@blueprintjs/core"
import DayNightSwitcher from "modules/pe-admin-module/views/scalars/DayNightSwitcher"
import { getCookie, setCookie } from "modules/pe-wpfest-admin-module/utilities/utils"
import React, { Component } from "react"
import ReactDOM from "react-dom"
import Layouts from "react-pe-layouts" 
import { importAll, initArea, __ } from "react-pe-utilities"
import LandingView from "../../LandingView"
import matrix from "../data/matrix"
import InputForm from "./InputForm" 

import CardTemplateEditor from "./sectionParamsEditors/CardTemplateEditor" 
import CurrentCardTemplateEditor from "./sectionParamsEditors/CurrentCardTemplateEditor"

class LandingSectionParams extends Component { 
  constructor(props) {
    super(props)
		this.rp = React.createRef() 
    //console.log(props)
    this.state = {
      ...this.props,
      matrixSrc: {},
      isDay : !!getCookie("isDay")
    }
  }
  onBodyClick = (e) =>
  { 
    const domNode = ReactDOM.findDOMNode(this.rp.current)
    console.log( domNode )
		if (!domNode || !domNode.contains(e.target)) 
    {
			if (this.state.isParamsVisibled) 
      { 
				this.setState({
					isParamsVisibled: false 
				})
			}
		}
  }
  componentDidMount()
  {
    document.body.addEventListener("clck", this.onBodyClick)
  }
  componentWillUnmount()
  {
    document.body.removeEventListener("clck", this.onBodyClick)
  }
  setUpdated = ( _data ) =>
  { 
    // console.log(_data)
    this.setState({ ..._data, isUpdate:true, isDDeleteOpen:false })
  }
  onUpdate = () =>
  {
    const _data = { ...this.state }
    delete _data.isUpdate
    delete _data.isDDeleteOpen
    this.props.onApply( _data )
    this.onDialogClose()
  }
  switchDayNight = isDay =>
  {
    this.setState({ isDay })
    setCookie( "isDay", isDay )
  }
  getDefaultInputForm = () =>
  {
    const sparam_id = this.state.matrixSrc.id
    const mData = matrix()[this.state.type] ? matrix()[this.state.type] : {}
    const cellData = {}
    Object.keys(mData)
      .filter( field => mData[field].sparam_id === sparam_id)
      .forEach(field => {
        let dat = {...mData[field]}
        //delete dat.hidden
        delete dat.parent
        delete dat.tab
        delete dat.tab_icon
        cellData[field] = dat
      })
    return <InputForm
      cellData={ cellData }
      data={ this.state }
    />
  }
  render() { 
    const btns = <ButtonGroup minimal vertical>
        {
          Array.isArray(matrix()[this.state.type]?.sparams.sparam_id)
            ? 
            matrix()[this.state.type].sparams.sparam_id
              .filter( e => !e.hidden)
              .map((e, i) => (
                <Button
                  key={i}
                  onClick={(evt) => this.onClick(evt, e)}
                  className="py-1 " 
                  icon={e.icon}
                >
                  <span dangerouslySetInnerHTML={{ __html: __(e.title) }} />
                </Button>
              ))
            : 
            null
        }
      </ButtonGroup> 
    let content, topPanel
    switch (this.state.matrixSrc.editor) {
      case "Card Template Lib":
        content = <CardTemplateEditor
            {...this.state }
            onEdit={this.props.onEdit}
            onApply={this.props.onApply}
            onTry={this.props.onTry}
            onUpdate={this.props.onUpdate}
        />
        break;
      case "Card template editor": 
        content = <CurrentCardTemplateEditor 
          data={{...this.state }}
          matrixSrc={this.state.matrixSrc}
          onUpdate={ this.setUpdated }
        />
        break;
      default:
        if(!this.state.matrixSrc.editor) 
        {
          content = ""
          break
        }
        const ElComponent = getExtendsComponents()["GridSectionParams"].c
        // console.log( ElComponent )
        // console.log( this.state )
        content = initArea(
          this.state.matrixSrc.editor,
          <ElComponent 
            elData={{...this.state}}
            onUpdate={ this.setUpdated }
          />,
          this.getDefaultInputForm()
        )
        //topPanel = initArea
    }
    return <>
      <div className="landing-sector__params mr-3" style={{top: this.state.dopEditTop + 5}}>        
        <div 
          className={` ${ this.state.isParamsVisibled ? "" : "hidden" }`} 
          style={{
            //position:   "absolute",
            top:        -3,
            //left:       3,
            border:     "1px solid #11111144",
            backgroundColor: "#FFF",
            position:   "relative",
            paddingTop: 30
          }}          
        >
          <ButtonGroup alignText="left" minimal>
            {btns}
          </ButtonGroup>
        </div>       
      </div>
      <div 
        className={` landing-sector__params mr-5 ${Array.isArray(matrix()[this.state.type]?.sparams.sparam_id) ? "" : "hidden"}`}
        style={{top: this.state.dopEditTop + 5}}
      >        
        <Button 
          icon={"caret-down"}           
          style={{ zIndex: 10, position: "absolute" }}
          onClick={ () => this.setState({ isParamsVisibled : !this.state.isParamsVisibled }) }
        />        
      </div>
      <Dialog
        isOpen={this.state.isDialogOpen}
        onClose={this.onTryDialogClose}
        title={
          <div className="d-flex justify-content-between h-100 align-items-center">
            <span dangerouslySetInnerHTML={{ __html: this.state.DialogTitle  }} />
            {topPanel}
            {
              this.state.isUpdate
                ?
                <div>
                  <Button 
                    intent={Intent.DANGER}
                    onClick={ this.onUpdate }
                  >
                    {__("Update and close")}
                  </Button>
                </div>
                :
                null
            }
            <DayNightSwitcher isDay={this.state.isDay} switchDayNight={this.switchDayNight} />
          </div>
          
        }
        position={Position.RIGHT}
        usePortal
        backdropClassName="landing-drawer-bg" 
        className={`full ${ this.state.isDay ? "day": "night" }`}
      >
        <div 
          className="pt-0 px-0 overflow-y-auto flex-grow-100 m-0" 
          style={{height: window.outerHeight - 200 }}
        >
          {content}
        </div>
      </Dialog>
      <Dialog
        isOpen={this.state.isDDeleteOpen}
        title={__("Changes not saved")}
        onClose={this.onDDeleteOpen}
        className="little"
      >
        <div className="">
          <div className="lead p-5">
            {__("Save changes?")}
          </div>
          <ButtonGroup fill large>
            <Button intent={Intent.SUCCESS} onClick={this.onUpdate} className="flex-grow-100">
              {__("Save and close")}
            </Button>
            <Button intent={Intent.DANGER} onClick={ this.onDialogClose }>
              {__("Close")}
            </Button>
          </ButtonGroup>
        </div>
      </Dialog>
    </>
  }

  onClick = (evt, data) => {
    this.setState({
      isDialogOpen: true,
      DialogTitle: __(data.title) ,
      matrixSrc: data
    })
    LandingView.isLockScroll = true
  }
  onDDeleteOpen = () => {
    this.setState({ isDDeleteOpen: !this.state.isDDeleteOpen })
    LandingView.isLockScroll = true
  }
  onTryDialogClose = () =>
  {
    if(this.state.isUpdate)
    {
      this.onDDeleteOpen()
    }
    else
    {
      this.onDialogClose()
    }
  }
  onDialogClose = () => {
    LandingView.isLockScroll = false
    this.setState({ isDialogOpen: false, isDDeleteOpen: false, isUpdate: false })
  }
}
export default LandingSectionParams



const extendsComponents = {};
const getExtendsComponents = () =>
{
	const plWidgets = [];
	const plWidgetDatas = []
	const lazies = {};

	try{
		const modules = Layouts().modules;
		Object.keys(modules).forEach(plugin => {
			Object.keys(modules[plugin].extentions).forEach(widget => {
				if( modules[plugin].extentions[widget].extended_landing_section_params )
				{
					plWidgets.push( widget );
					plWidgetDatas.push( modules[plugin].extentions[widget] )
				}
			});
		});
		importAll(require.context("modules/", true, /\.js$/), plWidgets, extendsComponents, lazies, "modules/");
		let __components = {} 
		plWidgetDatas.forEach((widget, i) => { 
			if( extendsComponents[ plWidgets[ i ] ] )
			{
				const comp = {
					c : 			extendsComponents[ plWidgets[ i ] ].default,
					icon : 			widget.icon,
					title:			widget.title,
					description: 	widget.description
				} 
				__components[ plWidgets[ i ] ] = comp
			}
		})
		//console.clear()
		//console.log( __components )
		//console.log( plWidgets )
		//console.log( extendsComponents )
		return __components
	}
	catch(e)
	{
		return {}
	}
}
